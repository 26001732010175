import React, { CSSProperties } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { isMobile } from 'react-device-detect';


const override: CSSProperties = {
    position: 'absolute',
    top: '40%',
    left: '45%',
    borderColor: 'black',
}


const overrideMobile: CSSProperties = {
    position: 'absolute',
    top: ' 40%',
    left: ' 30%',
    borderColor: 'black'
}

export interface IOuterProps {
    loading: boolean;
}

export const Spinner = ({ loading }: IOuterProps) => {
    return (
        <ClipLoader
            color={'black'}
            loading={loading}
            cssOverride={isMobile ? overrideMobile : override}
            size={150}
        />
    );
};
