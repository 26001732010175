import cx from 'classnames';
import React, { HTMLAttributes } from 'react';

import styles from './typography.module.scss';

export interface ITypographyProps extends HTMLAttributes<HTMLParagraphElement> {
    size12?: boolean;
    size14?: boolean;
    size16?: boolean;
    bold?: boolean;
    center?: boolean;
    noMargin?: boolean;
    withMarginBottom?: boolean;
    orange?: boolean;
    tag?: string;
    gray?: boolean;
    inlineBlock?: boolean;
}

export const Typography = ({
    children,
    size12,
    size14,
    size16,
    bold,
    withMarginBottom,
    noMargin,
    center,
    orange,
    tag = 'p',
    className,
    gray,
    inlineBlock,
    ...props
}: ITypographyProps) => {
    return React.createElement(
        tag,
        {
            className: cx(
                styles.text,
                {
                    [styles.bold]: bold,
                    [styles.center]: center,
                    [styles.orange]: orange,
                    [styles.noMargin]: noMargin,
                    [styles.standardMarginBottom]: withMarginBottom,
                    [styles['size-12']]: size12,
                    [styles['size-14']]: size14,
                    [styles['size-16']]: size16,
                    [styles.gray]: gray,
                    [styles.inlineBlock]: inlineBlock,
                },
                className,
            ),
            ...props,
        },
        children,
    );
};
