export interface ILanguage {
    code: string;
    shortDisplay: string;
}

export const availableLanguagesCodes: string[] = [
    'FR',
    'EN',
    'DE',
    'ES',
    'IT',
    'NL',
];

export const getAvailableLanguages = (): ILanguage[] =>
    availableLanguagesCodes.map((code) => ({
        code,
        shortDisplay: code.toUpperCase(),
    }));
