export const ES = {
  AactivityOnboarding: {
    SUMMARY: {
      ACTIVITY_TEXT: 'Frecuencia de las salidas'
    }
  },
  HipayPayments: {
    CARDNUMBER: 'Número de tarjeta',
    EXPIRYDATE: 'Fecha de caducidad',
    FULLNAME: 'Nombre completo',
    GOCARDLESS_LINK: 'Pagar con domiciliación bancaria'
  },
  Slider: {
    GPS_INTERVAL: 'Intervalo GPS'
  },
  TypeSwitch: {
    ANIMAL_SUBTITLE: 'Elija el animal para el que ha comprado este rastreador:',
    CAT_TYPE: 'Trazador por gato',
    DOG_TYPE: 'Trazador por perro',
    FAMILY_SUBTITLE: '¿Para quién has comprado este localizador?',
    KID_TYPE: 'Un niño',
    SILVER_TYPE: 'Una persona mayor',
    TITLE: '¿Qué tipo de trazador es el suyo?'
  },
  account: {
    LANGUAGE: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema',
        UPDATE_SUCCESS: 'Idioma actualizado correctamente'
      }
    },
    NAV: {
      LANGUAGE: 'Idioma',
      PASSWORD: 'Contraseña',
      PAYMENTS: 'Pagos y facturación',
      USER_PREFERENCES: 'Preferencias',
      USER_PROFILE: 'Perfil de usuario'
    },
    PASSWORD: {
      HEADLINE: 'Cambio de contraseña',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema',
        UPDATE_ERROR_WRONG_PASSWORD: 'La contraseña anterior introducida no es correcta',
        UPDATE_SUCCESS: 'Contraseña actualizada correctamente'
      }
    },
    PAYMENTS: {
      HISTORY_HEADLINE: 'Historial de pagos',
      MAIN_HEADLINE: 'Facturación'
    },
    USER_PREFERENCES: {
      EMAIL_PREFERENCES_HEADLINE: 'Preferencias de correo electrónico',
      EMAIL_PREFERENCES_USER_INFO: 'Las preferencias se aplicarán a la dirección del correo electrónico {{user_email}}',
      MAIN_HEADLINE: 'Preferencias',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema al guardar las preferencias',
        UPDATE_SUCCESS: 'Preferencias actualizadas correctamente'
      },
      METRIC_PREFERENCES_DESCRIPTION: 'Elija la unidad de distancia utilizada para su rastreador.',
      METRIC_PREFERENCES_HEADLINE: 'Preferencia del sistema métrico :'
    },
    USER_PROFILE: {
      BASIC_INFO_HEADLINE: 'Información básica',
      BILLING_HEADLINE: 'Dirección de facturación',
      MAIN_HEADLINE: 'Perfil de usuario',
      MARKETING_AGREEMENT_TEXT: 'Acepto recibir ofertas y promociones de los socios de Weenect',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema',
        UPDATE_SUCCESS: 'Datos actualizados correctamente'
      },
      METRIC_KM: 'Kilómetros',
      METRIC_MILES: 'Millas',
      PREFERRED_METRIC: 'Sistema métrico utilizable:'
    }
  },
  activity: {
    AVERAGE_SPEED: 'Velocidad media',
    CONTROLS: {
      PAUSE_PLAYING_TITLE: 'Suspender la grabación del historial',
      RESUME_PLAYING_TITLE: 'Reiniciar la grabación del historial',
      START_PLAYING: 'Play',
      START_PLAYING_TITLE: 'Iniciar la grabación del historial',
      STOP_PLAYING: 'Stop',
      STOP_PLAYING_TITLE: 'Parar la grabación del historial',
      TOGGLE_HEATMAP: 'Mapa térmico',
      TOGGLE_HEATMAP_TITLE: 'Mostrar el mapa térmico',
      TOGGLE_PATH: 'Trazado de posiciones',
      TOGGLE_PATH_TITLE: 'Mostrar el trazado de posiciones'
    },
    DETAIL_TITLE: 'Información de la actividad',
    EMPTY_LIST: 'No hay alertas que mostrar. Modifique el período seleccionado o compruebe que el localizador esté encendido.',
    EVENTS: {
      ALERT: 'Botón lateral activado',
      BATTERY: 'Batería baja - {{battery}}% restant',
      CALL_END: 'Llamada SOS finalizada',
      CALL_REQ: 'Llamada SOS solicitada',
      ENTERS_GEOFENCE: 'Entrada en la zona',
      EXIT_GEOFENCE: 'Salida de la zona',
      FLASH_REQ: 'Flash pedido',
      FREQ_CHANGE: 'Se ha modificado el intervalo de tiempo',
      RING: 'Timbre emitido',
      RING_REQ: 'Timbre solicitado',
      SOS: 'Alerta SOS',
      TRACKER_OFF: 'Localizador apagado',
      TRACKER_ON: 'Localizador encendido',
      VIBRATE: 'Vibración emitida',
      VIBRATE_REQ: 'Vibrador solicitado'
    },
    FEEDBACKTEXT: {
      FIRST: '',
      SECOND: ''
    },
    HISTORY_TITLE: 'Histórico de las notificaciones',
    LAST_TIME_PERIOD: 'Mostrar las últimas',
    LOADING: 'Cargando...',
    MAXIMUM_SPEED: 'Velocidad máxima',
    OBJECTIVE: {
      CONTENT: 'Establezca objetivos diarios para garantizar la salud de su mascota.',
      DAILY_REACHED: '{{name}} ha alcanzado su objetivo diario.',
      HELPTEXT: '¿Cuánta actividad necesita mi mascota?',
      RECOMENDED_VALUE: 'Recomendado: 1 hora',
      SUBTEXT: 'Establezca el tiempo de actividad diaria que debe alcanzar:',
      TITLE: 'Gestión de objetivos'
    },
    ONBOARDING: {
      FIRST_SUBTEXT: 'Esta herramienta le permitirá conocer la actividad diaria de su compañero. ',
      SECOND_SUBTEXT: '¿Cuánto tiempo durmió? ¿Cuántos pasos ha dado hoy?',
      START: 'Inicie',
      THIRD_SUBTEXT: 'Incluso podrá establecer un objetivo basado en la raza de su mascota, para que ésta realice la actividad diaria que necesita.',
      TITLE: '¡Bienvenido al seguimiento de actividad Weenect!'
    },
    PROGRESSBAR: {
      TITLE: 'Tiempo de actividad'
    },
    SELECT_DAY: 'Elegir el día que mostrar',
    SELECT_TIME: 'Use el control deslizante para seleccionar el intervalo de tiempo a mostrar',
    SUMMARY: {
      ACTIVITY: 'Actividad',
      AWAKENING: 'Despertar',
      DATEINTERVAL: '',
      DAY_INDEX: 'd',
      DISTRIBUTIONTEXT: 'Distribución de la actividad',
      HOUR_INDEX: 'h',
      LASTWEEK: '',
      MENU_TITLE: 'Informe de actividad',
      MIN_INDEX: 'min',
      PREVIOUS_DAY: '',
      PREVIOUS_MONTH: '',
      PREVIOUS_WEEK: '',
      REPORT: 'Ver informe ',
      REST: 'Descanso',
      SUBTEXT: 'Con {{time}} de ejercicio para {{name}}, ¿qué tal si la llevas a dar un paseo? ',
      TEXT_OBJECTIVE_COMPLETED: '{{name}} ha alcanzado su objetivo diario.',
      TEXT_OBJECTIVE_COMPLETED_TREAT: 'Enhorabuena, {{name}} ha conseguido su objetivo. ¿Quizás eso merezca un trato?',
      TEXT_OBJECTIVE_NONE: '{{name}} no tiene datos para este periodo',
      TEXT_OBJECTIVE_NONE_VERIFY_TRACKER: '{{name}} aún no ha registrado ningún movimiento. Comprueba que tu rastreador está encendido y que la batería está cargada.',
      TITTLE: 'El progreso de {{name}}',
      TODAY: 'Hoy,',
      YESTERDAY: 'ayer'
    },
    TIME_ELAPSED: 'Duración',
    TOTAL_DISTANCE: 'Distancia total'
  },
  activityGeneral: {
    BEHAVIOR: 'Actividad',
    STANDARD_MORPHOLOGY: 'Morfología',
    STERILIZED: 'Esterilizado'
  },
  activityOnboarding: {
    COMMON: {
      STEP: 'Etapa',
      TRACKER_PROFIL: 'Perfil del rastreador'
    },
    MONTH: {
      APRIL: 'Abril',
      AUGUST: 'Agosto',
      DECEMBER: 'Diciembre',
      FEBRUARY: 'Febrero',
      JANUARY: 'Enero',
      JULY: 'Julio',
      JUNE: 'Junio',
      MARCH: 'Marzo',
      MAY: 'Mayo',
      NOVEMBER: 'Noviembre',
      OCTOBER: 'Octubre',
      SEPTEMBER: 'Septiembre'
    },
    PET_ACTIVITY: {
      FIRST_BUTTON: 'Casero (lo estrictamente necesario)',
      FIRST_BUTTON_CAT: 'Excepcionalmente (vacaciones, imprevistos...)',
      FIRST_BUTTON_DOG: 'Sólo para sus propias necesidades',
      FOURTH_BUTTON: 'Hiperactivo (de 2 a 3 paseos al día)',
      FOURTH_BUTTON_CAT: 'Regularmente, día y noche',
      FOURTH_BUTTON_DOG: '2 a 3 paseos/día',
      SECOND_BUTTON: 'Normal (de 2 a 3 paseos a la semana)',
      SECOND_BUTTON_CAT: 'De vez en cuando, a petición suya\n',
      SECOND_BUTTON_DOG: '2 a 3 paseos/semana',
      SELECT_ACTIVITY: '¿Con qué frecuencia sale?',
      THIRD_BUTTON: 'Enérgico (un paseo al día)',
      THIRD_BUTTON_CAT: 'Regularmente, durante el día\n',
      THIRD_BUTTON_DOG: 'Un paseo al día',
      TITLE: '¿Cuál es su perfil de actividad?'
    },
    PET_AGE: {
      FIRST_COMMON_TEXT: 'año(s) y ',
      SECOND_COMMON_TEXT: 'mes(es)',
      TITLE: '¿Cuál es su fecha de nacimiento?'
    },
    PET_GENDER: {
      FEMALE: 'Hembra',
      FIRST_TITLE: '¿Tu mascota es una hembra o un macho?',
      MALE: 'Macho',
      SECOND_TITLE: '¿Tu mascota está esterilizada?'
    },
    PET_HEALTH: {
      LAST_VACINATION: '¿Cuándo se vacunó por última vez?',
      LAST_VET_VISIT: '¿Cuándo fue su última visita al veterinario?',
      TITLE: 'Punto de Salud'
    },
    PET_IDENTIFICATION: {
      CHIP: 'Chip',
      NONE: 'Mi mascota no está identificada',
      SELECT_IDENTIFICATION: '¿Cómo se identifica?',
      TATTOO: 'Tatuado'
    },
    PET_INFO: {
      GET_STARTED: '¿Empezamos?',
      NEED_TO_KNOW: 'Para nosotros es importante aprender a conocer a {{name}}.',
      PETS_DETAILS: '¡Completa los datos de tu mascota!',
      PETS_GENDER: {
        MIXED_BREED: 'Es un cruzado'
      },
      PET_GENDER_COMMON: 'Para empezar, tu mascota es: ',
      SECOND_PETS_DETAILS: 'Rellena los datos de tu compañero'
    },
    PET_MORPH: {
      AVERAGE: 'Normal',
      COMMON_TEXT: 'Aproximadamente',
      FIRST_TITLE: '¿Qué tipo de constitución tiene?',
      HELP_TEXT: '¿Cómo puedo definir la morfología de mi mascota?\n',
      LEAN: 'Delgado',
      OBESE: 'Obeso',
      OVERWEIGHT: 'Con sobrepeso',
      SECOND_TITLE: '¿Cuánto pesa?',
      WEIGH_TEXT: '¿Cuál es el peso de su mascota?\n'
    },
    PET_NAME: {
      COMMON_TEXT: 'Nos gustaría conocer a tu mascota.\u2028 Después, podrás modificar esta información en los ajustes. ',
      PLACEHOLDER: 'Nombre de su mascota',
      TITLE: '¿Cómo se llama?'
    },
    PET_RACE: {
      COMMON_TEXT: 'No sé de qué raza es',
      PLACEHOLDER: 'Raza de su perro',
      SELECT_BREED: 'Seleccionar la raza',
      TITLE: '¿Cuál es la raza de tu mascota? '
    },
    PET_TYPE: {
      CAT: 'Un gato',
      DOG: 'Un perro'
    },
    SUMMARY: {
      BIRTH_DATE: 'Fecha de nacimiento',
      BREED: 'Raza',
      CONDITION: 'Estado',
      DAILY_EXERCISE: 'Gasto diario',
      DELETE: 'Restablecer perfil',
      HEALTH: 'Salud',
      IDENTIFICATION: 'Identificación',
      MODAL: {
        NO: 'No, cancelar',
        SUBTEXT: 'Entonces tendrás que volver a empezar el proceso.',
        TITLE: '¿Estás seguro que quieres restablecer la información de su mascota?',
        YES: 'Si soy seguro'
      },
      MORPHOLOGY: 'Morfología',
      NAME: 'Nombre',
      RECAPTEXT: 'Aquí está la información sobre su compañero.',
      SEX: 'Sexo',
      SPECIES: 'Especies',
      SUBMIT: 'Validar el perfil',
      UNSPECIFIED: 'faltante',
      VALIDATION: 'Verificación del perfil',
      VALIDATION_TEXT: 'Por favor, valida yu información antes de continuar. ',
      WEIGHT: 'Peso'
    }
  },
  activityTracking: {
    ACTIVITY: 'Puntos de actividad',
    DAILY_CHART: 'Diagrama de actividad',
    DAYS: 'día',
    HOURS: 'Horas',
    INTERVAL: {
      TEXT: 'Desplace los bordes del gráfico para ajustar el intervalo temporal que desee analizar.',
      TITLE: 'Selección del intervalo'
    },
    MIN: 'minutos',
    PERIOD: {
      DAY: 'Día',
      MONTH: 'Mes',
      TITLE: 'Selección del período',
      WEEK: 'Semana',
      YEAR: 'Año'
    },
    TEXT: 'Este gráfico le indica el desarrollo de la actividad de su mascota. Esta actividad se mide en un intervalo que comienza en 0 y no tiene límite máximo. Cada punto de actividad representa una aceleración, es decir, un movimiento de su mascota.'
  },
  alertCustomer: {
    BODY0: 'Nuestro proveedor de tarjeta SIM está actualmente en mantenimiento.',
    BODY1: 'Durante este periodo de mantenamiento <1>el tiempo de espera para la activación de una SIM ha sido extendido a 24h</1>.',
    BODY2: 'Somos muy conscientes de las molestias que podría causar. Sentimos mucho esta situación fuera de nuestro control.\n\nPara más información, puede ponerse en contacto con nuestro equipo en <1>family@weenect.com</1> o por teléfono en al +442070391906.',
    FOOTER: 'Equipo Weenect'
  },
  changePaymentMean: {
    ADD_BANK_ACCOUNT: 'Añadir una cuenta a cargar',
    ADD_BANK_CARD: 'Pagar con tarjeta de crédito',
    BANK_ACCOUNT_ERROR: 'Se ha producido un error al modificar sus datos bancarios. Póngase en contacto con el Servicio de Atención al Cliente.',
    BANK_ACCOUNT_EXPLANATION: 'Tenga en cuenta que ninguno de sus datos bancarios son guardados por nuestros servidores.',
    BANK_ACCOUNT_SUCCESS: 'Datos bancarios actualizados',
    BANK_CARD_ERRORED: 'Problema durante el cambio de método de pago. Póngase en contacto con el <1>Servicio de Atención al Cliente</1>.',
    BANK_CARD_UPDATED: 'Tarjeta bancaria actualizada',
    CAPTATION: 'Para actualizar su información bancaria, autorizaremos un cargo a su tarjeta de {{ amount }}.',
    CAPTATION_EXPLANATION1: 'Este importe no se deducirá. La transacción se cancela de inmediato.',
    CAPTATION_EXPLANATION2: 'Este procedimiento nos permite cumplir con las normas de pago de Internet, además de garantizar la seguridad de su información bancaria. No se divulgará esta información a través de nuestros servidores.',
    CAPTATION_EXPLANATION3: 'IMPORTANTE: el formulario de domiciliación llevará el nombre "Hareau" y no "Weenect". Es normal, se trata del nombre de nuestra empresa. "Weenect" es el nombre comercial.',
    CAPTATION_EXPLANATION4: 'Cambie la cuenta bancaria en la que se efectúa el cobro, conservando la misma suscripción.',
    CARD_AUTHORIZED: 'Medios de pago autorizados:',
    CHANGE_PAYMENT_MEAN: 'Actualizar mi tarjeta bancaria',
    CURRENT_CHOICE_CB: 'Eligió pagar con tarjeta de crédito.',
    CURRENT_CHOICE_IBAN: 'Usted ha elegido pagar por IBAN',
    CURRENT_MEAN: 'Forma de pago actual',
    CURRENT_MEAN_PHRASE: 'Ha elegido pagar con tarjeta de crédito',
    CURRENT_MEAN_PHRASE_GOCARDLESS: 'Ha elegido pagar por domiciliación bancaria',
    DEFAULT_MEAN: 'Método de pago predeterminado:',
    GO_TO_SEPA: 'Pasar al débito directo',
    MODIFY: 'Modificar mi método de pago',
    TITLE: 'Método de pago',
    UPDATE_BANK_ACCOUNT: 'Cambiar la cuenta en la que se efectuará el cargo',
    UPDATE_BANK_CARD: 'Actualizar mi tarjeta bancaria',
    USED_PAYMENT_EXPLANATION: 'Este método de pago se utiliza para pagar sus suscripciones y opciones.'
  },
  commonActions: {
    ACTIVATE: 'Activar',
    BACK: 'Atrás',
    CANCEL: 'Cancelar',
    CAN_BE_CANCELED: 'Se puede cancelar en cualquier momento',
    CHANGE: 'Guardar',
    CONFIGURE: 'Configurar',
    DEACTIVATE: '',
    GO_MAP: 'Ir al mapa',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema'
    },
    NEXT: 'Siguiente',
    NO: 'No',
    OK: 'Aceptar',
    REGISTER: 'Activar',
    SAVE: 'Guardar',
    SELECT_ALL: 'Seleccionar todo',
    SEND: 'Enviar',
    SUBSCRIBE: 'Suscribirme',
    THEN: 'luego',
    UNSELECT_ALL: 'Deseleccionar todo',
    UPDATE: 'Guardar',
    VALIDATE: 'Validar',
    YES: 'Sí'
  },
  creditCardAlert: {
    AUTO_RENEWAL_ADVICE: 'Su cuenta sigue activa, pero debe actualizar su forma de pago para que la renovación de su suscripción se realice sin problemas.',
    AUTO_RENEWAL_NOTICE: '<1>¡Atención!</1> Su suscripción va a terminar y su tarjeta ha caducado.'
  },
  emailPreferences: {
    EMAIL_LISTS: {
      COMPANY_NEWS: 'Novedades de la empresa',
      NEW_FEATURES: 'Nuevas funcionalidades',
      OFFERS: 'Ofertas promocionales',
      SERVICE_STATUS: 'Averías y actualizaciones',
      SURVEYS_AND_TESTS: 'Encuestas y tests Beta',
      TUTORIALS: 'Tutoriales'
    },
    EMAIL_LISTS_HEADER: 'Lista de correo electrónico',
    EMAIL_LISTS_INFO: {
      SERVICE_STATUS: 'Necesario para el correcto funcionamiento del servicio.'
    },
    LANGUAGE_PREFERENCES_DESCRIPTION: 'Elija el idioma en el que desea recibir los correos electrónicos :',
    PREFERENCES_DECRIPTION: 'Elija el tipo de correos electrónicos que desea recibir. Sólo le enviaremos los que coincidan con sus elecciones. '
  },
  error: {
    TITLE: 'Ha surgido un problema '
  },
  errors: {
    DEFAULT: 'Campo no válido',
    FIELD_REQUIRED: 'Este campo es obligatorio',
    GENERAL: 'Ha ocurrido un error, inténtelo de nuevo',
    IMEI: 'Introduzca un número IMEI válido',
    MAIL_FORMAT: 'Este formato de correo electrónico no es válido',
    MAX_LENGTH: 'El campo puede contener ${max} caracteres como máximo',
    PASSWORD_FORMAT: 'Su contraseña debe contener al menos un número, una mayúscula, y una minúscula.',
    PHONE_FORMAT: 'El número de teléfono debe incluir el prefijo internacional',
    PROMO_CODE: 'Código promocional no válido'
  },
  forgotPassword: {
    FORGOT_PASSWORD_BUTTON: 'Recibir un enlace de contraseña olvidada',
    FORGOT_PASSWORD_CANCEL: 'Volver al inicio',
    FORGOT_PASSWORD_ERRORS: {
      UNKNOWN: 'Se ha producido un error. Póngase en contacto con nosotros a través de nuestro formulario.'
    },
    FORGOT_PASSWORD_EXPIRED: 'El enlace para reiniciar la contraseña ha caducado.',
    FORGOT_PASSWORD_RETRY: 'Solicitar un nuevo enlace de contraseña olvidada',
    FORGOT_PASSWORD_SUCCESS: 'Si hay una cuenta asociada con {{mail}} recibirás un correo electrónico con un enlace para reiniciar tu contraseña.'
  },
  forms: {
    CITY: {
      LABEL: 'Localidad',
      PLACEHOLDER: 'Su localidad'
    },
    CONFIRM_PASSWORD: {
      LABEL: '¿Olvidaste tu contraseña?',
      PLACEHOLDER: 'Confirmar'
    },
    COUNTRY: {
      LABEL: 'País'
    },
    EMAIL: {
      LABEL: 'Dirección de correo electrónico',
      PLACEHOLDER: 'votre@email.com'
    },
    FIRST_NAME: {
      LABEL: 'Nombre',
      PLACEHOLDER: 'Su nombre'
    },
    LANGUAGE: {
      LABEL: 'Idioma'
    },
    LAST_NAME: {
      LABEL: 'Apellidos',
      PLACEHOLDER: 'Sus apellidos'
    },
    NEW_PASSWORD: {
      LABEL: 'Nueva contraseña',
      PLACEHOLDER: 'Su nueva contraseña'
    },
    OLD_PASSWORD: {
      LABEL: 'Contraseña anterior',
      PLACEHOLDER: 'Su contraseña anterior'
    },
    PASSWORD: {
      LABEL: 'Contraseña',
      PLACEHOLDER: 'Contraseña',
      VALIDATION: 'Su contraseña debería tener al menos 8 caracteres',
      WARNING: 'Cuidado, "qwertyuiop" y "12345678" NO estan contraseñas seguras.\nPrueba la securitad de tu contraseña aquí : <1>https://howsecureismypassword.net</1>'
    },
    PHONE: {
      LABEL: 'Teléfono',
      PLACEHOLDER: '+33600000000'
    },
    POSTAL_CODE: {
      LABEL: 'Código postal',
      PLACEHOLDER: 'Su código postal',
      STREET_NUMBER: 'Número y vía'
    },
    STREET: {
      LABEL: 'Dirección',
      PLACEHOLDER: 'Nombre y número de la calle'
    }
  },
  geofences: {
    CREATE: {
      HEADLINE: 'Crear una zona nueva'
    },
    EDIT: {
      DELETE_GEOFENCE: 'Eliminar la zona',
      ENTRANCE_NOTIFICATION: 'Notificación de entrada a la zona',
      EXIT_NOTIFICATION: 'Notificación de salida de la zona',
      GEOCODE_LABEL: 'Utilizar una dirección',
      GEOCODE_PLACEHOLDER: 'Escriba la dirección aquí',
      LOCATION: 'Posición del centro de la zona',
      NAME_INPUT: {
        LABEL: 'Nombre de la zona',
        PLACRHOLDER: 'Escriba el nombre de la zona'
      },
      OUTDOORS: 'Zona exterior (parque, bosque)',
      OUTDOORS_TOOLTIP: 'Dado que el GPS es más preciso en exteriores, las reglas para activar una notificación de entrada o salida son diferentes en una zona interior y una exterior.',
      POINT_ON_THE_MAP_TEXT: 'o hacer clic directamente en el mapa',
      RADIUS: 'Tamaño de zona (radio)',
      TRACKER_LAST_POSITION_BUTTON: 'Utilizar la posición del localizador',
      UNKNOWN_ADDRESS: 'Dirección desconocida'
    }
  },
  header: {
    ALL_TRACKERS: 'Todos los localizadores',
    AVAILABLE_PLURAL: 'disponible(s)',
    NAV: {
      ACCOUNT: 'Cuenta',
      INSURANCE: 'Seguro',
      LOGOUT: 'Cerrar sesión',
      SUPPORT: 'Asistencia',
      TRACKERS: 'Mapa'
    },
    SEARCHBAR_RESULT: 'localizadores encontrados',
    SEARCHBAR_TITLE: 'Buscar un localizador:',
    TRACKERS: 'Localizador(es)',
    WHITE_LABEL: {
      IN_PARTERNSHIP_WITH: 'En asociación con'
    }
  },
  imeiInfo: {
    HINT: 'Encontrará el número IMEI en la parte trasera del localizador.',
    IMAGE_ALT: 'Foto del localizador',
    TITLE: 'Número IMEI'
  },
  login: {
    FORGOT_PASSWORD: '¿Olvidaste tu contraseña?',
    LOGIN_ERRORS: {
      UNKNOWN: 'Ha fallado la conexión'
    },
    SIGN_IN_BUTTON: 'Iniciar sesión',
    SIGN_IN_HEADLINE: 'Iniciar sesión',
    SIGN_UP_BUTTON: 'Crear cuenta',
    SIGN_UP_HEADLINE: '¿Aún no tiene cuenta?',
    TERMS_LINK: 'Condiciones de uso',
    TERMS_LINK_URL: 'https://www.weenect.com/common/pdf/en/cgv-weenect.pdf'
  },
  manageSubscription: {
    CANCEL: {
      DESCRIBE_YOUR_PROBLEMS: '¿Podría especificar el motivo de la cancelación de su suscripción?',
      GIVE_US_DETAILS: 'Motivo de la cancelación de su suscripción.',
      REASONS: {
        AFTER_SALES_SERVICE: 'Mala experiencia con el Servicio de Atención al Cliente',
        BATTERY: 'Autonomía de la batería insuficiente',
        LOST_BROKEN: 'La he perdido/roto.',
        MOBILE_APPLICATION: 'La aplicación no funciona',
        NOT_SATISFIED: 'No estoy conforme con Weenect.',
        NO_USE: 'Ya no la necesito.',
        TOO_EXPENSIVE: 'La suscripción es demasiado cara.',
        TRACKER_FORMAT: 'Formato del localizador por revisar (tamaño y peso)',
        WILL_REACTIVATE_LATER: 'Reactivaré la suscripción más tarde'
      },
      SAD_TO_SEE_YOU_LEAVE: 'Lamentamos que se vaya.',
      WHY_DO_YOU_RESIGN: '¿Por qué motivo quiere cancelar el plan?'
    },
    CANCEL_EXPIRE: 'Se ha cancelado la renovación automática. La suscripción caducará el {{date}}.',
    CANCEL_QUESTION: '¿No desea continuar con su suscripción?',
    CANCEL_RENEWAL: 'Cancelar la renovación automática',
    CANCEL_RENEWAL_BIS: 'Cancelar la suscripción',
    CANT_RESIGN_UNDER_EXTENDED_NOTICE: '¿Quiere cancelar su suscripción o simplemente desactivar la renovación automática de su suscripción?\nLlámenos al  +33 (0)1 87 66 98 71 de 09:30 a 16 h de lunes a viernes.',
    CANT_RESIGN_UNDER_NOTICE: 'No es posible cancelar su suscripción durante los últimos treinta días. Por favor contacte con el equipe de atención al cliente.',
    CANT_RESIGN_UNTIL: 'No puede cancelar su suscripción antes del {{date}}',
    CANT_RESIGN_WHILE_COMMITED: 'Estas comprometido por 3 meses, la terminación de su suscripción sólo tendrá efecto al final del período actual, es decir, el {{date}}',
    CURRENT_PLAN: 'Suscripción actual',
    HEADLINE: 'Gestionar suscripción',
    MESSAGES: {
      UPDATE_SUCCESS: 'Suscripción actualizada correctamente.'
    },
    RENEW_SUBSCRIPTION: 'Renovar suscripción',
    RESIGN_DATE: 'La terminación de su suscripción tendrá efecto al final del período actual, que es {{date}}',
    RETURN_TO_TRACKERS: 'Volver al mapa'
  },
  map: {
    NO_GPS_MARKER_TITLE: 'No hay señal GPS',
    POSITION: 'Posición'
  },
  mapControls: {
    MAP_TYPE: 'Tipo de mapa',
    REFRESH_POSITION: 'Actualizar posición',
    SUPER_LIVE: 'Super en vivo',
    ZOOM_IN: 'Zoom +',
    ZOOM_OUT: 'Zoom -'
  },
  mapLayers: {
    CLASSIC_MAPS: 'Mapas Clásico',
    DONT_SUBSCRIBE: 'Seguir con los mapas clásicos',
    FOREST_PATH: 'Senderos y caminos del bosque',
    GOOGLE_STREET: 'Google Maps',
    GRAY: 'Blanco y negro',
    PLAN: 'Plan',
    PREMIUM_MAPS: 'Mapas Premium',
    PREMIUM_SUBSCRIBE: 'Suscribirse a la opción mapas premium',
    PREMIUM_WARNING_MSG: 'Ups, aún no estás suscrito a la opción mapas Premium !\n\nEsta opción te permitirá aprovechar unos mapas más completos y actualizados de forma regular, para observar mejor los desplazamientos de tu localizador. \u2028Las versiones satélite HD, los mapas con relieve, o los caminos por el bosque te permitirán encontrar tu localizador fácilmente.\n\n¡Suscríbete a esta opción por solo {{mapOptionPrice}}/mes!',
    RELIEF: 'Relieves',
    SATELLITE: 'Satélite',
    STREET: 'Clásico',
    UNLOCK_MAPS: 'Desbloquea otras cartas haciendo clic en ellas.'
  },
  misc: {
    LANGUAGE: {
      DE: 'Alemán',
      EN: 'Inglés',
      ES: 'Español',
      FR: 'Francés',
      IT: 'Italiano',
      NL: 'Holandés'
    },
    NO_DATE: 'Ninguna posición conocida',
    ROTATE_DEVICE: 'Weenect se ha diseñado para usarse en modo vertical. Gire el dispositivo para aprovechar al máximo nuestros servicios.',
    locale: 'es-ES'
  },
  onboarding: {
    ACTIVATING_HINT: 'Duración media de activación de 5 minutos',
    ACTIVATING_INDICATOR: 'En proceso de activación',
    GREET: {
      BUTTON: 'Mi localizador está encendido',
      COMMON_TEXT: 'Para empezar bien, le recomendamos seguir este tutorial. Le ayudará en el manejo de su localizador.\n\nEmpecemos con la puesta en marcha de su localizador. Siga estas indicaciones para encenderlo:',
      MAIN_TITLE: '¡LE DAMOS LA BIENVENIDA A LA FAMILIA!',
      RED_TEXT: 'Es necesario que el localizador esté en el exterior para que pueda obtener coordenadas GPS'
    },
    MAP_INTERACTIONS: {
      COMMON_TEXT: {
        PET: 'De arriba abajo, estos botones le permiten hacer sonar o vibrar su localizador. Estas funciones le permiten enseñar a su mascota a acudir a la llamada.',
        SILVER: 'De arriba abajo, estos botones le permiten hacer sonar o vibrar su localizador. '
      },
      TITLE: 'Los botones de interacción con el localizador'
    },
    MAP_INTERVAL: {
      COMMON_TEXT: 'Este botón le permite elegir con qué frecuencia quiere recibir nuevas coordenadas GPS para su localizador. Atención, cuanto más corto es el intervalo, menos elevada será la autonomía del localizador.',
      TITLE: 'El intervalo de tiempo del informe del GPS'
    },
    MAP_OPTIONS: {
      COMMON_TEXT: 'Estos botones le permiten modificar el tipo de mapa que desea usar, y también le dejan acercar y alejar el zoom.',
      TITLE: 'Los botones de ajuste del mapa'
    },
    MAP_UPDATE: {
      COMMON_TEXT: 'Este botón le permite actualizar la posición de su localizador (para obtener una nueva posición, su localizador debe estar conectado al servidor y tener una señal GPS válida).',
      TITLE: 'El botón de actualización de la posición'
    },
    MODAL: {
      BUTTON_HINT: 'Pulse el botón durante 3 segundos hasta que el localizador vibre.',
      HEADLINE: 'Encienda el localizador al aire libre o en el alféizar de una ventana',
      IMAGE_ALT: 'Foto del localizador',
      MAP_HINT: 'Una vez encendido, el localizador se mostrará en el mapa en un plazo de 5 minutos de media.'
    },
    REMOTE_ASSISTANCE: {
      ASSISTANCE: {
        COMMON_TEXT: 'Si nadie reacciona en este periodo de tiempo, la alerta se transmite a un operador'
      },
      EXPLANATION: 'El operador se hace cargo de la llamada y responde a la necesidad del portador del localizador. Dependiendo de la situación, puede <1>ponerse en contacto con parientes o pedir ayuda.</1>.',
      PRICE: 'La opción cuesta 9,99 € al mes y lo puede desactivar en cualquier momento.',
      SOS: {
        COMMON_TEXT: 'Su pariente pulsa el botón de SOS de su localizador.'
      },
      TIME: {
        COMMON_TEXT: 'Se le avisa y tiene 30 segundos para responder a la alerta.'
      },
      TITLE: 'Descubra nuestra nueva opción: la teleasistencia ',
      USE_CASE: 'Esta opción permite poner al portador del localizador en contacto con un departamento especializado de teleasistencia disponible las 24 horas al día, los 7 días de la semana.'
    },
    SIDE_PAGES: {
      COMMON_TEXT: {
        PET: 'En el panel izquierdo, puede encontrar todas las páginas de gestión y uso de su localizador. La pestaña {{ Historial }} le permite consultar los desplazamientos de su mascota y la pestaña {{ Control de actividades }}, conocer su consumo de energía. La pestaña {{ Zonas }} le permite configurarlas, la pestaña {{ Parámetros }} contiene el perfil de su mascota y la pestaña {{ Suscripción }} le permite gestionar su suscripción, así como sus opciones muy fácilmente.',
        SILVER: 'En el panel izquierdo, puede encontrar todas las páginas de gestión y uso de su localizador. La pestaña < Historial > le permite consultar los desplazamientos de su familiar y la pestaña < Zonas > le permite configurar zonas de seguridad.\n La pestaña < Parámetros > le permite configurar el número al que llamar en caso de emergencia, así como los mensajes asociados a cada botón lateral del localizador. Por último, la pestaña <Suscripción> le permite gestionar su suscripción, así como sus opciones muy fácilmente.'
      },
      TITLE: 'Páginas adicionales'
    },
    SKIP: 'Saltar el tutorial',
    SUPERLIVE: {
      COMMON_TEXT: 'Este botón le permite activar el modo "superlive": una nueva posición cada 10 segundos durante 5 minutos.',
      TITLE: 'El botón "superlive"'
    },
    TUTORIAL_START: {
      BUTTON: 'Continuar con el tutorial',
      COMMON_TEXT: 'Perfecto, su localizador ya está listo para su uso. Sigamos con una breve introducción de su espacio personal My Weenect.'
    },
    WAITING_GPS_POSITION: 'Búsqueda de una posición GPS',
    WAITING_GPS_POSITION_HINT: 'Encienda su localizador en el exterior'
  },
  optionCardExpired: {
    PROPOSAL: 'Actualice su tarjeta bancaria para seguir disfrutando de nuestros servicios.',
    UPDATE_CARD: 'Actualizar ahora',
    WARNING: '<1>¡Atención!</1>No puede suscribirse a esta opción, puesto que <1>su tarjeta bancaria ha caducado</1>.'
  },
  payment: {
    ADD_NEW_PAYMENT_REGULARISE: 'Registre un nuevo método de pago o regularice su situación.',
    EXPIRACY_CREDIT_CARD_TEXT: 'Añada un nuevo método de pago para regularizar su situación.',
    NO_PAYMENT_MEAN_YET: 'Ningún medio de pago por el momento',
    NO_PAYMENT_YET: 'Ningún medio de pago en este momento',
    UPDATE_PAYMENT_REGULARISE: 'Por favor, actualice o cambie la información para regularizar su situación.',
    WARNING_ERROR: 'Parece que hay un problema con su forma de pago.',
    WARNING_EXPIRACY_CREDIT_CARD: 'Cuidado, su tarjeta de crédito ha caducado.',
    WARNING_NOT_ENOUGH_FOUNDS: 'Atención, parece que no hay fondos suficientes en su cuenta.'
  },
  payments: {
    CARD: {
      ACCEPTED: '(CB, VISA, Mastercard, American Express)',
      DESCRIPTION: 'Plazo de devolución de 30 días',
      NAME: 'Tarjeta de crédito'
    },
    STATUS: {
      CANCELED: 'Cancelado',
      ERROR: 'Fallido',
      NEW: 'Procesándose',
      PAYMENT_ERROR: 'Error en el pago',
      PAYMENT_ERROR_EXPLAIN_TEXT: 'Ha ocurrido un error en el pago. Vuelve a probar en unos instantes o ponte en contacto',
      PAYMENT_RETRY: 'Volver a intentar',
      PAYMENT_SAV: 'el servicio de atención al cliente.',
      PAYMENT_SUCCESS: '¡Pago terminado!',
      PAYMENT_SUCCESS_TEXT: 'Se ha registrado correctamente el pago. Ya puedes disfrutar de tu experiencia en Weenect.',
      PAYMENT_SUCCESS_TEXT_APP: 'Se ha registrado correctamente el pago.',
      PENDING: 'Procesándose',
      REFUNDED: 'Reembolsado',
      SUCCESS: 'Confirmado'
    },
    TRANSFER: {
      DESCRIPTION: '15 días adicionales para cambiar de opinión, es decir, 30 días con opción de reembolso',
      NAME: 'Adeudo bancario'
    }
  },
  periods: {
    ALT: {
      MONTH: '1 mes',
      YEAR: '1 año',
      YEAR_plural: '{{count}} años'
    },
    BASE: {
      MONTH: 'mes(es)',
      YEAR: 'año'
    }
  },
  registerSummaryTable: {
    BILLED_MONTH: 'El pago es mensual',
    BILLED_NOW: 'Facturado inmediatamente',
    BILLED_THREE_YEARS: 'Il pagamento viene effettuato una volta ogni tre anni',
    BILLED_TWO_YEARS: 'Facturada una vez cada dos años',
    BILLED_YEAR: 'El pago se efectúa una vez al año',
    HINT_OPTIONS_PRICE: 'Las opciones, por un importe de {{amount}}, se facturarán aparte de la suscripción dentro de dos horas.',
    OPTION_UPGRADE: 'Añadir la opción {{name}} por solo {{price}}/{{period}}',
    PAY_HINT: 'Haga clic en el botón "Pagar y activar" y pague {{subscriptionPrice}} por la suscripción.',
    SUBSCRIPTION_UPGRADE: 'Cambie a una suscripción de 2 años y ahorre {{amount}} al año.',
    TOTAL: 'Precio total'
  },
  registerTracker: {
    BUY_LINK: '¿Aún no tiene un localizador Weenect? <1>Puede adquirirlo aquí</1>',
    ERRORS: {
      UNKNOWN: 'Ha habido un problema al activar el localizador, inténtelo de nuevo'
    },
    HEADLINE: 'Activar un nuevo localizador',
    IMEI_INPUT: {
      LABEL: 'Número IMEI',
      PLACEHOLDER: 'Encontrará el número IMEI en la parte trasera del localizador.'
    },
    NAME_INPUT: {
      LABEL: 'Nombre del localizador',
      PLACEHOLDER: 'Nombre del usuario del localizador'
    }
  },
  remoteAssistance: {
    BASE: 'Teleasistencia',
    FEATURES: 'Tras pulsarse el botón SOS, si no responde en 30 segundos, la alerta SOS y la llamada de voz se transmiten automáticamente, las 24 horas, 7 días a la semana, a nuestra central de teleasistencia. Nuestros agentes están autorizados a ponerse en contacto con servicios de emergencia como la policía o el 112.',
    FORM: {
      ADDRESS_INPUT: {
        LABEL: 'Dirección',
        PLACEHOLDER: 'Nombre y número de la vía'
      },
      BACK: 'Cancelar',
      CITY_INPUT: {
        LABEL: 'Localidad',
        PLACEHOLDER: ''
      },
      EXTRA_PHONE: {
        LABEL: 'Teléfono secundario (opcional)',
        PLACEHOLDER: ''
      },
      HEADLINE: 'Registro en la teleasistencia',
      NAME_INPUT: {
        LABEL: 'Nombre completo',
        PLACEHOLDER: 'Nombre APELLIDOS'
      },
      PHONE: {
        LABEL: 'Teléfono',
        PLACEHOLDER: ''
      },
      POSTAL_CODE_INPUT: {
        LABEL: 'Código postal',
        PLACEHOLDER: ''
      },
      SAVE: 'Confirmar',
      TERMS: 'He leído y acepto <1>las condiciones de uso</1> del servicio',
      TEXT: 'Introduzca los datos del usuario del localizador. Nuestros agentes podrán atenderle en caso de emitirse una alerta SOS.'
    },
    PENDING: {
      WAITING_FOR_CALL_TEST: 'Formulario de inscripción validado. Pendiente de la llamada de prueba.\nAhora debe efectuar una llamada de prueba para finalizar la activación de la opción. Consulte el correo electrónico que ha recibido para ver el procedimiento que debe seguir.',
      WAITING_FOR_RESIGN: 'Desactivación de la opción en curso.\nSe ha recibido su solicitud para desactivar la opción. Nuestros agentes se encargan actualmente de las formalidades administrativas. La desactivación se efectuará en breve.',
      WAITING_FOR_VALIDATION: 'Formulario de inscripción en curso de validación. \nNuestros agentes están verificando la información que proporcionó al suscribirse a la opción. Una vez validada esta información, procederemos al último paso para activar la opción: deberá efectuarse una llamada de prueba para comprobar que todo funciona correctamente.'
    },
    USER_PHONE: {
      TEXT: 'Introduzca sus datos. Nuestros agentes podrán contactar con usted si se activa una alerta SOS.'
    }
  },
  signup: {
    ALREADY_MEMBER_TEXT: '¿Ya tiene cuenta?',
    CREATE_ACCOUNT_HEADLINE: 'Crear cuenta',
    LOGIN_TEXT: 'Conectarse',
    MARKETING_AGREEMENT_TEXT: 'Acepto recibir ofertas y promociones de los socios de Weenect',
    RETRY: 'Intentar de nuevo',
    SIGNUP_ERRORS: {
      EMAIL_ALREADY_EXISTS: 'Esta dirección de e-mail ya está asociada con una cuenta',
      POPIN_EMAIL_ALREADY_EXIST: '<1>Ups</1>, parece que este correo ya tiene una cuenta',
      UNKNOWN: 'Ha fallado la conexión, reintentar'
    },
    SIGN_IN_TEXT: 'Iniciar sesión',
    SUBMIT_BUTTON: 'Crear mi cuenta',
    TERMS_AGREEMENT_TEXT: 'Acepto <1>las condiciones de uso</1> del servicio'
  },
  simInfo: {
    TITLE: 'Número SIM'
  },
  subOptions: {
    CANCEL: {
      EXPIRATION_DATE: 'Esta opción seguirá estando activa hasta el {{date}}',
      HEADLINE: '¿Desea cancelar la opción {{option}}?'
    },
    MAP: {
      BASE: 'Pack multi-mapas',
      FEATURES: 'Aprovecha unos mapas más completos y actualizados de forma regular, para observar mejor los desplazamientos de tu localizador. (Satélite HD, mapa con relieve, caminos por el bosque).',
      TITLE: 'Mapas en alta definición: '
    },
    PREMIUM: {
      BASE: 'Paquete Premium',
      BUTTON: {
        FREEMONTH: '¡Pruébalo gratis durante 1 mes!'
      },
      DEACTIVATE: 'Al desactivar esta opción, perderá los beneficios de la prima.',
      FEATURE: {
        CHAT: {
          BASE: 'Chat en línea',
          TEXT: 'Chatee directamente con un asesor de Weenect en directo en su aplicación, los 7 días de la semana, de 9 a 18 horas.',
          TEXT2: 'Disfrute de ventajas exclusivas (chat en línea, alertas por SMS, mapas en alta definición) dentro de la aplicación.'
        },
        SMS: {
          TEXT: 'Reciba directamente por SMS todas las notificaciones que elija (40 SMS al mes).'
        }
      },
      FREEMONTH: {
        BASE: 'Primer mes gratis',
        BASE2: 'GRATIS'
      },
      SUBTITLE: 'El Pack Premium incluye las siguientes características:',
      SUB_LEGAL: 'Esta afiliación se renovará automáticamente cada mes, a través de la forma de pago elegida al suscribirte. Puedes cancelar la afiliación en cualquier momento a través de tu espacio personal.',
      TEXT_INFO_PRICE: 'El servicio se cobra a {{price}}/mes después del primer mes.',
      TITLE_DISCOVER: 'Descubra sus ventajas exclusivas: ',
      TITLE_POPUP: '¡Conviértase en un miembro Premium!'
    },
    SMS_PACK: {
      ALT_FEATURES: 'Reciba todas las alertas por SMS',
      BASE: 'Pack SMS',
      FEATURES: 'Recibe todas las alertas por SMS (20 SMS disponibles al mes). Ten en cuenta que sin esta opción recibirás las alertas mediante pushs (aplicación) y correos electrónicos.'
    },
    UNDEFINED: {
      PAYMENTS: 'Pago para la opción'
    },
    WARRANTY: {
      ALT_FEATURES: {
        one: 'Consiga gratuitamente un nuevo localizador en caso de rotura, pérdida o robo de su localizador actual.',
        other: 'Pago con tarjeta de débito con posibilidad de cancelación en cualquier momento',
        two: 'Su plotter reemplazado en 48H en caso de pérdida, rotura o robo, sin condiciones.'
      },
      BASE: 'Garantía multirriesgo',
      EXPLAINER: 'La garantía estándar solo cubre los problemas de fabricación del localizador.',
      FEATURES: 'Consigue un localizador de sustitución gratuito en caso de que tu localizador actual se rompa, pierda o haya sido robado; no se pide justificación. La garantía estándar solo cubre los problemas de fabricación del localizador.'
    }
  },
  subscription: {
    COMMON_TEXT: {
      MONTH: '/ mes',
      OR: 'Osea'
    },
    FEATURES: {
      APP_ACCESS: 'Acceso ilimitado a la aplicación y al servicio de localización',
      REFOUND: '15 días de prueba: satisfecho o le devolvemos el dinero',
      SAV: 'Servicio de atención al cliente disponible 6/7 días. \nRespuesta garantizada en 24 horas.',
      WARRANTY: '24 meses de garantía del fabricante'
    },
    INFO_CGU: 'Para beneficiarse de las suscripciones, debe introducir información bancaria válida y aceptar las condiciones generales',
    INFO_CGU_BUTTON: 'las condiciones generales de uso del servicio.',
    INFO_OPTION: 'El pago es mensual, la cancelación es posible en cualquier momento.',
    MONEY_BACK: 'Todas las suscripciones se facturan inmediatamente, pero pueden cancelarse en un plazo de 15 días, con el reembolso íntegro del importe pagado.',
    PAYMENT_INFO: 'Garantía de devolución del dinero: 15 días para probar el rastreador',
    PAYMENT_SUBHEADLINE: 'Seleccione un método de pago para pagar su suscripción y activar su rastreador.',
    PAYMENT_TITLE: 'Elija su método de pago',
    PAYMENT_TITLE2: 'Registre su método de pago',
    PRICE_MONTH_SAVED: 'osea {{price}} / mes',
    SUMMARY: 'Resumen'
  },
  subscriptionAlert: {
    ACTIVATE_AUTO_RENEWAL: 'Volver a activar la renovación automática',
    AUTO_RENEWAL_ADVICE: 'Le aconsejamos que vuelva a activar la renovación automática si quiere prolongar su suscripción',
    AUTO_RENEWAL_NOTICE: '<1>¡Atención!</1> Su suscripción para el localizador {{trackerName}} va a terminar y la renovación automática está desactivada.',
    CONTINUE_BUTTON: 'Continuar',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema',
      UPDATE_SUCCESS: 'Actualizado correctamente'
    },
    REMIND_LATER: 'Recuérdamelo más tarde',
    SUPPORT_TEXT: 'Si tiene preguntas o cree que es un error, póngase en contacto con nuestro <1>Servicio de Atención al Cliente</1>.'
  },
  subscriptionOptionsSelection: {
    CANCEL_WARNING_MODAL_HEADLINE: '¿Seguro que desea desactivar esta opción?',
    CANCEL_WARNING_SMS: 'Al desactivar esta opción, no recibirá las notificaciones de las alertas por SMS.',
    CANCEL_WARNING_WARRANTY: 'Al desactivar esta opción, perderá la protección adicional que aporta la garantía multirriesgos.',
    EXPLANATION: 'Por defecto, las opciones están seleccionadas, pero las puede deseleccionar haciendo clic.',
    EXPLANATION2: 'And cancellation is possible at any time.',
    EXPLANATION3: 'No se efectuará ningún pago.',
    HINT: 'Puede activar o desactivar una opción en todo momento',
    MAIN_HEADLINE: 'Elija sus opciones',
    SUBMIT_BUTTON: 'Confirmar'
  },
  subscriptionPayment: {
    ERRORS: {
      UNKNOWN: 'Se ha producido un problema durante el pago, inténtelo de nuevo'
    },
    GIFT_CARD: {
      EXPLANATION: 'Introduzca el código que se encuentra en el reverso de la tarjeta que se le entregó en su paquete de seguimiento.',
      I_HAVE_ONE: 'Tengo una tarjeta de regalo',
      NEED_HELP: '¿Necesita ayuda con las tarjetas regalo?',
      TITLE: 'Utilice su regalo',
      USE: 'Utilizar la tarjeta de regalo'
    },
    HEADLINE: 'Resumen previo al pago',
    PROMOCODE_TEXT: 'Tengo un código de cupón:',
    PROMO_CODE: 'Código del cupón',
    PROMO_CODE_GIFT_CARD: 'Tarjeta de regalo',
    SATISFIED_OR_REFUNDED: 'Garantía satisfecho o reembolsado',
    SATISFIED_OR_REFUNDED_DESCRIPTION: '30 días para probarla sin riesgo; si no está satisfecho, le reembolsamos.',
    SELECT_PAYMENT_MEAN: 'Seleccione una forma de pago antes de pagar y activar su suscripción.',
    SUBMIT_BUTTON: 'Pagar la suscripción',
    SUBMIT_BUTTON2: 'Activar el rastreador',
    SUMMARY: 'Resumen de su elección'
  },
  subscriptionProcess: {
    GIFT_CARD_EXPLANATION: 'Introduzca su método de pago preferido para activar su rastreador de forma gratuita.',
    GIFT_CARD_EXPLANATION2: 'Esta información es necesaria por motivos legales relacionados con el uso de tarjetas SIM.',
    RETURN_TO_APP: 'Volver a la aplicación',
    RETURN_TO_MAP: 'Volver al mapa',
    RETURN_TO_TRACKERS: 'Volver al mapa',
    STEPS: [
      'Localizador',
      'Suscripción',
      'Opciones',
      'Resumen'
    ]
  },
  subscriptionSelection: {
    BEST_OFFER: 'MEJOR OFERTA',
    CANCELABLE_AFTER_THREE_MONTHS: 'Posibilidad de cancelación en cualquier momento después de los tres primeros meses',
    CANCEL_ANYTIME: 'Posibilidad de cancelación en cualquier momento',
    COMMITMENT_TEXT: 'Puede rescatarse en cualquier momento tras el periodo mínimo de compromiso de 3 meses.',
    FEATURES: '',
    FEATURES_HEADLINE: 'Todas las suscripciones incluyen:',
    HEADLINE: 'Elja su suscripción',
    MIN_THREE_MONTHS: 'Compromiso mínimo de tres meses',
    MONEY_BACK: 'Todas las suscripciones se facturan de inmediato, pero pueden cancelarse en un plazo de 30 días, con reembolso integral del importe abonado.',
    PAYMENT_MEANS: 'Pago por domiciliación bancaria o tarjeta de crédito',
    REFUNDED_INFO: 'Le reembolsaremos la suscripción sin condiciones.',
    REFUNDED_TEXT: 'Satisfecho o reembolsado 30 días.',
    SIM_CARD_WARNING: 'El localizador tiene una tarjeta SIM ya integrada. Esto explica que sea necesario contratar una suscripción (para cubrir los gastos de servicio).',
    SUBMIT_BUTTON: 'Confirmar',
    XMAS_OFFER: {
      BODY: 'Sin embargo estamos obligados a pedirle elegir su futura suscripción ahora (por razones de seguridad, como el aparato viene con una tarjeta sim). No habrá ningún débito antes del final de los 6 meses gratis, y puede cancelar esta suscripción en cualquier momento hasta un mes antes de la fecha del comienzo efectivo.',
      TITLE: 'Su localizador está precargado con 6 meses de suscripción regalado por su ser querido.'
    },
    features: [
      'Plazo de devolución de 30 días',
      'Localización GPS ilimitada',
      'Notificaciones push (aplicación) y correos electrónicos',
      '10 minutos de llamada de voz al mes'
    ]
  },
  subscriptions: {
    BASE: 'Suscripción',
    CANCEL_MODAL: {
      HEADLINE: '¿Desea cancelar la renovación automática de su suscripción?',
      HINT: 'Su suscripción seguirá activa hasta el {{date}}, pero no se renovará automáticamente. Después de esta fecha, ya no podrá localizar su localizador.'
    },
    CHANGE_PLAN: {
      ADD_OPTIONS: 'Añadir una opción',
      EXPLANATION: 'El cambio de suscripción se efectuará al finalizar el período en curso.',
      HEADLINE: '¿Cambiar de suscripción?'
    },
    DETAILS: {
      ACTIVATE_BUTTON: {
        LABEL: 'Activar suscripción'
      },
      ACTIVE_CASE: {
        MANAGE_BUTTON: 'Gestionar suscripción',
        RENEWAL_INFO: 'Su suscripción se renovará automáticamente el {{date}} por un importe de {{price}}.'
      },
      CANCELED: {
        EXPIRED_MAIN: 'Su suscripción ha caducado. Reactive la suscripción para poder localizar su GPS.',
        EXPIRED_SEC: 'Caducado',
        UNCANCEL_BUTTON_LABEL: 'Reactivar la renovación automática de la suscripción',
        WILL_EXPIRE_DAYS: 'Caduca dentro de {{count}} día',
        WILL_EXPIRE_DAYS_plural: 'Caduca dentro de {{count}} días',
        WILL_EXPIRE_MAIN: 'Se ha cancelado la renovación automática de su suscripción. Caducará el {{date}}.'
      },
      CANCEL_RESILLIATION: 'Cancelar la solicitud de anulación',
      NEW_CASE: {
        INFO_TEXT: 'Ha habido un problema con su pago. Inténtelo de nuevo o cambie el método de pago.',
        PAY_BUTTON: 'Pagar y activar'
      },
      NO_SUB: {
        INFO_TEXT: 'Active la suscripción para empezar a usar su localizador.'
      },
      RESILLIATION_ASKED: 'Baja solicitada',
      SUPPORT_TEXT: '¿Tiene problemas para activar su localizador? Contacte con el servicio de <1>asistencia</1>.'
    },
    OFFERS: {
      SAVING_PER_YEAR: 'ahorre {{amount}}/año'
    },
    OFFERS_TEXT: {
      MOST_POPULAR: 'La más popular',
      ONE_MONTH: {
        INFO: 'Compromiso mínimo de 3 meses',
        TITLE: 'Paquete de 1 mes'
      },
      ONE_YEAR: {
        INFO: '15 días de prueba adicionales: 30 días en total',
        TITLE: 'Paquete de 1 año'
      },
      THREE_YEAR: {
        INFO: '1 año de garantía adicional del fabricante: 3 años en total',
        INFO2: '3-year manufacturer\'s warranty (instead of two).',
        TITLE: 'Paquete de 3 años'
      }
    },
    OVERVIEW: {
      OPTIONS: 'Detalles de las opciones',
      REG_DETAILS: 'Datos del localizador',
      REPLACE_TRACKER_BUTTON: {
        LABEL: 'Sustituir localizador'
      },
      SUB_DETAILS: 'Datos de la suscripción'
    },
    PLAN_NAMES: {
      FALLBACK: 'Suscripción',
      ONE_MONTH: 'Suscripción mensual',
      ONE_YEAR: 'Suscripción prepaga 1 año',
      THREE_YEARS: 'Suscripción prepaga 3 años',
      TWO_YEARS: 'Suscripción 2 años'
    },
    STATUS: {
      ACTIVE: 'Suscrito',
      CANCELED: 'Cancelado',
      INACTIVE: 'No suscrito',
      NEW: 'Activo',
      PENDING: 'Pendiente'
    },
    TRACKER_DETAILS: {
      REGISTRATION_DATE: 'Fecha de activación',
      WARRANTY: 'Garantía estándar'
    }
  },
  superLive: {
    ACTIVE_TEXT: 'El modo Super live está activo',
    HEADLINE: 'Modo Super live',
    MESSAGES: {
      SUCCESS_ACTIVATED: 'Modo Super live activado correctamente: su localizador enviará una posición cada {{frequency}} segundos durante 5 minutos'
    },
    TIME: 'Una posición cada {{frequency}} segundos'
  },
  support: {
    FORM: {
      MESSAGE: {
        LABEL: 'Su mensaje*',
        PLACEHOLDER: ''
      },
      PROBLEM: {
        AVAILABLE_OPTIONS: 'Opciones disponibles',
        LABEL: 'Tipo de problema experimentado*'
      },
      SUBJECT: {
        LABEL: 'Asunto de su solicitud*',
        PLACEHOLDER: ''
      },
      TRACKER: {
        LABEL: 'Localizador asociado a su solicitud',
        PLACEHOLDER: 'Seleccione el localizador'
      }
    },
    HEADLINE: 'Asistencia',
    HELP_CENTER: 'Centro de ayuda',
    HELP_TEXT: '¿Necesita ayuda? Visite nuestro centro de ayuda para hallar respuesta a su pregunta. También puede ponerse en contacto con nosotros utilizando el siguiente formulario; nuestro equipo le responderá en un plazo de 24 horas hábiles.',
    MAIN_TEXT: 'Nuestro centro de ayuda contiene todos los artículos que pueden responder a sus preguntas.',
    MESSAGES: {
      SUBMIT_ERROR: 'Ha ocurrido un error, inténtelo de nuevo',
      SUBMIT_SUCCESS: 'Mensaje enviado correctamente'
    },
    PROBLEM_TYPES: {
      CHARGE: 'Mi localizador no se carga normalmente',
      DOWNLOAD_APP: 'No consigo descargar la aplicación',
      GPS_INVALID: 'Mi señal de GPS indica que no es válida',
      GPS_PRECISION: 'La posición GPS de mi localizador es incorrecta',
      LEDS_FIXED: 'Los dos ledes de mi localizador permanecen fijos',
      NO_GEOFENCE_ALERTS: 'Recibo alertas falsas de entrada o salida de la zona',
      NO_NOTIFICATIONS: 'No recibo las notificaciones',
      OTHER: 'Otros',
      SIGN_IN: 'No puedo conectarme a mi espacio personal',
      SUBSCRIPTION: 'Tengo un problema con mi suscripción',
      TRACKERS_PARAMS: 'No puedo configurar mi localizador',
      TRACKER_NOT_CONNECTED: 'Mi localizador indica que no está conectado'
    },
    SUBMITTED_TICKET: 'Hemos recibido su solicitud, gracias.\nLe hemos enviado un correo electrónico de confirmación (revise su bandeja de correo no deseado).',
    SUB_TEXT: 'Si estas explicaciones no son suficientes, también puede ponerse en contacto con un miembro de nuestro equipo de servicio, que le responderá en un plazo de 24 horas laborables.',
    TITLE: '¿Necesitas ayuda?',
    URGENT_NUMBER: 'Si su solicitud es urgente, puede ponerse en contacto con nuestro Servicio de Atención al Cliente al llamar el \n+33 187 660 083 de 10 a 12 h y de 14 a 17 h, de lunes a viernes.'
  },
  trackerLeftPanel: {
    CALL: 'Llamada',
    CTR_ONLY_SECONDS: 'Le quedan {{SECONDS}} segundo(s) de llamada telefónica para el mes en curso.',
    CTR_WITH_MINUTES: 'Le quedan {{MINUTES}} minutos(s) y {{SECONDS}} segundo(s) de llamada telefónica para el mes en curso.',
    FLASH: 'Flash',
    GOOD_GPS_ACCURACY: 'Precisión GPS media',
    LAST_POSITION: 'Última posición conocida:',
    MEDIUM_GPS_ACCURACY: 'Precisión GPS media',
    OFFLINE: 'Localizador desconectado',
    POOR_GPS_ACCURACY: 'Precisión GPS mala',
    RING: 'Timbre',
    SMS_AVAILABLE: 'SMS disponibles : {{SMS_count}}',
    VIBRATE: 'Vibración'
  },
  trackerSettings: {
    BUTTONS: {
      LABELS: {
        BUTTON: 'Botón'
      },
      PLACEHOLDER_BUTTON: 'Introduzca el mensaje enviado por el botón {{button_number}}',
      TEXT: 'Configurar el mensaje asignado a cada botón del localizador. Lo recibirá en las notificaciones activadas al pulsar el botón correspondiente.'
    },
    CONTACTS: {
      ADD_EMAIL_BUTTON: 'Agregar dirección de correo electrónico',
      ADD_PHONE_BUTTON: 'Agregar teléfono móvil',
      EMAILS_LABEL: 'Direcciones de correo electrónico para las notificaciones',
      MAIL: {
        PLACEHOLDER: 'votre@email.com'
      },
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Ha ocurrido un error, inténtelo de nuevo',
        UPDATE_SUCCESS: 'Contactos actualizados correctamente'
      },
      NUMBERS_LABEL: 'Números de teléfono para las notificaciones por SMS',
      PHONE: {
        PLACEHOLDER: '+33600000000'
      }
    },
    DISPLAY: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Se ha producido un problema',
        UPDATE_SUCCESS: 'Configuración actualizada correctamente'
      },
      MODAL: {
        HEADLINE: 'Seleccionar icono',
        TEXT: 'Puede seleccionar el icono que desee en la lista a continuación. Representará a su localizador en el mapa.'
      },
      TRACKER_ICON: {
        EDIT_TEXT: 'Cambiar icono',
        LABEL: 'Icono del localizador'
      },
      TRACKER_NAME: {
        LABEL: 'Nombre del localizador',
        PLACEHOLDER: 'Nombre que se mostrará en el mapa'
      },
      TRACKER_TYPE: {
        CHANGE: 'Cambiar de tipo de localizador',
        MODIFIED_SUCCESS: 'Tipo de localizador modificado con éxito'
      }
    },
    GPS_FREQUENCY: {
      BATTERY_USAGE: 'Duración media de la batería:',
      BATTERY_USAGE_LEVELS: {
        HIGH: 'Alta',
        LOW: 'Baja',
        MEDIUM: 'Media'
      },
      GEOFENCE_NOTIFICATIONS: 'Espera media de la sensibilidad de las alertas de zona:',
      HINT: 'Una frecuencia más rápida reduce la duración de la batería, pero mejora la sensibilidad de las alertas de entrada y salida de zonas. Por el contrario, una frecuencia más lenta aumenta la duración de la batería, pero reduce la sensibilidad de las alertas de entrada y salida de zonas.',
      TEXT: 'Seleccione el intervalo de tiempo del informe del GPS'
    },
    NOTIFICATION_SETTINGS: {
      APP: 'Aplicación',
      BATTERY: {
        HINT: 'Envía una notificación a todos los contactos si el localizador llega al nivel de batería baja.',
        TITLE: 'Batería'
      },
      BUTTONS: {
        HINT: 'Envía una notificación a todos los contactos cuando se pulsa uno de los botones laterales. El mensaje configurado para este botón se incluirá en la notificación.',
        TITLE: 'Botones laterales'
      },
      GEOFENCES: {
        HINT: 'Envía una notificación a todos los contactos si el localizador entra o sale de una zona configurada.',
        TITLE: 'Zonas de seguridad'
      },
      MAIL: 'Correo',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Ha ocurrido un error, inténtelo de nuevo',
        UPDATE_SUCCESS: 'La configuración de las notificaciones se ha actualizado correctamente'
      },
      SMS: 'SMS',
      SOS: {
        HINT: 'Envía una notificación a todos los contactos cuando se pulsa el botón SOS.',
        TITLE: 'Llamada SOS'
      },
      WARNING: {
        SMS: {
          LINK: 'Haga clic aquí para activarla.',
          TEXT: 'Atención: si desea recibir las alertas por SMS, debe activar la opción correspondiente.'
        }
      }
    },
    SECTION_TITLES: {
      BUTTONS: 'Botones',
      CONTACTS: 'Contactos para las notificaciones',
      DISPLAY: 'General',
      GPS_FREQUENCY: 'Intervalo de tiempo del informe del GPS',
      NOTIFICATION_SETTINGS: 'Tipos de notificaciones para cada alerta',
      SOS: 'Número SOS',
      TRAINING: 'Función teléfono'
    },
    TRAINING_SOS: {
      DIRECT_CALL: 'Llame a este número directamente si se presiona el botón SOS',
      INPUT_PLACEHOLDER: '+33600000000',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Ha ocurrido un error, inténtelo de nuevo',
        UPDATE_SUCCESS: 'Número de teléfono actualizado correctamente'
      },
      SOS_TEXT: 'Número de teléfono que recibirá las llamadas en caso de emitirse una alerta SOS',
      TRAINING_TEXT: 'Escriba el número de teléfono móvil al que llamará por defecto el localizador si se utiliza la función de teléfono desde la aplicación. Este número puede editarse en cada solicitud de llamada desde la aplicación.'
    }
  },
  trackers: {
    ADD_TRACKER_BUTTON: {
      LABEL: 'Añadir localizador'
    },
    EXPIRATION: {
      EXPIRED: 'Ha caducado la suscripción de {{tracker}}',
      WILL_EXPIRE: 'La suscripción de {{tracker}} caducará dentro de {{days}} días'
    },
    LINKS: {
      ACTIVITY: 'Seguimiento de la actividad',
      GEOFENCES: 'Zonas',
      HISTORY: 'Historial',
      SETTINGS: 'Configuración',
      SUBSCRIPTION: 'Suscripción'
    }
  },
  trackersPositionRefresh: {
    MESSAGES: {
      NOT_REFRESHED: 'Su localizador no ha podido obtener nuevas posiciones GPS',
      REFRESHING: 'Espere, se está solicitando una actualización',
      SUCCESS: 'Posiciones GPS de los localizadores actualizadas correctamente'
    }
  },
  transferPage: {
    ADD_TRACKER: 'Quiero añadir un nuevo localizador a mi cuenta',
    BACK_TO_TRACKERS: 'Volver al mapa',
    FIRST_TITLE: '¿Qué quiere hacer exactamente?',
    FROM_HEADLINE: 'Localizador que se sustituirá:',
    HEADLINE: 'Sustituir un localizador',
    IMEI_LABEL: 'Número IMEI',
    INFO: 'La suscripción actual, así como el historial de posiciones GPS y la configuración, se transferirán al nuevo localizador tras la sustitución.',
    MESSAGES: {
      ERROR_UNKNOWN: 'Ha ocurrido un error, inténtelo de nuevo',
      SUCCESS: 'Nuevo localizador asignado correctamente',
      WRONG_TYPE: 'Der GPS-Sender mit dieser IMEI-Nummer entspricht nicht dem Typ Ihres alten Senders.'
    },
    NAME_LABEL: 'Nombre del localizador',
    PURCHASE: '¿Aún no tiene un localizador Weenect? <1>Puede adquirirlo aquí</1>',
    REPLACE_BUTTON: {
      LABEL: 'Sustituir'
    },
    REPLACE_TRACKER: 'Quiero sustituir mi localizador por uno nuevo',
    TO_HEADLINE: 'Nuevo localizador:'
  },
  webappDisconnection: {
    BUG: 'Parece que el localizador tiene dificultades para conectarse a la red.',
    MANUAL: 'Has apagado el localizador. Pulsa el botón ON para encenderlo.',
    NO_BATTERY: 'Tu localizador se apagó porque no le quedaba batería.',
    NO_SUB: 'No tienes suscripción para este localizador. Escoge una desde la página'
  },
  wifiZones: {
    AIRPLANE_TEXT: 'Advertencia: en el modo avión, mientras el localizador esté en la Zonas de Ahorro de energía, ya no podrás hacer sonar, vibrar o actualizar manualmente su posición.',
    DEEP_SLEEP_TITLE: 'Modo avión',
    DISPLAY_BUTTON: 'Mostrar la zona en el mapa',
    DISPLAY_TITLE: 'Notificaciones y visualización',
    IN_AIRPLANE: '- en modo avión',
    IN_OUT_TEXT: 'Entradas y salidas de zona:',
    LAST_POS_IN_WIFI: 'En el Zonas de Ahorro de energía desde {{date}}',
    MAIL_NOTIF: 'Notificaciones mail',
    NOTIF_TEXT: 'Reciba una notificación por cada entrada o salida de la zona predefinida.',
    POPIN: {
      AIRPLANE: {
        BUTTON_VALIDATE: 'Salir del modo avión',
        CONTENT: 'Ya no puedes comunicarte con tu rastreador mientras esté en la <span style="color: #333333; font-family: Verdana, sans-serif;"><span>Zonas de Ahorro de energía</span></span>. Si quieres recuperar la funcionalidad completa,<strong> desactiva el modo avión y saca el rastreador de la zona. </strong>',
        TITLE: 'Tu localizador está en modo avión en la Zonas de Ahorro de energía {{wifiName}}.'
      },
      BUTTON_CLOSE: 'Hazlo después',
      BUTTON_VALIDATE: 'Desactivar la zona',
      CONTENT: 'En esta zona,<strong> la subida automática de las posiciones GPS de tu localizador está desactivada</strong>, pero puedes tocarlo para encontrarlo. <strong>También puedes desactivar la Zonas de Ahorro de energía</strong>.',
      TITLE: 'Tu localizador está en la Zonas de Ahorro de energía {{wifiName}}.'
    },
    PUSH_NOTIF: 'Notificaciones push',
    SMS_NOTIF: 'Notificaciones SMS',
    TITLE: 'Zonas de Ahorro de energía',
    ZONE_ACTIVATION: 'Activación de la zona'
  }
}
