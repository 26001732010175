import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames/bind';

import { Typography } from './common/typography/typography.component';
import { EmailPreferences } from './emailpref/email-preferences.component';
import { fetchUserEmailPref, postUserEmailPref } from './emailpref/userpref.service';
import { IUserEmailPrefData } from './emailpref/interfaces';
import { updateAppLanguage, getLanguage } from './i18n/setup-translations';

import { ReactComponent as AppLogo } from './logo.svg';
import styles from './App.module.scss';

let cx = classNames.bind(styles);
const NotifShowTime = 5000;

const loadPrefs = async (
  token: string, validation_key: string
) => fetchUserEmailPref(token, validation_key);
const changePrefs = async (
  token: string, validation_key: string, prefs: IUserEmailPrefData
) => postUserEmailPref(token, validation_key, prefs)

export interface INotifData {
  message: string;
  tradref?: string;
  type: 'error' | 'warning' | 'success';
  timeoutId: any;
}

let onLoadInitialised = false;

function App() {
  const params = new URLSearchParams(window.location.search);
  const token = params.get("token");
  const validation_key = params.get("validation_key") || "";

  const { t } = useTranslation([
    'account', 'forms', 'commonActions'
  ]);

  const [emailPreferences, setEmailPreferences] = useState<IUserEmailPrefData | undefined> ();
  const [notif, setNotif] = useState<INotifData | undefined>();
  const [loading, setLoading] = useState<boolean>(false);

  const successNotif = (tradref?: string, message: string = "Success") => {
    if (notif?.timeoutId) clearTimeout(notif.timeoutId);
    setNotif({
      message: message,
      tradref: tradref,
      type: 'success',
      timeoutId: setTimeout(() => (setNotif(undefined)), NotifShowTime),
    });
  }
  
  const errorNotif = (tradref?: string, message: string = "Error") => {
    if (notif?.timeoutId) clearTimeout(notif.timeoutId);
    setNotif({
      message: message,
      tradref: tradref,
      type: 'error',
      timeoutId: setTimeout(() => (setNotif(undefined)), NotifShowTime),
    });
  }
  
  const updatePrefs = (prefs: IUserEmailPrefData) => {
    if (prefs.current_lang !== getLanguage())
      updateAppLanguage(prefs.current_lang);
    setEmailPreferences(prefs);
  }

  useEffect(() => {
    if (!onLoadInitialised){
      onLoadInitialised = true;
      if (token && !loading) {
        setLoading(true);
        loadPrefs(token, validation_key).then(
          (prefs: IUserEmailPrefData) => {
            updatePrefs(prefs);
            setLoading(false);
          },
          (err) => {
            console.log(err);
            errorNotif('account:USER_PROFILE.MESSAGES.UPDATE_ERROR_UNKNOWN');
            setLoading(false);
          }
        );
      } else if (!token) {
        errorNotif('account:USER_PROFILE.MESSAGES.UPDATE_ERROR_UNKNOWN');
        setLoading(false);
      }
    }
  }, [loading, setLoading, setEmailPreferences, setNotif]);

  const handleSubmit = (prefs: IUserEmailPrefData) => {
    setLoading(true);
    if (token)
      changePrefs(token, validation_key, prefs).then(() => {
        updatePrefs(prefs);
        successNotif('account:USER_PREFERENCES.MESSAGES.UPDATE_SUCCESS');
        setLoading(false);
      }, (err) => {
        errorNotif('account:USER_PROFILE.MESSAGES.UPDATE_ERROR_UNKNOWN');
        setLoading(false);
      });
    else updatePrefs(prefs);
  };

  const maybeRenderNotification = () => {
    if (notif) {
      const cname = cx({
        notif: true,
        notifSuccessMessage: notif.type === 'success',
        notifWrarningMessage: notif.type === 'warning',
        notifErrorMessage: notif.type === 'error',
      })
      return (
        <Typography className={cname}>
          {notif.tradref ? t(notif.tradref) : notif.message}
        </Typography>
      );
    }
  };


  return (
    <div className={styles.App}>
      <header className={styles.AppHeader}>
        <AppLogo className={styles.AppLogo} />
      </header>
      {maybeRenderNotification()}
      <div className={styles.userChoice}>
        <div className={styles.emailPreferences}>
          <EmailPreferences
           distantValues={emailPreferences}
           loading={loading}
           onSubmit={handleSubmit}/>
        </div>
      </div>
    </div>
  );
}

export default App;
