import { emailPrefApiService } from './emailpref.service';
import { IUserEmailPrefData } from './interfaces';

function b64_encode(str: string): string {
    const buffer = window.btoa(str);
    return buffer
        .replace(/\+/g, '-') // Convert '+' to '-'
        .replace(/\//g, '_'); // Convert '/' to '_'
        //.replace(/=+$/, ''); // Remove ending '='
}

export const fetchUserEmailPref = (
    token: string,
    validation_key: string,
    user_email?: string,
): Promise<IUserEmailPrefData> => {
    let params: URLSearchParams = new URLSearchParams(
        {validation_key: validation_key}
    );
    if (user_email)
        params.append("user_account", b64_encode(user_email));
    const url = '/prefs/' + token + '?' + params;
    return emailPrefApiService.fetch(url, {}).then((resp) => {
        if (resp.status !== 200) {
            console.error('emailpref request failed');
            throw Error('Request failed');
            // TODO: Handle it
        } else {
            return resp.json();
        }
    });
};

export const postUserEmailPref = (
    token: string,
    validation_key: string,
    body: IUserEmailPrefData,
    user_email?: string,
): Promise<IUserEmailPrefData> => {
    let params: URLSearchParams = new URLSearchParams(
        { validation_key: validation_key }
    );
    if (user_email)
        params.append("user_account", b64_encode(user_email));
    const url = '/prefs/' + token + '?' + params;
    return emailPrefApiService
        .fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
        })
        .then((resp) => {
            return resp.json();
        });
};
