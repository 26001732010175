import cx from 'classnames';
import React, { ButtonHTMLAttributes, ReactNode } from 'react';

import styles from './button.module.scss';

export interface IButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
    primary?: boolean;
    secondary?: boolean;
    block?: boolean;
    iconLeft?: ReactNode;
    iconRight?: ReactNode;
    small?: boolean;
    superSmall?: boolean;
    round?: boolean;
    large?: boolean;
    noWrap?: boolean;
    outlined?: boolean;
    curvy?: boolean;
}

export const Button = ({
    children,
    primary = true,
    block,
    secondary,
    className,
    iconLeft,
    iconRight,
    small,
    superSmall,
    type = 'button',
    disabled,
    round,
    large,
    noWrap,
    outlined,
    curvy,
    ...props
}: IButtonProps) => (
    <button
        disabled={disabled}
        type={type}
        className={cx(
            styles.button,
            {
                [styles.primary]: primary && !secondary,
                [styles.secondary]: secondary,
                [styles.block]: block,
                [styles.disabled]: disabled,
                [styles.small]: small,
                [styles.round]: round,
                [styles.large]: large,
                [styles.superSmall]: superSmall,
                [styles.withLeftIcon]: iconLeft,
                [styles.withRightIcon]: iconRight,
                [styles.noWrap]: noWrap,
                [styles.outlined]: outlined,
                [styles.curvy]: curvy,
            },
            className,
        )}
        {...props}
    >
        {iconLeft}
        {children}
        {iconRight}
    </button>
);
