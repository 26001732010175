export const IT = {
  AactivityOnboarding: {
    SUMMARY: {
      ACTIVITY_TEXT: 'Frequenza delle uscite'
    }
  },
  HipayPayments: {
    CARDNUMBER: 'Numero della carta',
    EXPIRYDATE: 'Data di scadenza',
    FULLNAME: 'Nome completo',
    GOCARDLESS_LINK: 'Pagare tramite bonifico bancario'
  },
  Slider: {
    GPS_INTERVAL: 'GPS Intervallo'
  },
  TypeSwitch: {
    ANIMAL_SUBTITLE: 'Seleziona l’animale per il quale hai acquistato questo localizzatore:',
    CAT_TYPE: 'Localizzatore per gatti',
    DOG_TYPE: 'Localizzatore per cani',
    FAMILY_SUBTITLE: 'Per chi hai comprato questo localizatore?',
    KID_TYPE: 'Un bambino',
    SILVER_TYPE: 'Un cittadino anziano',
    TITLE: 'Che tipo di localizzatore è?'
  },
  account: {
    LANGUAGE: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Si è verificato un problema',
        UPDATE_SUCCESS: 'Lingua aggiornata correttamente'
      }
    },
    NAV: {
      LANGUAGE: 'Lingua',
      PASSWORD: 'Password',
      PAYMENTS: 'Pagamento e fatturazione',
      USER_PREFERENCES: 'Preferenze',
      USER_PROFILE: 'Profilo utente'
    },
    PASSWORD: {
      HEADLINE: 'Modifica della password',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Si è verificato un problema',
        UPDATE_ERROR_WRONG_PASSWORD: 'La vecchia password inserita non è corretta',
        UPDATE_SUCCESS: 'Password aggiornata correttamente'
      }
    },
    PAYMENTS: {
      HISTORY_HEADLINE: 'Cronologia dei pagamenti',
      MAIN_HEADLINE: 'Fatturazione'
    },
    USER_PREFERENCES: {
      EMAIL_PREFERENCES_HEADLINE: 'Preferenze e-mail',
      EMAIL_PREFERENCES_USER_INFO: 'Le preferenze saranno applicate all\'indirizzo e-mail {{user_email}}',
      MAIN_HEADLINE: 'Preferenze',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Si è verificato un problema durante il salvataggio delle preferenze',
        UPDATE_SUCCESS: 'Le preferenze sono state aggiornate correttamente'
      },
      METRIC_PREFERENCES_DESCRIPTION: 'Scegliere l\'unità di misura della distanza utilizzata per il localizzatore.',
      METRIC_PREFERENCES_HEADLINE: 'Preferenza per il sistema metrico:'
    },
    USER_PROFILE: {
      BASIC_INFO_HEADLINE: 'Dati di base',
      BILLING_HEADLINE: 'Indirizzo di fatturazione',
      MAIN_HEADLINE: 'Profilo utente',
      MARKETING_AGREEMENT_TEXT: 'Accetto di ricevere offerte e promozioni dai partner di Weenect',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Si è verificato un problema',
        UPDATE_SUCCESS: 'Dati aggiornati correttamente'
      },
      METRIC_KM: 'Chilometri',
      METRIC_MILES: 'Miglia',
      PREFERRED_METRIC: 'Sistema di misura da usare :'
    }
  },
  activity: {
    AVERAGE_SPEED: 'Velocità media',
    CONTROLS: {
      PAUSE_PLAYING_TITLE: 'Interrompi la visualizzazione della cronologia',
      RESUME_PLAYING_TITLE: 'Fai ripartire la visualizzazione della cronologia',
      START_PLAYING: 'Play',
      START_PLAYING_TITLE: 'Lancia la visualizzazione della cronologia',
      STOP_PLAYING: 'Stop',
      STOP_PLAYING_TITLE: 'Ferma la visualizzazione della cronologia',
      TOGGLE_HEATMAP: 'Mappa termica',
      TOGGLE_HEATMAP_TITLE: 'Visualizza la mappa termica',
      TOGGLE_PATH: 'Tracciato delle posizioni',
      TOGGLE_PATH_TITLE: 'Visualizza il tracciato delle posizioni'
    },
    DETAIL_TITLE: 'Dettaglio dell\'attività',
    EMPTY_LIST: 'Nessun avviso da visualizzare. Modifica il periodo selezionato o verifica che il localizzatore sia acceso.',
    EVENTS: {
      ALERT: 'Pulsante laterale azionato',
      BATTERY: 'Batteria bassa - del {{battery}}% rimanente',
      CALL_END: 'Chiamata SOS terminata',
      CALL_REQ: 'Chiamata SOS richiesta',
      ENTERS_GEOFENCE: 'Ingresso nella zona di sicurezza',
      EXIT_GEOFENCE: 'Uscita dalla zona di sicurezza',
      FLASH_REQ: 'Flash chiesto',
      FREQ_CHANGE: 'Intervallo di tempo modificato',
      RING: 'Suoneria effettuata',
      RING_REQ: 'Suoneria richiesta',
      SOS: 'Avviso SOS',
      TRACKER_OFF: 'Localizzatore spento',
      TRACKER_ON: 'Localizzatore acceso',
      VIBRATE: 'Vibrazione effettuata',
      VIBRATE_REQ: 'Vibrazione richiesta'
    },
    FEEDBACKTEXT: {
      FIRST: '',
      SECOND: ''
    },
    HISTORY_TITLE: 'Cronologia delle notifiche',
    LAST_TIME_PERIOD: 'Mostra le ultime',
    LOADING: 'Caricamento...',
    MAXIMUM_SPEED: 'Velocità massima',
    OBJECTIVE: {
      CONTENT: 'Stabilite degli obiettivi giornalieri per garantire la salute del vostro animale.',
      DAILY_REACHED: '{{name}} ha raggiunto il suo obiettivo giornaliero!',
      HELPTEXT: 'Di quanta attività ha bisogno il mio animale?',
      RECOMENDED_VALUE: 'Consigliato: 1 ora',
      SUBTEXT: 'Impostare il tempo di attività giornaliero da raggiungere:',
      TITLE: 'Gestione degli obiettivi'
    },
    ONBOARDING: {
      FIRST_SUBTEXT: 'Questo strumento vi permetterà di conoscere l\'attività quotidiana del vostro compagno. ',
      SECOND_SUBTEXT: 'Quanto tempo ha dormito? Quanti passi ha fatto oggi?',
      START: 'Inizio',
      THIRD_SUBTEXT: 'Potrete anche impostare un obiettivo in base alla razza del vostro animale, in modo da garantirgli l\'attività quotidiana di cui ha bisogno.',
      TITLE: 'Benvenuti nell\'activity tracker Weenect!'
    },
    PROGRESSBAR: {
      TITLE: 'Tempo di attività'
    },
    SELECT_DAY: 'Scegli un giorno da visualizzare',
    SELECT_TIME: 'Usa cursore per selezionare l’intervallo di tempo da visualizzare',
    SUMMARY: {
      ACTIVITY: 'Attività',
      AWAKENING: 'Risveglio',
      DATEINTERVAL: '',
      DAY_INDEX: 'g',
      DISTRIBUTIONTEXT: 'Distribuzione dell\'attività',
      HOUR_INDEX: 'h',
      LASTWEEK: '',
      MENU_TITLE: 'Rapporto di attività',
      MIN_INDEX: 'min',
      PREVIOUS_DAY: '',
      PREVIOUS_MONTH: '',
      PREVIOUS_WEEK: '',
      REPORT: 'Visualizza il rapporto \n',
      REST: 'Riposo',
      SUBTEXT: 'Con {{time}} di esercizio a disposizione di {{name}}, che ne dite di portarla a fare una passeggiata? ',
      TEXT_OBJECTIVE_COMPLETED: '{{name}} ha raggiunto il suo obiettivo giornaliero!',
      TEXT_OBJECTIVE_COMPLETED_TREAT: 'Congratulazioni, {{name}} ha raggiunto il suo obiettivo! Forse questo merita un trattamento?',
      TEXT_OBJECTIVE_NONE: '{{name}} non ha dati per questo periodo',
      TEXT_OBJECTIVE_NONE_VERIFY_TRACKER: '{{name}} non ha ancora registrato alcun movimento. Controllare che il tracker sia acceso e che la batteria sia carica!',
      TITTLE: 'I progressi di {{name}}',
      TODAY: 'Oggi,',
      YESTERDAY: 'ieri'
    },
    TIME_ELAPSED: 'Durata',
    TOTAL_DISTANCE: 'Distanza totale'
  },
  activityGeneral: {
    BEHAVIOR: 'Attività',
    STANDARD_MORPHOLOGY: 'Morfologia ',
    STERILIZED: 'Sterilizzato'
  },
  activityOnboarding: {
    COMMON: {
      STEP: 'tappa',
      TRACKER_PROFIL: 'Profilo del localizatore'
    },
    MONTH: {
      APRIL: 'Aprile',
      AUGUST: 'Agosto',
      DECEMBER: 'Dicembre',
      FEBRUARY: 'Febbraio',
      JANUARY: 'Gennaio',
      JULY: 'Luglio',
      JUNE: 'Giugno',
      MARCH: 'Marzo',
      MAY: 'Maggio',
      NOVEMBER: 'Novembre',
      OCTOBER: 'Ottobre',
      SEPTEMBER: 'Settembre'
    },
    PET_ACTIVITY: {
      FIRST_BUTTON: 'Casalingo (il minimo indispensabile)',
      FIRST_BUTTON_CAT: 'Eccezionalmente (vacanze, imprevisti...)',
      FIRST_BUTTON_DOG: 'Solo per i propri bisogni',
      FOURTH_BUTTON: 'Iperattivo (2 o 3 passeggiate al giorno)',
      FOURTH_BUTTON_CAT: 'Regolarmente, giorno e notte',
      FOURTH_BUTTON_DOG: '2 a 3 passeggiate al giorno',
      SECOND_BUTTON: 'Normale (2 o 3 passeggiate a settimana)',
      SECOND_BUTTON_CAT: 'Di tanto in tanto, su sua richiesta\n',
      SECOND_BUTTON_DOG: '2 o 3 passeggiate a settimana',
      SELECT_ACTIVITY: 'Quanto spesso esce?',
      THIRD_BUTTON: 'Energico (una passeggiata al giorno)',
      THIRD_BUTTON_CAT: 'Regolarmente, durante il giorno\n',
      THIRD_BUTTON_DOG: 'Una passeggiata al giorno',
      TITLE: 'Qual è il suo profilo sportivo?'
    },
    PET_AGE: {
      FIRST_COMMON_TEXT: 'anno/i e ',
      SECOND_COMMON_TEXT: 'mesi',
      TITLE: 'Qual è la sua data di nascita?'
    },
    PET_GENDER: {
      FEMALE: 'Una femmina',
      FIRST_TITLE: 'Il tuo animale è una femmina o un maschio?',
      MALE: 'Un maschio',
      SECOND_TITLE: 'Il tuo animale è sterilizzato?'
    },
    PET_HEALTH: {
      LAST_VACINATION: 'Quando è stata fatta l\'ultima vaccinazione?',
      LAST_VET_VISIT: 'Quando è stata l\'ultima visita dal veterinario?\n',
      TITLE: 'Punto salute'
    },
    PET_IDENTIFICATION: {
      CHIP: 'Microchip',
      NONE: 'Il mio animale non è identificato',
      SELECT_IDENTIFICATION: 'Com\'è identificato?',
      TATTOO: 'Tatuaggio'
    },
    PET_INFO: {
      GET_STARTED: 'Pronti, partenza, via!',
      NEED_TO_KNOW: 'Per noi è importante imparare a conoscere {{name}} .',
      PETS_DETAILS: 'Inserisci le informazioni del tuo animale.',
      PETS_GENDER: {
        MIXED_BREED: 'È un crociato'
      },
      PET_GENDER_COMMON: 'Per cominciare, il tuo animale domestico è: ',
      SECOND_PETS_DETAILS: 'Inserisci le informazioni del tuo amico a 4 zampe'
    },
    PET_MORPH: {
      AVERAGE: 'Normale',
      COMMON_TEXT: 'Circa',
      FIRST_TITLE: 'Qual è la sua morfologia?',
      HELP_TEXT: 'Come posso definire la morfologia del mio animale?\n',
      LEAN: 'Magro',
      OBESE: 'Obeso',
      OVERWEIGHT: 'In sovrappeso',
      SECOND_TITLE: 'Qual è il suo peso?',
      WEIGH_TEXT: 'Qual è il peso del vostro animale?\n'
    },
    PET_NAME: {
      COMMON_TEXT: 'Ci piacerebbe saper di più sul tuo animale!\u2028 Potrai modificare queste informazioni in seguito nei parametri. ',
      PLACEHOLDER: 'Nome dell\'animale',
      TITLE: 'Come si chiama?'
    },
    PET_RACE: {
      COMMON_TEXT: 'Non conosco la sua razza',
      PLACEHOLDER: 'Razza del cane',
      SELECT_BREED: 'Seleziona la razza',
      TITLE: 'Qual è la sua razza?'
    },
    PET_TYPE: {
      CAT: 'Un gatto',
      DOG: 'Un cane'
    },
    SUMMARY: {
      BIRTH_DATE: 'Data di nascita',
      BREED: 'Razza',
      CONDITION: 'Condizione',
      DAILY_EXERCISE: 'Movimento quotidiano',
      DELETE: 'Reimpostare il profilo',
      HEALTH: 'Salute',
      IDENTIFICATION: 'Identificazione',
      MODAL: {
        NO: 'No, annulla',
        SUBTEXT: 'Dovrete quindi ricominciare il processo da capo.',
        TITLE: 'Siete sicuri di voler reimpostare i dati del vostro animale domestico?',
        YES: 'Sì, sono sicuro'
      },
      MORPHOLOGY: 'Morfologia',
      NAME: 'Nome',
      RECAPTEXT: 'Ecco le informazioni fornite sul tuo compagno.',
      SEX: 'Sesso',
      SPECIES: 'Specie',
      SUBMIT: 'Convalida il profilo',
      UNSPECIFIED: 'vuoto',
      VALIDATION: 'Conferma del profilo',
      VALIDATION_TEXT: 'Conferma le informazioni prima di continuare. ',
      WEIGHT: 'Peso'
    }
  },
  activityTracking: {
    ACTIVITY: 'Punti di attività',
    DAILY_CHART: 'Grafico delle attività',
    DAYS: 'giorno',
    HOURS: 'Ore',
    INTERVAL: {
      TEXT: 'Sposta i bordi del grafico per adattare l\'intervallo di tempo che desideri analizzare.',
      TITLE: 'Selezione dell\'intervallo'
    },
    MIN: 'minuti',
    PERIOD: {
      DAY: 'Giorno',
      MONTH: 'Mese',
      TITLE: 'Selezione del periodo',
      WEEK: 'Settimana',
      YEAR: 'Anno'
    },
    TEXT: 'Questo grafico ti indica l\'evoluzione dell\'attività del tuo animale. Questa attività viene misurata su un intervallo che parte da 0 e che non ha un limite massimo. Ogni punto di attività rappresenta un\'accelerazione, ovvero uno spostamento del tuo animale.'
  },
  alertCustomer: {
    BODY0: 'Attualmente è in corso una manutenzione presso il nostro fornitore di schede SIM.',
    BODY1: 'Durante questa manutenzione, <1>il tempo di attivazione è esteso a 24 ore</1>.',
    BODY2: 'Siamo ben consapevoli del disagio che ciò rappresenta. Vi preghiamo di scusarci per questo problema indipendente dalla nostra volontà.\n\nPer ulteriori informazioni, potete contattare il nostro team all\'indirizzo mail <1>family@weenect.com</1> o telefonicamente al +33.1.87.66.00.83.',
    FOOTER: 'Il team Weenect'
  },
  changePaymentMean: {
    ADD_BANK_ACCOUNT: 'Aggiungere un conto da addebitare',
    ADD_BANK_CARD: 'Passare al pagamento con carta di credito',
    BANK_ACCOUNT_ERROR: 'Si è verificato un errore durante il caricamento dei dati bancari. Ti preghiamo di contattare il servizio clienti.',
    BANK_ACCOUNT_EXPLANATION: 'Ti informiamo che nessuno dei tuoi dati bancari viene salvato sui nostri server.',
    BANK_ACCOUNT_SUCCESS: 'Dati bancari aggiornati',
    BANK_CARD_ERRORED: 'Impossibile modificare il metodo di pagamento. Ti preghiamo di contattare il <1>servizio clienti</1>.',
    BANK_CARD_UPDATED: 'Carta bancaria aggiornata',
    CAPTATION: 'Per aggiornare i dettagli bancari, autorizzeremo un addebito sulla tua carta per un importo di {{ amount }}.',
    CAPTATION_EXPLANATION1: 'L\'importo non sarà di fatto addebitato perché la transazione viene annullata immediatamente.',
    CAPTATION_EXPLANATION2: 'Questa procedura è necessaria per conformità agli standard dei pagamenti via internet e per garantire la sicurezza dei dati bancari dei clienti. Nessuno di questi dati passa attraverso i nostri server.',
    CAPTATION_EXPLANATION3: 'IMPORTANTE: sul modulo di addebito troverai "Hareau" come beneficiario e non "Weenect". Non preoccuparti, è il nome della nostra azienda. "Weenect" è la denominazione commerciale.',
    CAPTATION_EXPLANATION4: 'Modifica il conto bancario da prelevare mantenendo lo stesso abbonamento.',
    CARD_AUTHORIZED: 'I metodi di pagamento autorizzati sono:',
    CHANGE_PAYMENT_MEAN: 'Aggiorna la mia carta bancaria',
    CURRENT_CHOICE_CB: 'Hai scelto di pagare con carta di credito.',
    CURRENT_CHOICE_IBAN: 'Hai scelto di pagare tramite IBAN',
    CURRENT_MEAN: 'Metodo di pagamento attuale',
    CURRENT_MEAN_PHRASE: 'Hai scelto di pagare con carta di credito',
    CURRENT_MEAN_PHRASE_GOCARDLESS: 'Hai scelto di pagare tramite addebito diretto ',
    DEFAULT_MEAN: 'Metodo di pagamento predefinito:',
    GO_TO_SEPA: 'Passare all\'addebito diretto',
    MODIFY: 'Modifica il metodo di pagamento',
    TITLE: 'Metodo di pagamento',
    UPDATE_BANK_ACCOUNT: 'Aggiornare il mio IBAN',
    UPDATE_BANK_CARD: 'Aggiorna la mia carta bancaria',
    USED_PAYMENT_EXPLANATION: 'Questo metodo di pagamento viene utilizzato per pagare l\'abbonamento e le opzioni.'
  },
  commonActions: {
    ACTIVATE: 'Attiva',
    BACK: 'Indietro',
    CANCEL: 'Annulla',
    CAN_BE_CANCELED: 'E\' possibile disdire in qualsiasi momento.',
    CHANGE: 'Salva',
    CONFIGURE: 'Configura',
    DEACTIVATE: '',
    GO_MAP: 'Vai alla carta',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Si è verificato un problema'
    },
    NEXT: 'Avanti',
    NO: 'No ',
    OK: 'Ok',
    REGISTER: 'Attiva',
    SAVE: 'Salva',
    SELECT_ALL: 'Selezionare tutti',
    SEND: 'Invia',
    SUBSCRIBE: 'Attivazione',
    THEN: 'poi',
    UNSELECT_ALL: 'Deselezionare tutti',
    UPDATE: 'Salva',
    VALIDATE: 'Conferma',
    YES: 'Sì '
  },
  creditCardAlert: {
    AUTO_RENEWAL_ADVICE: 'Il tuo account rimane attivo ma devi aggiornare il metodo di pagamento per fare in modo che il rinnovo dell\'abbonamento avvenga senza problemi.',
    AUTO_RENEWAL_NOTICE: '<1>Attenzione!</1> Il tuo abbonamento giunge al termine e la tua carta è scaduta.'
  },
  emailPreferences: {
    EMAIL_LISTS: {
      COMPANY_NEWS: 'Ultime notizie dell’azienda',
      NEW_FEATURES: 'Nuove funzionalità',
      OFFERS: 'Offerte promozionali',
      SERVICE_STATUS: 'Guasti dei servizi e aggiornamenti',
      SURVEYS_AND_TESTS: 'Indagini e Beta test',
      TUTORIALS: 'Tutorial'
    },
    EMAIL_LISTS_HEADER: 'Elenco e-mail',
    EMAIL_LISTS_INFO: {
      SERVICE_STATUS: 'Necessario per il corretto funzionamento del servizio.'
    },
    LANGUAGE_PREFERENCES_DESCRIPTION: 'Scegliete la lingua in cui desiderate ricevere le e-mail :',
    PREFERENCES_DECRIPTION: 'Scegliere il tipo di e-mail che si desidera ricevere. Vi invieremo solo quelle che corrispondono alle vostre scelte. '
  },
  error: {
    TITLE: 'Ops si è verificato un problema '
  },
  errors: {
    DEFAULT: 'Campo non valido',
    FIELD_REQUIRED: 'Questo campo è obbligatorio',
    GENERAL: 'Si è verificato un errore, ti preghiamo di riprovare',
    IMEI: 'Inserisci un numero IMEI valido',
    MAIL_FORMAT: 'Questo formato e-mail non è valido',
    MAX_LENGTH: 'Il campo può contenere al massimo ${max} caratteri',
    PASSWORD_FORMAT: 'La password deve almeno contenere un numero, una lettera maiuscola, e una minuscola.',
    PHONE_FORMAT: 'Il numero di telefono deve includere il prefisso internazionale',
    PROMO_CODE: 'Codice promozionale non valido'
  },
  forgotPassword: {
    FORGOT_PASSWORD_BUTTON: 'Ricevere un link password dimenticata',
    FORGOT_PASSWORD_CANCEL: 'Torna all\'accesso',
    FORGOT_PASSWORD_ERRORS: {
      UNKNOWN: 'Si è verificato un errore. Contattaci tramite l\'assistenza clienti.'
    },
    FORGOT_PASSWORD_EXPIRED: 'Il link per il reset della password è scaduto.',
    FORGOT_PASSWORD_RETRY: 'Chiedere un nuovo link password dimenticata',
    FORGOT_PASSWORD_SUCCESS: 'Se esiste un account associato a {{mail}} riceverai un email con un link per reimpostare la tua password.'
  },
  forms: {
    CITY: {
      LABEL: 'Città',
      PLACEHOLDER: 'La tua città'
    },
    CONFIRM_PASSWORD: {
      LABEL: 'Hai dimenticato la password?',
      PLACEHOLDER: 'Conferma'
    },
    COUNTRY: {
      LABEL: 'Paese'
    },
    EMAIL: {
      LABEL: 'Indirizzo e-mail',
      PLACEHOLDER: 'votre@email.com'
    },
    FIRST_NAME: {
      LABEL: 'Nome',
      PLACEHOLDER: 'Il tuo nome'
    },
    LANGUAGE: {
      LABEL: 'Lingua'
    },
    LAST_NAME: {
      LABEL: 'Cognome ',
      PLACEHOLDER: 'Il tuo cognome'
    },
    NEW_PASSWORD: {
      LABEL: 'Nuova password',
      PLACEHOLDER: 'La tua nuova password'
    },
    OLD_PASSWORD: {
      LABEL: 'Vecchia password',
      PLACEHOLDER: 'La tua vecchia password'
    },
    PASSWORD: {
      LABEL: 'Password',
      PLACEHOLDER: 'Password',
      VALIDATION: 'La password deve essere composta da minimo 8 caratteri.',
      WARNING: 'Attenzione, "qwertyuiop" e "123456789" NON sono password complesse\nProva la sicurezza della tua password qui: <1>https://howsecureismypassword.net</1>'
    },
    PHONE: {
      LABEL: 'Telefono',
      PLACEHOLDER: '+33600000000'
    },
    POSTAL_CODE: {
      LABEL: 'Codice postale',
      PLACEHOLDER: 'Il tuo codice postale',
      STREET_NUMBER: 'Via e numero'
    },
    STREET: {
      LABEL: 'Indirizzo',
      PLACEHOLDER: 'Via e numero civico'
    }
  },
  geofences: {
    CREATE: {
      HEADLINE: 'Crea nuovo zona di sicurezza'
    },
    EDIT: {
      DELETE_GEOFENCE: 'Elimina la zona',
      ENTRANCE_NOTIFICATION: 'Notifica di entrata nella zona',
      EXIT_NOTIFICATION: 'Notifica di uscita dalla zona',
      GEOCODE_LABEL: 'Utilizzare un indirizzo',
      GEOCODE_PLACEHOLDER: 'Scrivi qui l\'indirizzo',
      LOCATION: 'Posizione del centro della zona di sicurezza',
      NAME_INPUT: {
        LABEL: 'Nome della zona',
        PLACRHOLDER: 'Immetti nome della zona'
      },
      OUTDOORS: 'Area all\'aperto (parco, foresta, ecc.)',
      OUTDOORS_TOOLTIP: 'Il GPS è più preciso all\'aperto e di conseguenza le regole che determinano l\'invio di una notifica di entrata o uscita dalla zona sono diverse per una zona all\'aperto rispetto a una zona al chiuso.',
      POINT_ON_THE_MAP_TEXT: 'o clicca direttamente sulla mappa',
      RADIUS: 'Dimensioni della zona (raggio)',
      TRACKER_LAST_POSITION_BUTTON: 'Utilizza la posizione del localizzatore',
      UNKNOWN_ADDRESS: 'Indirizzo sconosciuto'
    }
  },
  header: {
    ALL_TRACKERS: 'Tutti i localizzatori',
    AVAILABLE_PLURAL: 'disponibile/i',
    NAV: {
      ACCOUNT: 'Account',
      INSURANCE: 'Assicurazione',
      LOGOUT: 'Disconnessione',
      SUPPORT: 'Assistenza',
      TRACKERS: 'Mappa'
    },
    SEARCHBAR_RESULT: 'localizzatori trovati',
    SEARCHBAR_TITLE: 'Ricerca un localizzatore',
    TRACKERS: 'localizzatore/i',
    WHITE_LABEL: {
      IN_PARTERNSHIP_WITH: 'In partnership con'
    }
  },
  imeiInfo: {
    HINT: 'Il numero IMEI è indicato sul retro del tuo localizzatore.',
    IMAGE_ALT: 'Foto del localizzatore',
    TITLE: 'Numero IMEI'
  },
  login: {
    FORGOT_PASSWORD: 'Hai dimenticato la password?',
    LOGIN_ERRORS: {
      UNKNOWN: 'Impossibile effettuare l\'accesso'
    },
    SIGN_IN_BUTTON: 'Accedi',
    SIGN_IN_HEADLINE: 'Accedi',
    SIGN_UP_BUTTON: 'Crea un account',
    SIGN_UP_HEADLINE: 'Non hai ancora un account?',
    TERMS_LINK: 'Termini e condizioni',
    TERMS_LINK_URL: 'https://www.weenect.com/common/pdf/en/cgv-weenect.pdf'
  },
  manageSubscription: {
    CANCEL: {
      DESCRIBE_YOUR_PROBLEMS: 'Potresti precisare il motivo della cancellazione dell\'abbonamento?',
      GIVE_US_DETAILS: 'Motivo della disdetta abbonamento.',
      REASONS: {
        AFTER_SALES_SERVICE: 'Esperienza negativa con il Servizio clienti',
        BATTERY: 'Autonomia batteria insufficiente',
        LOST_BROKEN: 'L’ho smarrito/rotto',
        MOBILE_APPLICATION: 'L\'applicazione non funziona',
        NOT_SATISFIED: 'Non sono soddisfatto di Weenect',
        NO_USE: 'Non mi occorre più',
        TOO_EXPENSIVE: 'L’abbonamento è troppo costoso',
        TRACKER_FORMAT: 'Formato del localizzatore da rivedere (dimensioni e peso)',
        WILL_REACTIVATE_LATER: 'Riattiverò l\'abbonamento più tardi'
      },
      SAD_TO_SEE_YOU_LEAVE: 'Siamo tristi che te ne vai...',
      WHY_DO_YOU_RESIGN: 'Per quale motivo vuoi annullare l\'abbonamento?'
    },
    CANCEL_EXPIRE: 'Il rinnovo automatico è stato annullato. L\'abbonamento scadrà il {{date}}.',
    CANCEL_QUESTION: 'Non desideri restare abbonato?',
    CANCEL_RENEWAL: 'Annulla il rinnovo automatico',
    CANCEL_RENEWAL_BIS: 'Disdire l\'abbonamento',
    CANT_RESIGN_UNDER_EXTENDED_NOTICE: 'Desideri disdire il tuo abbonamento o semplicemente disattivare il rinnovo automatico?\nChiamaci al numero +33 (0)1 87 66 98 71 dalle 9:30 alle 16:00 dal lunedì al venerdì.',
    CANT_RESIGN_UNDER_NOTICE: 'Non è possibile annullare l\'abbonamento negli ultimi 30 giorni. Si prega di contattare il team di assistenza clienti.',
    CANT_RESIGN_UNTIL: 'Non può disdire il suo abbonamento prima del {{date}}',
    CANT_RESIGN_WHILE_COMMITED: 'Sei impegnato per 3 mesi, la cessazione del suo abbonamento avrà effetto solo alla fine del periodo in corso, cioè il {{date}}',
    CURRENT_PLAN: 'Abbonamento attuale',
    HEADLINE: 'Gestisci il tuo abbonamento',
    MESSAGES: {
      UPDATE_SUCCESS: 'Abbonamento aggiornato correttamente.'
    },
    RENEW_SUBSCRIPTION: 'Rinnova l\'abbonamento',
    RESIGN_DATE: 'La cessazione del tuo abbonamento avrà effetto alla fine del periodo corrente, che è {{date}}',
    RETURN_TO_TRACKERS: 'Torna alla mappa'
  },
  map: {
    NO_GPS_MARKER_TITLE: 'Nessun segnale GPS',
    POSITION: 'Posizione'
  },
  mapControls: {
    MAP_TYPE: 'Tipo di mappa',
    REFRESH_POSITION: 'Aggiorna posizione',
    SUPER_LIVE: 'Tempo reale ultra',
    ZOOM_IN: 'Zoom +',
    ZOOM_OUT: 'Zoom -'
  },
  mapLayers: {
    CLASSIC_MAPS: 'Mappe Classiche',
    DONT_SUBSCRIBE: 'Continua con le mappe classiche',
    FOREST_PATH: 'Strade forestali e sentieri',
    GOOGLE_STREET: 'Google Maps',
    GRAY: 'Mappa in bianco e nero',
    PLAN: 'Mappa',
    PREMIUM_MAPS: 'Mappe Premium',
    PREMIUM_SUBSCRIBE: 'Attivare l\'opzione Mappe Premium',
    PREMIUM_WARNING_MSG: 'Ooops, non hai sottoscritto l\'opzione mappe Premium per il momento!\n\nQuest\'opzione ti consentirà di usufruire di mappe più complete e aggiornate regolarmente per osservare meglio gli spostamenti del localizzatore. \u2028Le versioni Satellite HD, Mappa dei rilievi, o sentieri forestali ti consentiranno di individuare il localizzatore rapidamente.\n\nAttiva questa opzione a soli {{mapOptionPrice}} / mese!',
    RELIEF: 'Rilievi',
    SATELLITE: 'Mappa satellitare',
    STREET: 'Classiche',
    UNLOCK_MAPS: 'Sblocca altre mappe cliccando su di esse!'
  },
  misc: {
    LANGUAGE: {
      DE: 'Tedesco',
      EN: 'Inglese',
      ES: 'Spagnolo',
      FR: 'Francese',
      IT: 'Italiano',
      NL: 'Olandese'
    },
    NO_DATE: 'Nessuna posizione conosciuta',
    ROTATE_DEVICE: 'Weenect è stato progettato per essere utilizzato in modalità verticale. Per approfittare al meglio dei nostri servizi, ti invitiamo a ruotare il dispositivo.',
    locale: 'it-IT'
  },
  onboarding: {
    ACTIVATING_HINT: 'Durata media di attivazione: 5 minuti',
    ACTIVATING_INDICATOR: 'Attivazione in corso',
    GREET: {
      BUTTON: 'Il localizzatore è acceso',
      COMMON_TEXT: 'Per iniziare bene, ti raccomandiamo di seguire le istruzioni del seguente tutorial. Ti aiuterà ad acquistare dimestichezza con il localizzatore.\n\nComincia con la messa in funzione del localizzatore. Segui le indicazioni qui sotto per accenderlo:',
      MAIN_TITLE: 'BENVENUTO IN FAMIGLIA!',
      RED_TEXT: 'Per riuscire ad ottenere una posizione GPS, il localizzatore deve stare all\'esterno'
    },
    MAP_INTERACTIONS: {
      COMMON_TEXT: {
        PET: 'Dall\'alto in basso questi pulsanti ti consentono di far suonare o vibrare il localizzatore. Queste funzionalità ti consentono di educare l\'animale al richiamo.',
        SILVER: 'Dall\'alto in basso questi pulsanti ti consentono di far suonare o vibrare il localizzatore. '
      },
      TITLE: 'I pulsanti d\'interazione con il localizzatore'
    },
    MAP_INTERVAL: {
      COMMON_TEXT: 'Questo pulsante consente di scegliere con quale frequenza vuoi ricevere una nuova posizione GPS per il localizzatore.  Attenzione, più l\'intervallo è breve e meno l\'autonomia del localizzatore sarà elevata. ',
      TITLE: 'L\'intervallo di tempo tra ogni posizione GPS'
    },
    MAP_OPTIONS: {
      COMMON_TEXT: 'Questi pulsanti consentono di modificare il tipo di carta che si desidera visualizzare e di ingrandire o rimpicciolire.',
      TITLE: 'I pulsanti di regolazione della carta'
    },
    MAP_UPDATE: {
      COMMON_TEXT: 'Questo pulsante ti consente di aggiornare la posizione del localizzatore (per ottenere una nuova posizione il localizzatore deve essere collegato al server e avere un segnale GPS valido).',
      TITLE: 'Il pulsante di aggiornamento della posizione'
    },
    MODAL: {
      BUTTON_HINT: 'Premi il pulsante per 3 secondi fino a quando il localizzatore inizia a vibrare.',
      HEADLINE: 'Accendi il localizzatore all\'esterno o sul davanzale di una finestra',
      IMAGE_ALT: 'Foto del localizzatore',
      MAP_HINT: 'Una volta acceso, il localizzatore sarà visualizzato sulla mappa in media dopo 5 minuti.'
    },
    REMOTE_ASSISTANCE: {
      ASSISTANCE: {
        COMMON_TEXT: 'Se nessuno reagisce in questo intervallo, l\'avviso viene trasferito a un operatore'
      },
      EXPLANATION: 'L\'operatore prende in carico la chiamata e si occupa di assistere la persona che indossa il localizzatore. A seconda della situazione, può <1> contattare i famigliari o chiamare i soccorsi</1>.',
      PRICE: 'Questa opzione ha un costo mensile di 9,99 € e può essere disattivata in qualsiasi momento.',
      SOS: {
        COMMON_TEXT: 'Il tuo famigliare preme il pulsante SOS del suo localizzatore.'
      },
      TIME: {
        COMMON_TEXT: 'Tu ricevi un avviso e hai 30 secondi per rispondere.'
      },
      TITLE: 'Scopri la nostra nuova opzione: la teleassistenza ',
      USE_CASE: 'Questa opzione consente di mettere la persona che indossa il localizzatore in contatto con un servizio di teleassistenza specializzato, disponibile 7 giorni su 7 e 24 ore su 24.'
    },
    SIDE_PAGES: {
      COMMON_TEXT: {
        PET: 'Nella parte a sinistra, puoi trovare tutte le pagine per la gestione e l\'utilizzo del localizzatore. La scheda Cronologia ti consente di consultare gli spostamenti dell\'animale e la scheda Follow-up attività di conoscere i consumi di energia. La scheda Zone ti consente di configurare le diverse zone, la scheda Parametri contiene il profilo dell\'animale e la scheda Abbonamento consente di gestire in modo semplice e rapido l\'abbonamento e le opzioni.',
        SILVER: 'Nella parte a sinistra, puoi trovare tutte le pagine per la gestione e l\'utilizzo del localizzatore. La scheda Cronologia consente di consultare gli spostamenti della persona e la scheda Zone consente di configurare dei perimetri di sicurezza.\n La scheda Parametri consente di configurare il numero da chiamare in caso di SOS e i messaggi associati a ogni pulsante laterale del localizzatore. Infine, la scheda Abbonamento consente di gestire in modo semplice e rapido l\'abbonamento e le opzioni.'
      },
      TITLE: 'Pagine aggiuntive'
    },
    SKIP: 'Salta il tutorial',
    SUPERLIVE: {
      COMMON_TEXT: 'Questo pulsante ti consente di attivare la modalità "superlive": una nuova posizione ogni 10 secondi per 5 minuti.',
      TITLE: 'Il pulsante "superlive"'
    },
    TUTORIAL_START: {
      BUTTON: 'Prosegui il tutorial',
      COMMON_TEXT: 'Congratulazioni, il tuo localizzatore è pronto per l\'uso! Continuiamo con una rapida panoramica della tua area personale My Weenect.'
    },
    WAITING_GPS_POSITION: 'Ricerca di una posizione GPS',
    WAITING_GPS_POSITION_HINT: 'Accendi il localizzatore all\'esterno'
  },
  optionCardExpired: {
    PROPOSAL: 'Aggiorna la tua carta di credito per continuare ad usufruire dei nostri servizi.',
    UPDATE_CARD: 'Aggiorna adesso',
    WARNING: '<1>Attenzione!</1> Non puoi sottoscrivere quest\'opzione perché la tua <1>carta di credito è scaduta</1>.'
  },
  payment: {
    ADD_NEW_PAYMENT_REGULARISE: 'Registra un nuovo metodo di pagamento o regolarizza la tua situazione.',
    EXPIRACY_CREDIT_CARD_TEXT: 'Aggiungi un nuovo metodo di pagamento per regolarizzare la tua situazione.',
    NO_PAYMENT_MEAN_YET: 'Nessun mezzo di pagamento al momento',
    NO_PAYMENT_YET: 'Nessun mezzo di pagamento al momento',
    UPDATE_PAYMENT_REGULARISE: 'Aggiorna o cambia per regolarizzare la tua situazione.',
    WARNING_ERROR: 'Sembra che ci sia un problema con il tuo metodo di pagamento.',
    WARNING_EXPIRACY_CREDIT_CARD: 'Attenzione, la carta di credito è scaduta.',
    WARNING_NOT_ENOUGH_FOUNDS: 'Attenzione, sembra che non ci siano fondi sufficienti sul tuo conto.'
  },
  payments: {
    CARD: {
      ACCEPTED: '(CB, VISA, Mastercard, American Express)',
      DESCRIPTION: '30 giorni di garanzia soddisfatti o rimborsati',
      NAME: 'Carta di credito'
    },
    STATUS: {
      CANCELED: 'Annullato',
      ERROR: 'Non riuscito',
      NEW: 'Elaborazione in corso',
      PAYMENT_ERROR: 'Pagamento non riuscito',
      PAYMENT_ERROR_EXPLAIN_TEXT: 'Si è verificato un errore con il tuo pagamento. Ti preghiamo di riprovare tra qualche istante o contattare',
      PAYMENT_RETRY: 'Provare di nuovo',
      PAYMENT_SAV: 'servizio clienti.',
      PAYMENT_SUCCESS: 'Pagamento andato a buon fine!',
      PAYMENT_SUCCESS_TEXT: 'Il tuo pagamento è stato registrato. Ora puoi dare inizio alla tua esperienza Weenect!',
      PAYMENT_SUCCESS_TEXT_APP: 'Il tuo pagamento è stato registrato.',
      PENDING: 'Elaborazione in corso',
      REFUNDED: 'Rimborsato',
      SUCCESS: 'Confermato'
    },
    TRANSFER: {
      DESCRIPTION: '15 giorni in più per cambiare idea: 30 giorni di garanzia soddisfatti o rimborsati',
      NAME: 'Prelievo bancario'
    }
  },
  periods: {
    ALT: {
      MONTH: '1 mese',
      YEAR: '1 anno',
      YEAR_plural: '{{count}} anni'
    },
    BASE: {
      MONTH: 'mese',
      YEAR: 'anno'
    }
  },
  registerSummaryTable: {
    BILLED_MONTH: 'Il pagamento è mensile',
    BILLED_NOW: 'Fatturazione immediata',
    BILLED_THREE_YEARS: 'Il pagamento avviene una volta ogni tre anni.',
    BILLED_TWO_YEARS: 'Fatturato ogni due anni',
    BILLED_YEAR: 'Il pagamento viene effettuato una volta all’anno',
    HINT_OPTIONS_PRICE: 'Le opzioni, pari a un importo totale di {{amount}}, saranno fatturate a parte (non con l\'abbonamento) entro due ore.',
    OPTION_UPGRADE: 'Aggiungi l\'opzione {{name}} per soli {{price}} / {{period}}',
    PAY_HINT: 'Clicca sul pulsante "Paga e attiva" e paga {{subscriptionPrice}} per l\'abbonamento.',
    SUBSCRIPTION_UPGRADE: 'Passa a un abbonamento per due anni e risparmia {{amount}} ogni anno.',
    TOTAL: 'Prezzo totale'
  },
  registerTracker: {
    BUY_LINK: 'Non hai ancora un localizzatore Weenect? <1>Puoi acquistarne uno qui</1>',
    ERRORS: {
      UNKNOWN: 'Si è verificato un problema durane l\'attivazione del localizzatore, ti preghiamo di riprovare.'
    },
    HEADLINE: 'Attiva un nuovo localizzatore',
    IMEI_INPUT: {
      LABEL: 'Numero IMEI',
      PLACEHOLDER: 'Il numero IMEI è indicato sul retro del tuo localizzatore.'
    },
    NAME_INPUT: {
      LABEL: 'Nome del localizzatore',
      PLACEHOLDER: 'Nome di chi indossa il localizzatore'
    }
  },
  remoteAssistance: {
    BASE: 'Teleassistenza',
    FEATURES: 'Se il bottone SOS viene premuto e tu non rispondi entro 30 secondi, l\'avviso SOS e la chiamata vocale saranno automaticamente trasmessi 7 giorni su 7 e 24 ore su 24 alla nostra centrale di teleassistenza. I nostri operatori sono autorizzati a contattare i soccorsi come la polizia o i servizi di emergenza medica.',
    FORM: {
      ADDRESS_INPUT: {
        LABEL: 'Indirizzo',
        PLACEHOLDER: 'Via e numero civico'
      },
      BACK: 'Annulla',
      CITY_INPUT: {
        LABEL: 'Città',
        PLACEHOLDER: ''
      },
      EXTRA_PHONE: {
        LABEL: 'Telefono alternativo (opzionale)',
        PLACEHOLDER: ''
      },
      HEADLINE: 'Iscrizione alla teleassistenza',
      NAME_INPUT: {
        LABEL: 'Nome completo',
        PLACEHOLDER: 'Nome COGNOME'
      },
      PHONE: {
        LABEL: 'Telefono',
        PLACEHOLDER: ''
      },
      POSTAL_CODE_INPUT: {
        LABEL: 'Codice postale',
        PLACEHOLDER: ''
      },
      SAVE: 'Conferma',
      TERMS: 'Ho letto e accetto i <1>Termini e condizioni</1> del servizio',
      TEXT: 'Inserisci i dati del tuo familiare (chi indossa il localizzatore). Consentiranno al nostro personale di venirgli/venirle in aiuto in caso di avviso SOS'
    },
    PENDING: {
      WAITING_FOR_CALL_TEST: 'Modulo d\'iscrizione confermato. In attesa della chiamata di prova.\nOra devi fare una chiamata di prova per finalizzare l\'attivazione dell\'opzione. Fai riferimento all\'email che ti è stata inviata per la procedura da seguire.',
      WAITING_FOR_RESIGN: 'Disattivazione dell\'opzione in corso.\nLa tua richiesta di disattivare l\'opzione è stata ricevuta correttamente. I nostri operatori si stanno occupando delle formalità amministrative. La disattivazione sarà effettiva tra poco.',
      WAITING_FOR_VALIDATION: 'Conferma modulo d\'iscrizione in corso. \nI nostri operatori stanno verificando i dati che hai indicato al momento della sottoscrizione dell\'opzione. Non appena questi dati saranno confermati potremo passare all\'ultima fase di attivazione dell\'opzione: sarà necessario effettuare una chiamata di prova per verificare che tutto funzioni correttamente.'
    },
    USER_PHONE: {
      TEXT: 'Inserisci i tuoi dati. Consentiranno al nostro personale di contattarti in caso di avviso SOS.'
    }
  },
  signup: {
    ALREADY_MEMBER_TEXT: 'Hai già un account?',
    CREATE_ACCOUNT_HEADLINE: 'Crea un account',
    LOGIN_TEXT: 'Connettarsi',
    MARKETING_AGREEMENT_TEXT: 'Accetto di ricevere offerte e promozioni dai partner di Weenect',
    RETRY: 'Provare di nuovo',
    SIGNUP_ERRORS: {
      EMAIL_ALREADY_EXISTS: 'Questo indirizzo e-mail è già associato a un account',
      POPIN_EMAIL_ALREADY_EXIST: '<1>Ops</1>, questo indirizzo e-mail sembra essere già utilizzato.',
      UNKNOWN: 'Impossibile effettuare l\'accesso, si prega di riprovare'
    },
    SIGN_IN_TEXT: 'Accedi',
    SUBMIT_BUTTON: 'Crea l\'account',
    TERMS_AGREEMENT_TEXT: 'Accetto <1>i termini e le condizioni</1> del servizio'
  },
  simInfo: {
    TITLE: 'Numero SIM'
  },
  subOptions: {
    CANCEL: {
      EXPIRATION_DATE: 'Questa opzione sarà sempre attiva fino al {{date}}',
      HEADLINE: 'Desideri annullare l\'opzione {{option}} ?'
    },
    MAP: {
      BASE: 'Pack multi mappe',
      FEATURES: 'Ottieni mappe più complete e aggiornate regolarmente per osservare meglio gli spostamenti del localizzatore. (Satellite HD, Mappe dei rilievi, Sentieri forestali)',
      TITLE: 'Mappe HD: '
    },
    PREMIUM: {
      BASE: 'Pacchetto Premium',
      BUTTON: {
        FREEMONTH: 'Provatelo gratuitamente per 1 mese!'
      },
      DEACTIVATE: 'Disattivando questa opzione si perderanno le prestazioni del premio.',
      FEATURE: {
        CHAT: {
          BASE: 'Chat online',
          TEXT: 'Chatta direttamente dalla tua applicazione con un agente Weenect, 7 giorni su 7, dalle 9:00 alle 18:00.',
          TEXT2: 'Usufruite di vantaggi esclusivi (chat online, avvisi via SMS, mappe HD) all\'interno dell\'applicazione.'
        },
        SMS: {
          TEXT: 'Essere avvisati direttamente via SMS per tutte le notifiche di vostra scelta (40 SMS al mese).'
        }
      },
      FREEMONTH: {
        BASE: 'Primo mese gratuito!',
        BASE2: 'GRATUITO'
      },
      SUBTITLE: 'Il Premium Pack include le seguenti caratteristiche:',
      SUB_LEGAL: 'L\'abbonamento sarà rinnovato automaticamente ogni mese, tramite il metodo di pagamento scelto al momento della sottoscrizione. È possibile annullare l\'iscrizione in qualsiasi momento tramite il proprio spazio personale.',
      TEXT_INFO_PRICE: 'Servizio a pagamento a {{price}}/mese dopo il primo mese.',
      TITLE_DISCOVER: 'Scoprite i vostri vantaggi esclusivi: ',
      TITLE_POPUP: 'Diventa un membro Premium!'
    },
    SMS_PACK: {
      ALT_FEATURES: 'Ricevi tutti gli avvisi via SMS',
      BASE: 'Pacchetto SMS',
      FEATURES: 'Ricevi tutti gli avvisi via SMS (20 SMS disponibili al mese). Nota che senza tale opzione riceverai avvisi mediante notifiche push (applicazione) e email.'
    },
    UNDEFINED: {
      PAYMENTS: 'Pagamento dell\'opzione'
    },
    WARRANTY: {
      ALT_FEATURES: {
        one: 'Ricevi gratuitamente un nuovo localizzatore in caso di rottura, smarrimento o furto del tuo attuale localizzatore.',
        other: 'Pagamento con carta di credito con possibilità di annullamento in qualsiasi momento',
        two: 'Il tuo localizzatore sostituito in 48H in caso di smarrimento, rottura o furto, senza condizioni.'
      },
      BASE: 'Garanzia multi rischio',
      EXPLAINER: 'La garanzia standard copre solo i difetti di fabbrica del localizzatore.',
      FEATURES: 'Ottieni un localizzatore sostitutivo gratuito in caso di danni, smarrimento o furto del tuo attuale localizzatore; non è richiesta alcuna motivazione. La garanzia standard copre esclusivamente i difetti di fabbricazione del localizzatore.'
    }
  },
  subscription: {
    COMMON_TEXT: {
      MONTH: '/ mese',
      OR: 'Cioè'
    },
    FEATURES: {
      APP_ACCESS: 'Accesso illimitato all\'applicazione e al servizio di localizzazione',
      REFOUND: '15 giorni di prova: soddisfatti o rimborsati',
      SAV: 'Servizio clienti disponibile 6/7 giorni. \nRisposta garantita entro 24 ore.',
      WARRANTY: '24 mesi di garanzia del produttore'
    },
    INFO_CGU: 'Per beneficiare degli abbonamenti, è necessario inserire informazioni bancarie valide e accettare le condizioni generali',
    INFO_CGU_BUTTON: 'le condizioni generali di utilizzo del servizio.',
    INFO_OPTION: 'Il pagamento è mensile, la cancellazione è possibile in qualsiasi momento.',
    MONEY_BACK: 'Tutti gli abbonamenti sono fatturati immediatamente ma possono essere annullati entro 15 giorni, con un rimborso completo dell\'importo pagato.',
    PAYMENT_INFO: 'Garanzia di rimborso: 15 giorni per provare il tuo tracker',
    PAYMENT_SUBHEADLINE: 'Seleziona un metodo di pagamento per pagare il tuo abbonamento e attivare il tuo tracker.',
    PAYMENT_TITLE: 'Scegli il tuo metodo di pagamento',
    PAYMENT_TITLE2: 'Registrate il vostro metodo di pagamento',
    PRICE_MONTH_SAVED: 'cioè {{price}} / mese',
    SUMMARY: 'Riassunto'
  },
  subscriptionAlert: {
    ACTIVATE_AUTO_RENEWAL: 'Riattiva il rinnovo automatico',
    AUTO_RENEWAL_ADVICE: 'Ti consigliamo di riattivare il rinnovo automatico se vuoi prolungare l\'abbonamento.',
    AUTO_RENEWAL_NOTICE: '<1>Attenzione!</1> Il tuo abbonamento per il localizzatore {{trackerName}} sta per scadere e il rinnovo automatico è disattivato.',
    CONTINUE_BUTTON: 'Continua',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Si è verificato un problema',
      UPDATE_SUCCESS: 'Dati aggiornati correttamente'
    },
    REMIND_LATER: 'Ricordamelo più tardi',
    SUPPORT_TEXT: 'Se hai domande o pensi che si tratti di un errore, contatta il nostro <1>servizio clienti.</1>'
  },
  subscriptionOptionsSelection: {
    CANCEL_WARNING_MODAL_HEADLINE: 'Vuoi davvero disattivare questa opzione?',
    CANCEL_WARNING_SMS: 'Disattivando questa opzione non riceverai le notifiche degli avvisi per SMS.',
    CANCEL_WARNING_WARRANTY: 'Disattivando questa opzione perderai la protezione supplementare della garanzia multi-rischio.',
    EXPLANATION: 'Le opzioni sono selezionate per default ma puoi deselezionarle cliccando qui sotto.',
    EXPLANATION2: 'And cancellation is possible at any time.',
    EXPLANATION3: 'Non verrà effettuato alcun pagamento.',
    HINT: 'Puoi attivare o disattivare un\'opzione in qualsiasi momento',
    MAIN_HEADLINE: 'Scegli le tue opzioni',
    SUBMIT_BUTTON: 'Conferma'
  },
  subscriptionPayment: {
    ERRORS: {
      UNKNOWN: 'Si è verificato un problema al momento del pagamento, ti preghiamo di riprovare'
    },
    GIFT_CARD: {
      EXPLANATION: 'Inserisci il codice scritto dietro la carta regalo fornita nella scatola del localizzatore.',
      I_HAVE_ONE: 'Ho una carta regalo',
      NEED_HELP: 'Bisogno di aiuto a proposito della carta regalo?',
      TITLE: 'Usa il tuo regalo',
      USE: 'Usa carta regalo'
    },
    HEADLINE: 'Riepilogo prima del pagamento',
    PROMOCODE_TEXT: 'Ho un codice coupon:',
    PROMO_CODE: 'Codice promozionale',
    PROMO_CODE_GIFT_CARD: 'Carta regalo',
    SATISFIED_OR_REFUNDED: 'Garanzia soddisfatti o rimborsati',
    SATISFIED_OR_REFUNDED_DESCRIPTION: '30 giorni per provarlo senza rischi, se non sei soddisfatto ti rimborsiamo.',
    SELECT_PAYMENT_MEAN: 'Seleziona un metodo di pagamento prima di pagare e attivare l\'abbonamento. ',
    SUBMIT_BUTTON: 'Pagare l\'abbonamento',
    SUBMIT_BUTTON2: 'Attivare il localizatore',
    SUMMARY: 'Riepilogo ordine'
  },
  subscriptionProcess: {
    GIFT_CARD_EXPLANATION: 'Inserite il vostro metodo di pagamento preferito per attivare gratuitamente il vostro tracker.',
    GIFT_CARD_EXPLANATION2: 'Queste informazioni sono necessarie per motivi legali legati all\'uso delle carte SIM.',
    RETURN_TO_APP: 'Torna sull\'applicazione',
    RETURN_TO_MAP: 'Torna alla mappa',
    RETURN_TO_TRACKERS: 'Torna alla mappa',
    STEPS: [
      'Localizzatore',
      'Abbonamento',
      'Opzioni',
      'Riepilogo'
    ]
  },
  subscriptionSelection: {
    BEST_OFFER: 'OFFERTA MIGLIORE',
    CANCELABLE_AFTER_THREE_MONTHS: 'Possibilità di annullamento in qualsiasi momento dopo i primi tre mesi',
    CANCEL_ANYTIME: 'Possibilità di annullamento in qualsiasi momento',
    COMMITMENT_TEXT: 'Può essere terminato in qualsiasi momento dopo il periodo di impegno minimo di 3 mesi.',
    FEATURES: '',
    FEATURES_HEADLINE: 'Tutti gli abbonamenti comprendono:',
    HEADLINE: 'Scegli il tuo abbonamento',
    MIN_THREE_MONTHS: 'Impegno minimo di tre mesi',
    MONEY_BACK: 'Tutti gli abbonamenti sono fatturati immediatamente, ma possono essere annullati entro 30 giorni con un rimborso totale dell\'importo pagato.',
    PAYMENT_MEANS: 'Pagamento con addebito diretto o con carta di credito',
    REFUNDED_INFO: 'Ti rimborsiamo l\'abbonamento senza condizioni.',
    REFUNDED_TEXT: 'Soddisfatti o rimborsati per 30 giorni.',
    SIM_CARD_WARNING: 'Una carta sim è integrata direttamente nel tuo localizzatore. Ecco perché è necessario sottoscrivere un abbonamento (per coprire le spese di servizio).',
    SUBMIT_BUTTON: 'Conferma',
    XMAS_OFFER: {
      BODY: 'Tuttavia siamo obbligati a farle scegliere subito il suo futuro abbonamento (per motivi di sicurezza,  il prodotto è dotato di una carta SIM). Non lei verrà addebitato alcun costo prima della fine dei 6 mesi liberi e potrà disdire l\'abbonamento in qualsiasi momento fino a un mese prima della sua data di inizio effettiva.',
      TITLE: 'Il suo tracker è pre-caricato con 6 mesi di abbonamento offerto dal parente.'
    },
    features: [
      '30 giorni di garanzia soddisfatti o rimborsati',
      'Localizzazione GPS illimitata',
      'Notifiche push (applicazione) e per e-mail',
      '10 minuti di chiamate voce al mese'
    ]
  },
  subscriptions: {
    BASE: 'Abbonamento',
    CANCEL_MODAL: {
      HEADLINE: 'Desideri annullare il rinnovo automatico del tuo abbonamento?',
      HINT: 'Il tuo abbonamento sarà sempre attivo fino al {{date}}, ma non sarà rinnovato automaticamente alla scadenza. Dopo questa data non sarai più in grado di rintracciare il tuo localizzatore'
    },
    CHANGE_PLAN: {
      ADD_OPTIONS: 'Aggiungi un\'opzione',
      EXPLANATION: 'Il cambio di abbonamento sarà effettivo alla fine del periodo in corso.',
      HEADLINE: 'Cambiare abbonamento?'
    },
    DETAILS: {
      ACTIVATE_BUTTON: {
        LABEL: 'Attiva l\'abbonamento'
      },
      ACTIVE_CASE: {
        MANAGE_BUTTON: 'Gestisci il tuo abbonamento',
        RENEWAL_INFO: 'Il tuo abbonamento sarà automaticamente rinnovato il {{date}} al prezzo di {{price}}'
      },
      CANCELED: {
        EXPIRED_MAIN: 'Il tuo abbonamento è scaduto. Riattiva l\'abbonamento per poter rintracciare il localizzatore.',
        EXPIRED_SEC: 'Scaduto',
        UNCANCEL_BUTTON_LABEL: 'Riattivare il rinnovo automatico dell\'abbonamento',
        WILL_EXPIRE_DAYS: 'Scade tra {{count}} giorno',
        WILL_EXPIRE_DAYS_plural: 'Scade tra {{count}} giorni',
        WILL_EXPIRE_MAIN: 'Il rinnovo automatico del tuo abbonamento è stato annullato. Scadrà in data {{date}}.'
      },
      CANCEL_RESILLIATION: 'Annulla la richiesta di cancellazione',
      NEW_CASE: {
        INFO_TEXT: 'Si è verificato un problema con il pagamento. Ti preghiamo di riprovare o di cambiare metodo di pagamento.',
        PAY_BUTTON: 'Paga e attiva'
      },
      NO_SUB: {
        INFO_TEXT: 'Attiva l\'abbonamento per iniziare a usare il tuo localizzatore.'
      },
      RESILLIATION_ASKED: 'Cancellazione richiesta',
      SUPPORT_TEXT: 'Hai dei problemi con l\'attivazione del tuo localizzatore? Contatta l\'<1>assistenza</1>'
    },
    OFFERS: {
      SAVING_PER_YEAR: 'risparmia {{amount}} / anno'
    },
    OFFERS_TEXT: {
      MOST_POPULAR: 'La più popolare',
      ONE_MONTH: {
        INFO: 'Vincolo minimo di 3 mesi',
        TITLE: 'Formula 1 mese'
      },
      ONE_YEAR: {
        INFO: '15 giorni di prova in più: 30 giorni in totale',
        TITLE: 'Formula 1 anno'
      },
      THREE_YEAR: {
        INFO: '1 anno aggiuntivo di garanzia del produttore: 3 anni in totale',
        INFO2: '3-year manufacturer\'s warranty (instead of two).',
        TITLE: 'Formula 3 anni'
      }
    },
    OVERVIEW: {
      OPTIONS: 'Dettagli delle opzioni',
      REG_DETAILS: 'Dettagli del localizzatore',
      REPLACE_TRACKER_BUTTON: {
        LABEL: 'Sostituzione del localizzatore'
      },
      SUB_DETAILS: 'Dettagli dell\'abbonamento'
    },
    PLAN_NAMES: {
      FALLBACK: 'Abbonamento',
      ONE_MONTH: 'Abbonamento mensile',
      ONE_YEAR: 'Abbonamento prepagato 1 anno',
      THREE_YEARS: 'Abbonamento prepagato 3 anni',
      TWO_YEARS: 'Abbonamento per 2 anni'
    },
    STATUS: {
      ACTIVE: 'Sottoscritto',
      CANCELED: 'Annullato',
      INACTIVE: 'Non sottoscritto',
      NEW: 'Attivo',
      PENDING: 'In attesa'
    },
    TRACKER_DETAILS: {
      REGISTRATION_DATE: 'Data di attivazione',
      WARRANTY: 'Garanzia standard'
    }
  },
  superLive: {
    ACTIVE_TEXT: 'La modalità Super live è attiva',
    HEADLINE: 'Modalità super live',
    MESSAGES: {
      SUCCESS_ACTIVATED: 'Modalità Super live attivata: il localizzatore invierà una posizione ogni {{frequency}} secondi per 5 minuti'
    },
    TIME: '1 posizione ogni {{frequency}} secondi'
  },
  support: {
    FORM: {
      MESSAGE: {
        LABEL: 'Il tuo messaggio*',
        PLACEHOLDER: ''
      },
      PROBLEM: {
        AVAILABLE_OPTIONS: 'Opzioni disponibili',
        LABEL: 'Tipo di problema riscontrato*'
      },
      SUBJECT: {
        LABEL: 'Oggetto della richiesta*',
        PLACEHOLDER: ''
      },
      TRACKER: {
        LABEL: 'Localizzatore a cui si riferisce la richiesta',
        PLACEHOLDER: 'Seleziona il localizzatore'
      }
    },
    HEADLINE: 'Assistenza',
    HELP_CENTER: 'Centro assistenza',
    HELP_TEXT: 'Serve aiuto? Puoi trovare la risposta alla tua domanda nel nostro centro d\'aiuto. Puoi anche contattarci utilizzando il modulo che trovi qui di seguito; il nostro team ti risponderà entro 24 ore lavorative.',
    MAIN_TEXT: 'Il nostro centro di assistenza contiene tutti gli articoli per rispondere alle tue domande.',
    MESSAGES: {
      SUBMIT_ERROR: 'Si è verificato un errore, ti preghiamo di riprovare',
      SUBMIT_SUCCESS: 'Messaggio inviato correttamente'
    },
    PROBLEM_TYPES: {
      CHARGE: 'Il mio localizzatore non si ricarica normalmente',
      DOWNLOAD_APP: 'Non riesco a scaricare l\'applicazione',
      GPS_INVALID: 'Il mio segnale GPS risulta non valido',
      GPS_PRECISION: 'La posizione GPS del mio localizzatore è errata',
      LEDS_FIXED: 'I due led del mio localizzatore rimangono fissi',
      NO_GEOFENCE_ALERTS: 'Ricevo false notifiche di entrata e di uscita dalla zona',
      NO_NOTIFICATIONS: 'Non ricevo le notifiche',
      OTHER: 'Altro',
      SIGN_IN: 'Non riesco a connettermi alla mia area personale',
      SUBSCRIPTION: 'Ho un problema con il mio abbonamento',
      TRACKERS_PARAMS: 'Non riesco a impostare il mio localizzatore',
      TRACKER_NOT_CONNECTED: 'Il mio localizzatore risulta non connesso'
    },
    SUBMITTED_TICKET: 'Grazie, abbiamo ricevuto la tua richiesta.\nTi abbiamo inviato un\'e-mail di conferma (ricordati di verificare la posta indesiderata).',
    SUB_TEXT: 'Se queste spiegazioni non sono sufficienti, puoi anche contattare un operatore del nostro servizio clienti, che ti risponderà entro 24 ore lavorative.',
    TITLE: 'Hai bisogno di aiuto?',
    URGENT_NUMBER: 'Se la tua richiesta è urgente puoi contattare il nostro servizio clienti al numero \n+33 187 660 083 dalle 10:00 alle 12:00 e dalle 14:00 alle 17:00 dal lunedì al venerdì.'
  },
  trackerLeftPanel: {
    CALL: 'Chiama',
    CTR_ONLY_SECONDS: 'Ti restano {{SECONDS}} secondo/i di chiamata telefonica per il mese in corso.',
    CTR_WITH_MINUTES: 'Ti restano {{MINUTES}} minuto/i e {{SECONDS}} secondo/i di chiamata telefonica per il mese in corso.',
    FLASH: 'Flash',
    GOOD_GPS_ACCURACY: 'Buona precisione GPS',
    LAST_POSITION: 'Ultima posizione conosciuta:',
    MEDIUM_GPS_ACCURACY: 'Precisione GPS media',
    OFFLINE: 'Localizzatore non connesso',
    POOR_GPS_ACCURACY: 'Precisione GPS scarsa',
    RING: 'Suono',
    SMS_AVAILABLE: 'SMS disponibili : {{SMS_count}}',
    VIBRATE: 'Vibrazione'
  },
  trackerSettings: {
    BUTTONS: {
      LABELS: {
        BUTTON: 'Pulsante'
      },
      PLACEHOLDER_BUTTON: 'Inserisci il messaggio da inviare con il pulsante {{button_number}}',
      TEXT: 'Scrivi un testo da assegnare a ogni pulsante del tuo localizzatore. Riceverai un messaggio di notifica con questo messaggio quando il bottone corrispondente viene premuto.'
    },
    CONTACTS: {
      ADD_EMAIL_BUTTON: 'Aggiungi un indirizzo e-mail',
      ADD_PHONE_BUTTON: 'Aggiungi un numero di cellulare',
      EMAILS_LABEL: 'Indirizzo e-mail per le notifiche tramite posta elettronica',
      MAIL: {
        PLACEHOLDER: 'votre@email.com'
      },
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Si è verificato un problema, ti preghiamo di riprovare.',
        UPDATE_SUCCESS: 'Contatti aggiornati correttamente'
      },
      NUMBERS_LABEL: 'Numero di telefono per le notifiche tramite SMS',
      PHONE: {
        PLACEHOLDER: '+33600000000'
      }
    },
    DISPLAY: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Si è verificato un problema',
        UPDATE_SUCCESS: 'Impostazioni aggiornate correttamente'
      },
      MODAL: {
        HEADLINE: 'Seleziona l\'icona',
        TEXT: 'Puoi scegliere l\'icona che preferisci nell\'elenco riportato di seguito. Rappresenterà il tuo localizzatore sulla mappa.'
      },
      TRACKER_ICON: {
        EDIT_TEXT: 'Modifica l\'icona',
        LABEL: 'Icona del localizzatore'
      },
      TRACKER_NAME: {
        LABEL: 'Nome del localizzatore',
        PLACEHOLDER: 'Nome che sarà visualizzato sulla mappa'
      },
      TRACKER_TYPE: {
        CHANGE: 'Cambiare il tipo',
        MODIFIED_SUCCESS: 'Tipo di localizzatore modificato con successo'
      }
    },
    GPS_FREQUENCY: {
      BATTERY_USAGE: 'Autonomia media della batteria:',
      BATTERY_USAGE_LEVELS: {
        HIGH: 'Elevata',
        LOW: 'Breve',
        MEDIUM: 'Media'
      },
      GEOFENCE_NOTIFICATIONS: 'Tempo medio di reazione per gli avvisi recinto virtuale:',
      HINT: 'Una frequenza più ravvicinata riduce l\'autonomia della batteria, ma migliora il tempo di reazione per ingresso e uscita dai zona di sicurezza. Al contrario, una frequenza più distanziata migliora l\'autonomia della batteria, ma riduce il tempo di reazione per ingresso e uscita dai recinti virtuali.',
      TEXT: 'Scegli l\'intervallo di tempo tra ogni posizione GPS'
    },
    NOTIFICATION_SETTINGS: {
      APP: 'Applicazione',
      BATTERY: {
        HINT: 'Invia una notifica a tutti i contatti quando la batteria del localizzatore raggiunge un livello di carica bassa.',
        TITLE: 'Batteria'
      },
      BUTTONS: {
        HINT: 'Invia una notifica a tutti i contatti quando viene premuto uno dei pulsanti laterali. Il messaggio configurato per questo pulsante sarà incluso nella notifica',
        TITLE: 'Pulsanti laterali'
      },
      GEOFENCES: {
        HINT: 'Invia una notifica a tutti i contatti se il localizzatore entra o esce da una zona definita nelle impostazioni.',
        TITLE: 'Zone di sicurezza'
      },
      MAIL: 'E-mail',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Si è verificato un problema, ti preghiamo di riprovare',
        UPDATE_SUCCESS: 'Le impostazioni di notifica sono state aggiornate correttamente.'
      },
      SMS: 'SMS',
      SOS: {
        HINT: 'Invia una notifica a tutti i contatti premendo il pulsante SOS.',
        TITLE: 'Chiamata SOS'
      },
      WARNING: {
        SMS: {
          LINK: 'Clicca qui per attivare.',
          TEXT: 'Attenzione, se desideri ricevere gli avvisi tramite SMS devi attivare l\'opzione corrispondente.'
        }
      }
    },
    SECTION_TITLES: {
      BUTTONS: 'Pulsanti',
      CONTACTS: 'Contatti per le notifiche',
      DISPLAY: 'Generale',
      GPS_FREQUENCY: 'Intervallo di tempo tra posizioni GPS',
      NOTIFICATION_SETTINGS: 'Tipi di notifica per ogni avviso',
      SOS: 'Numero SOS',
      TRAINING: 'Funzione telefono'
    },
    TRAINING_SOS: {
      DIRECT_CALL: 'Chiamare questo numero direttamente se si preme il pulsante SOS',
      INPUT_PLACEHOLDER: '+33600000000',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Si è verificato un problema, ti preghiamo di riprovare',
        UPDATE_SUCCESS: 'Numero cellulare aggiornato correttamente'
      },
      SOS_TEXT: 'Numero di cellulare che riceverà le chiamate in caso di avviso SOS',
      TRAINING_TEXT: 'Immetti il numero di cellulare predefinito che il localizzatore chiamerà in caso di utilizzo della funzione telefono con l\'applicazione. Questo numero può essere modificato a ogni richiesta di chiamata tramite l\'applicazione.'
    }
  },
  trackers: {
    ADD_TRACKER_BUTTON: {
      LABEL: 'Aggiungi localizzatore'
    },
    EXPIRATION: {
      EXPIRED: 'L\'abbonamento di {{tracker}} è scaduto',
      WILL_EXPIRE: 'L\'abbonamento di {{tracker}} scadrà tra {{days}} giorni'
    },
    LINKS: {
      ACTIVITY: 'Monitoraggio dell\'attività',
      GEOFENCES: 'Zone di sicurezza',
      HISTORY: 'Cronologia',
      SETTINGS: 'Impostazioni',
      SUBSCRIPTION: 'Abbonamento'
    }
  },
  trackersPositionRefresh: {
    MESSAGES: {
      NOT_REFRESHED: 'Il localizzatore non è riuscito ad ottenere nuove posizioni GPS',
      REFRESHING: 'Richiesta di aggiornamento in corso, attendere prego...',
      SUCCESS: 'Posizione GPS dei localizzatori aggiornata correttamente'
    }
  },
  transferPage: {
    ADD_TRACKER: 'Voglio aggiungere un nuovo localizzatore al mio account',
    BACK_TO_TRACKERS: 'Torna alla mappa',
    FIRST_TITLE: 'Che cosa vuole fare esattamente?',
    FROM_HEADLINE: 'Localizzatore da sostituire:',
    HEADLINE: 'Sostituzione di un localizzatore',
    IMEI_LABEL: 'Numero IMEI',
    INFO: 'L\'abbonamento attivo, la cronologia delle posizioni GPS e le impostazioni saranno trasferiti sul nuovo localizzatore dopo la sostituzione.',
    MESSAGES: {
      ERROR_UNKNOWN: 'Si è verificato un problema, ti preghiamo di riprovare',
      SUCCESS: 'Nuovo localizzatore registrato correttamente',
      WRONG_TYPE: 'Il localizzatore con questo numero IMEI non corrisponde al tipo del tuo vecchio localizzatore. Contatta il nostro servizio clienti.'
    },
    NAME_LABEL: 'Nome del localizzatore',
    PURCHASE: 'Non hai ancora un localizzatore Weenect? <1>Puoi acquistarne uno qui</1>',
    REPLACE_BUTTON: {
      LABEL: 'Sostituisci'
    },
    REPLACE_TRACKER: 'Voglio sostituire un localizzatore esistente',
    TO_HEADLINE: 'Nuovo localizzatore:'
  },
  webappDisconnection: {
    BUG: 'Sembra che il tuo localizzatore non riesca a connettersi alla rete.',
    MANUAL: 'Hai spento Il tuo localizzatore. Premi il pulsante ON per riaccenderlo.',
    NO_BATTERY: 'Il tuo localizzatore si è spento perché la batteria era scarica.',
    NO_SUB: 'Non disponi di un abbonamento per questo localizzatore. Ti invitiamo a procurartene uno visitando il sito'
  },
  wifiZones: {
    AIRPLANE_TEXT: 'Avviso: in modalità aereo, finché il localizzatore si trova nella Zone di Risparmio energetico, non puoi più far squillare, vibrare o aggiornare manualmente la posizione.',
    DEEP_SLEEP_TITLE: 'Modalità aereo',
    DISPLAY_BUTTON: 'Mostrare il recinto sulla mappa',
    DISPLAY_TITLE: 'Notifiche e visualizzazione',
    IN_AIRPLANE: '- in modalità aereo',
    IN_OUT_TEXT: 'Entrate e uscite dal recinto:',
    LAST_POS_IN_WIFI: 'Nel Zone di Risparmio energetico dal {{date}}',
    MAIL_NOTIF: 'Notifications Mail',
    NOTIF_TEXT: 'Ricevi una notifica per ogni entrata o uscita dal recinto virtuale',
    POPIN: {
      AIRPLANE: {
        BUTTON_VALIDATE: 'Esci dalla modalità aereo',
        CONTENT: 'Non è più possibile comunicare con il tracker quando si trova nella Zone di Risparmio energetico. Se si desidera recuperare la piena funzionalità,<strong> disattivare la modalità aereo e portare il tracker fuori dalla zona. </strong>',
        TITLE: 'Il tuo localizzatore è in modalità aereo nella Zone di Risparmio energetico {{wifiName}}.'
      },
      BUTTON_CLOSE: 'Fallo più tardi',
      BUTTON_VALIDATE: 'Disattiva la zona',
      CONTENT: 'In quest\'area, <strong>la rilevazione automatica delle posizioni GPS del tuo localizzatore è disattivata</strong>, tuttavia puoi farlo suonare per trovarlo.<strong> Puoi anche disabilitare la Zone di Risparmio energetico</strong>.',
      TITLE: 'Il tuo localizzatore si trova nella Zone di Risparmio energetico {{wifiName}}.'
    },
    PUSH_NOTIF: 'Notifications push',
    SMS_NOTIF: 'Notifications SMS',
    TITLE: 'Zone di Risparmio energetico',
    ZONE_ACTIVATION: 'Attivazione dell\'area'
  }
}
