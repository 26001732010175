export const NL = {
  AactivityOnboarding: {
    SUMMARY: {
      ACTIVITY_TEXT: 'Frequentie van uitstapjes'
    }
  },
  HipayPayments: {
    CARDNUMBER: 'Kaartnummer',
    EXPIRYDATE: 'Vervaldatum',
    FULLNAME: 'Niet compleet',
    GOCARDLESS_LINK: 'Betaal per bankoverschrijving'
  },
  Slider: {
    GPS_INTERVAL: 'Tijdsinterval GPS'
  },
  TypeSwitch: {
    ANIMAL_SUBTITLE: 'Kies het dier waarvoor u deze tracker heeft gekocht:',
    CAT_TYPE: 'Kattentracker',
    DOG_TYPE: 'Hondentracker',
    FAMILY_SUBTITLE: 'Voor wie heb je deze tracker gekocht?Voor wie heb je deze tracker gekocht?',
    KID_TYPE: 'Een kind',
    SILVER_TYPE: 'Een oudere senior',
    TITLE: 'Wat voor type tracker is uw tracker?'
  },
  account: {
    LANGUAGE: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden',
        UPDATE_SUCCESS: 'Taal met succes gewijzigd'
      }
    },
    NAV: {
      LANGUAGE: 'Taal',
      PASSWORD: 'Wachtwoord',
      PAYMENTS: 'Betaling en facturering',
      USER_PREFERENCES: 'Voorkeuren',
      USER_PROFILE: 'Gebruikersprofiel'
    },
    PASSWORD: {
      HEADLINE: 'Wijzigen wachtwoord',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden',
        UPDATE_ERROR_WRONG_PASSWORD: 'Je hebt een onjuist oud wachtwoord ingevoerd',
        UPDATE_SUCCESS: 'Het wachtwoord is met succes gewijzigd'
      }
    },
    PAYMENTS: {
      HISTORY_HEADLINE: 'Betaalgeschiedenis',
      MAIN_HEADLINE: 'Facturering'
    },
    USER_PREFERENCES: {
      EMAIL_PREFERENCES_HEADLINE: 'E-mail voorkeuren',
      EMAIL_PREFERENCES_USER_INFO: 'De voorkeuren worden toegepast op het e-mailadres {{user_email}}',
      MAIN_HEADLINE: 'Voorkeuren',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden bij het opslaan van voorkeuren',
        UPDATE_SUCCESS: 'Voorkeuren succesvol bijgewerkt'
      },
      METRIC_PREFERENCES_DESCRIPTION: 'Kies de afstandseenheid die wordt gebruikt voor uw tracker.',
      METRIC_PREFERENCES_HEADLINE: 'Metrisch systeem voorkeur :'
    },
    USER_PROFILE: {
      BASIC_INFO_HEADLINE: 'Basisgegevens',
      BILLING_HEADLINE: 'Factuuradres',
      MAIN_HEADLINE: 'Gebruikersprofiel',
      MARKETING_AGREEMENT_TEXT: 'Ik stem er mee in om aanbiedingen en promoties te ontvangen van Weenect partners',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden',
        UPDATE_SUCCESS: 'Gegevens met succes gewijzigd'
      },
      METRIC_KM: 'Kilometers',
      METRIC_MILES: 'Mijlen',
      PREFERRED_METRIC: 'Te gebruiken metrisch systeem:'
    }
  },
  activity: {
    AVERAGE_SPEED: 'Gemiddelde snelheid',
    CONTROLS: {
      PAUSE_PLAYING_TITLE: 'Pauzeer de geschiedenis film',
      RESUME_PLAYING_TITLE: 'Herstart de geschiedenis film',
      START_PLAYING: 'Play',
      START_PLAYING_TITLE: 'Start de geschiedenis film',
      STOP_PLAYING: 'Stop',
      STOP_PLAYING_TITLE: 'Stop de geschiedenis film',
      TOGGLE_HEATMAP: 'Thermokaart',
      TOGGLE_HEATMAP_TITLE: 'Geef de thermokaart weer',
      TOGGLE_PATH: 'Spoor van de posities',
      TOGGLE_PATH_TITLE: 'Geef het spoor van de posities weer'
    },
    DETAIL_TITLE: 'Detail van de activiteit',
    EMPTY_LIST: 'Geen alerts om weer te geven. Wijzig de geselecteerde periode of controleer dat de tracer aanstaat.',
    EVENTS: {
      ALERT: 'Laterale knop geactiveerd',
      BATTERY: 'Zwakke batterij - {{battery}}% over',
      CALL_END: 'SOS oproep afgelopen',
      CALL_REQ: 'SOS oproep aangevraagd',
      ENTERS_GEOFENCE: 'Ingaan van de zone',
      EXIT_GEOFENCE: 'Uitgaan van de zone',
      FLASH_REQ: 'Flash aangevraagd',
      FREQ_CHANGE: 'Tijdsinterval gewijzigd',
      RING: 'Bellen uitgevoerd',
      RING_REQ: 'Beltoon gevraagd',
      SOS: 'SOS alarm',
      TRACKER_OFF: 'Tracer uit',
      TRACKER_ON: 'Tracer aan',
      VIBRATE: 'Trillen uitgevoerd',
      VIBRATE_REQ: 'Trilfunctie gevraagd'
    },
    FEEDBACKTEXT: {
      FIRST: '',
      SECOND: ''
    },
    HISTORY_TITLE: 'Meldingengeschiedenis',
    LAST_TIME_PERIOD: 'Geef de laatste weer',
    LOADING: 'Bezig met laden...',
    MAXIMUM_SPEED: 'Maximale snelheid',
    OBJECTIVE: {
      CONTENT: 'Stel dagelijkse doelen om ervoor te zorgen dat uw huisdier gezond is.',
      DAILY_REACHED: '{{name}} heeft haar dagelijkse doel bereikt!',
      HELPTEXT: 'Hoeveel activiteit heeft mijn huisdier nodig?',
      RECOMENDED_VALUE: 'Aanbevolen: 1 uur',
      SUBTEXT: 'Stel de dagelijks te bereiken activiteitentijd in:',
      TITLE: 'Beheer van de doelstellingen'
    },
    ONBOARDING: {
      FIRST_SUBTEXT: 'Dit instrument zal u toelaten de dagelijkse activiteit van uw metgezel te kennen. ',
      SECOND_SUBTEXT: 'Hoe lang heeft hij geslapen? Hoeveel stappen heeft hij vandaag gezet?',
      START: 'Start',
      THIRD_SUBTEXT: 'U kunt zelfs een doel instellen op basis van het ras van uw huisdier, zodat uw huisdier de dagelijkse activiteit krijgt die het nodig heeft.',
      TITLE: 'Welkom bij de Weenect activiteitentracker!'
    },
    PROGRESSBAR: {
      TITLE: 'Tijd van activiteit'
    },
    SELECT_DAY: 'Kies een weer te geven dag',
    SELECT_TIME: 'Gebruik de schuifknop om het weer te geven tijdsinterval te selecteren',
    SUMMARY: {
      ACTIVITY: 'Activiteit',
      AWAKENING: 'Ontwaken',
      DATEINTERVAL: '',
      DAY_INDEX: 'd',
      DISTRIBUTIONTEXT: 'Spreiding van de activiteit',
      HOUR_INDEX: 'u',
      LASTWEEK: '',
      MENU_TITLE: 'Activiteitenverslag',
      MIN_INDEX: 'min',
      PREVIOUS_DAY: '',
      PREVIOUS_MONTH: '',
      PREVIOUS_WEEK: '',
      REPORT: 'Verslag bekijken ',
      REST: 'Rust',
      SUBTEXT: 'Met nog {{period}} beweging voor {{name}}, wat dacht je van een wandeling met haar? ',
      TEXT_OBJECTIVE_COMPLETED: '{{name}} heeft haar dagelijkse doel bereikt!',
      TEXT_OBJECTIVE_COMPLETED_TREAT: 'Gefeliciteerd, {{name}} heeft haar doel bereikt! Misschien verdient dat een traktatie?',
      TEXT_OBJECTIVE_NONE: '{{name}} heeft geen gegevens voor deze periode',
      TEXT_OBJECTIVE_NONE_VERIFY_TRACKER: '{{name}} heeft nog geen bewegingen geregistreerd. Controleer of uw tracker is ingeschakeld en of de batterij is opgeladen!',
      TITTLE: '{{name}} vooruitgang',
      TODAY: 'Vandaag,',
      YESTERDAY: 'gisteren'
    },
    TIME_ELAPSED: 'Duur',
    TOTAL_DISTANCE: 'Totale afstand'
  },
  activityGeneral: {
    BEHAVIOR: 'Activiteit ',
    STANDARD_MORPHOLOGY: 'Morfologie ',
    STERILIZED: 'Gesteriliseerd'
  },
  activityOnboarding: {
    COMMON: {
      STEP: 'Step',
      TRACKER_PROFIL: 'Tracker profiel'
    },
    MONTH: {
      APRIL: 'April',
      AUGUST: 'Augustus',
      DECEMBER: 'December',
      FEBRUARY: 'Februari',
      JANUARY: 'Januari',
      JULY: 'Juli',
      JUNE: 'Juni',
      MARCH: 'Maart',
      MAY: 'Mei',
      NOVEMBER: 'November',
      OCTOBER: 'Oktober',
      SEPTEMBER: 'September'
    },
    PET_ACTIVITY: {
      FIRST_BUTTON: 'Af en toe (het strikte minimum)',
      FIRST_BUTTON_CAT: 'Uitzonderlijk (vakantie, onvoorziene gebeurtenissen...)',
      FIRST_BUTTON_DOG: 'Alleen voor haar eigen behoeften',
      FOURTH_BUTTON: 'Hyperactief (2 tot 3 wandelingen per dag)',
      FOURTH_BUTTON_CAT: 'Regelmatig, dag en nacht',
      FOURTH_BUTTON_DOG: '2 tot 3 wandelingen/dag',
      SECOND_BUTTON: 'Normaal (2 tot 3 wandelingen per week)',
      SECOND_BUTTON_CAT: 'Van tijd tot tijd, op zijn verzoek',
      SECOND_BUTTON_DOG: '2 tot 3 wandelingen / week',
      SELECT_ACTIVITY: 'Hoe vaak gaat hij uit?',
      THIRD_BUTTON: 'Energiek (één wandeling per dag)',
      THIRD_BUTTON_CAT: 'Regelmatig, gedurende de dag',
      THIRD_BUTTON_DOG: 'Eén wandeling per dag',
      TITLE: 'Hoeveel beweegt hij?'
    },
    PET_AGE: {
      FIRST_COMMON_TEXT: 'jaar en ',
      SECOND_COMMON_TEXT: 'maand(en)',
      TITLE: 'Wat is zijn geboortedatum?'
    },
    PET_GENDER: {
      FEMALE: 'Een vrouwtje',
      FIRST_TITLE: 'Is uw huisdier een vrouwtje of een mannetje?',
      MALE: 'Een mannetje',
      SECOND_TITLE: 'Is uw huisdier gesteriliseerd?'
    },
    PET_HEALTH: {
      LAST_VACINATION: 'Wanneer was zijn laatste vaccinatie?',
      LAST_VET_VISIT: 'Wanneer was zijn laatste bezoek aan de dierenarts?',
      TITLE: 'Gezondheidspunt'
    },
    PET_IDENTIFICATION: {
      CHIP: 'Gechipt',
      NONE: 'Mijn huisdier is niet geïdentificeerd',
      SELECT_IDENTIFICATION: 'Hoe wordt hij geïdentificeerd?',
      TATTOO: 'Getatoeëerd'
    },
    PET_INFO: {
      GET_STARTED: 'Klaar voor de start!',
      NEED_TO_KNOW: 'Het is belangrijk voor ons om {{name}} te leren kennen.',
      PETS_DETAILS: 'Vul de gegevens van uw huisdier in!',
      PETS_GENDER: {
        MIXED_BREED: 'Dit dier is een kruising'
      },
      PET_GENDER_COMMON: 'Om te beginnen, uw huisdier is: ',
      SECOND_PETS_DETAILS: 'Vul de gegevens van uw huisdier in'
    },
    PET_MORPH: {
      AVERAGE: 'Normaal',
      COMMON_TEXT: 'Ongeveer',
      FIRST_TITLE: 'Wat is de lichaamsvorm?',
      HELP_TEXT: 'Hoe bepaal ik de morfologie van mijn huisdier?',
      LEAN: 'Mager',
      OBESE: 'Zwaarlijvig',
      OVERWEIGHT: 'Overgewicht',
      SECOND_TITLE: 'Wat is zijn gewicht?',
      WEIGH_TEXT: 'Wat is het gewicht van uw huisdier?'
    },
    PET_NAME: {
      COMMON_TEXT: 'We zouden graag kennis willen maken met uw huisdier! \u2028U kunt de gegevens daarna wijzigen in de instellingen. ',
      PLACEHOLDER: 'Naam van uw huisdier',
      TITLE: 'Hoe heet hij?'
    },
    PET_RACE: {
      COMMON_TEXT: 'Ik ken het ras niet',
      PLACEHOLDER: 'Ras van de hond',
      SELECT_BREED: 'Selecteer het dierenras',
      TITLE: 'Wat is het ras?'
    },
    PET_TYPE: {
      CAT: 'En kat',
      DOG: 'Een hond'
    },
    SUMMARY: {
      BIRTH_DATE: 'geboortedatum',
      BREED: 'Ras',
      CONDITION: 'Status',
      DAILY_EXERCISE: 'Dagelijkse inspanning',
      DELETE: 'Resetten het profiel',
      HEALTH: 'Gezondheid',
      IDENTIFICATION: 'Identificatie',
      MODAL: {
        NO: 'Nee, annuleren',
        SUBTEXT: 'U zult dan het proces helemaal opnieuw moeten beginnen.',
        TITLE: 'Weet u zeker dat u de gegevens van uw huisdier opnieuw wilt instellen?',
        YES: 'Ja, dat weet ik zeker'
      },
      MORPHOLOGY: 'Lichaamsbouw',
      NAME: 'Naam',
      RECAPTEXT: 'Dit is de informatie over uw metgezel.',
      SEX: 'Geslacht',
      SPECIES: 'Ras',
      SUBMIT: 'Het profiel bevestigen',
      UNSPECIFIED: 'lege geboortedatum',
      VALIDATION: 'Bevestiging van profiel',
      VALIDATION_TEXT: 'Gelieve uw informatie te bevestigen voordat u verder gaat. ',
      WEIGHT: 'Gewicht'
    }
  },
  activityTracking: {
    ACTIVITY: 'Punten van activiteit',
    DAILY_CHART: 'Activiteitendiagram',
    DAYS: 'dag',
    HOURS: 'Uren',
    INTERVAL: {
      TEXT: 'Verplaats de randen van de grafiek om de tijdsinterval in te stellen die u wilt analyseren.',
      TITLE: 'Keuze van de interval'
    },
    MIN: 'minuten',
    PERIOD: {
      DAY: 'Dag',
      MONTH: 'Maand',
      TITLE: 'Selectie van de periode',
      WEEK: 'Week',
      YEAR: 'Jaar'
    },
    TEXT: 'Deze grafiek laat u de voortgang zien van de activiteit die uw dier heeft vertoond. Het meten van die activiteit vindt plaats op een interval die bij 0 begint en die geen maximumgrens heeft. Elk punt van activiteit stelt een versnelling voor, dat wil zeggen een beweging van uw dier.'
  },
  alertCustomer: {
    BODY0: 'Op dit moment is er onderhoud aan de gang bij onze SIM-kaartprovider.',
    BODY1: '<1>Tijdens dit onderhoud wordt de activeringstijd van de SIM-kaart verlengd tot 24 uur</1>.',
    BODY2: 'We zijn ons terdege bewust van het ongemak dat dit met zich meebrengt.\n\nVoor meer informatie kunt u contact opnemen met ons team op <1>family@weenect.com</1> of telefonisch op +33.1.87.66.00.83.',
    FOOTER: 'Het team van Weenect'
  },
  changePaymentMean: {
    ADD_BANK_ACCOUNT: 'Een te debiteren rekening toevoegen',
    ADD_BANK_CARD: 'Overschakelen op betaling met kredietkaart',
    BANK_ACCOUNT_ERROR: 'Er is een fout opgetreden bij het wijzigen van uw bankgegevens.  Neem alstublieft contact met de klantenservice op.',
    BANK_ACCOUNT_EXPLANATION: 'U moet weten dat geen van uw bankgegevens door onze servers wordt opgeslagen.',
    BANK_ACCOUNT_SUCCESS: 'Bankgegevens bijgewerkt',
    BANK_CARD_ERRORED: 'Probleem tijdens het wijzigen van het betaalmiddel. Neem alstublieft contact met de <1>klantenservice op</1>.',
    BANK_CARD_UPDATED: 'Bankpas is bijwerkt',
    CAPTATION: 'Voordat u uw bankgegevens bijwerkt, verlenen wij toestemming voor het aanrekenen van {{ amount }} op uw kaart.',
    CAPTATION_EXPLANATION1: 'Dit bedrag wordt niet afgehouden. De transactie wordt onmiddellijk geannuleerd.',
    CAPTATION_EXPLANATION2: 'Deze procedure stelt ons in staat om te voldoen aan de online betalingsstandaarden en om de veiligheid van uw bankgegevens te garanderen. Niets van deze informatie verlaat onze servers.',
    CAPTATION_EXPLANATION3: 'BELANGRIJK: op het incassoformulier zal de naam "Hareau" en niet "Weenect" staan. Dat is normaal, het gaat om de naam van ons bedrijf. Terwijl "Weenect" de handelsnaam is.',
    CAPTATION_EXPLANATION4: 'Wijzig de bankrekening waarop wordt afgeschreven, terwijl u toch hetzelfde abonnement houdt.',
    CARD_AUTHORIZED: 'De toegestane betaalwijzen zijn:',
    CHANGE_PAYMENT_MEAN: 'Mijn bankkaart bijwerken',
    CURRENT_CHOICE_CB: 'U hebt ervoor gekozen om met een creditcard te betalen.',
    CURRENT_CHOICE_IBAN: 'U heeft ervoor gekozen om te betalen via IBAN',
    CURRENT_MEAN: 'Huidige betalingsmethode',
    CURRENT_MEAN_PHRASE: 'U heeft gekozen voor betaling per creditcard',
    CURRENT_MEAN_PHRASE_GOCARDLESS: 'U heeft gekozen om te betalen via automatische incasso',
    DEFAULT_MEAN: 'Standaardbetaalmethode:',
    GO_TO_SEPA: 'Overschakelen op automatische incasso',
    MODIFY: 'Mijn betaalmiddel wijzigen',
    TITLE: 'Betaalmiddel',
    UPDATE_BANK_ACCOUNT: 'Andere debetrekening kiezen',
    UPDATE_BANK_CARD: 'Mijn bankkaart bijwerken',
    USED_PAYMENT_EXPLANATION: 'Deze betaalmethode wordt gebruikt om uw abonnementen en opties te betalen.'
  },
  commonActions: {
    ACTIVATE: 'Activeren',
    BACK: 'Retourneren',
    CANCEL: 'Annuleren',
    CAN_BE_CANCELED: 'Annuleren is mogelijk op elk gewenst moment.',
    CHANGE: 'Opslaan',
    CONFIGURE: 'Configureren',
    DEACTIVATE: '',
    GO_MAP: 'Ga naar de kaart',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden'
    },
    NEXT: 'Verder',
    NO: 'Nee',
    OK: 'Ok',
    REGISTER: 'Activeren',
    SAVE: 'Opslaan',
    SELECT_ALL: 'Alles selecteren',
    SEND: 'Versturen',
    SUBSCRIBE: 'Ik meld me aan',
    THEN: 'dan',
    UNSELECT_ALL: 'Unselect all',
    UPDATE: 'Opslaan',
    VALIDATE: 'Bevestigen',
    YES: 'Ja'
  },
  creditCardAlert: {
    AUTO_RENEWAL_ADVICE: 'Uw account blijft actief maar u moet uw betaalmethode bijwerken zodat de verlenging van uw abonnement probleemloos verloopt.',
    AUTO_RENEWAL_NOTICE: '<1>Let op!</1> Uw abonnement loopt af en uw kaart is verlopen.'
  },
  emailPreferences: {
    EMAIL_LISTS: {
      COMPANY_NEWS: 'Nieuws over het bedrijf',
      NEW_FEATURES: 'Nieuwe functies',
      OFFERS: 'Aanbiedingen',
      SERVICE_STATUS: 'Storingen en updates',
      SURVEYS_AND_TESTS: 'Vragenlijsten en betatests',
      TUTORIALS: 'Tutorials'
    },
    EMAIL_LISTS_HEADER: 'E-mail lijst',
    EMAIL_LISTS_INFO: {
      SERVICE_STATUS: 'Noodzakelijk voor de goede werking van de dienst.'
    },
    LANGUAGE_PREFERENCES_DESCRIPTION: 'Kies de taal waarin u e-mails wenst te ontvangen :',
    PREFERENCES_DECRIPTION: 'Kies het type e-mails dat u wenst te ontvangen. Wij sturen u alleen de e-mails die overeenkomen met uw keuzes. '
  },
  error: {
    TITLE: 'Er is een probleem opgetreden'
  },
  errors: {
    DEFAULT: 'Ongeldig veld',
    FIELD_REQUIRED: 'Dit veld is vereist',
    GENERAL: 'Er is een fout opgetreden, probeer het nogmaals',
    IMEI: 'Voer een geldig IMEI-nummer in',
    MAIL_FORMAT: 'Dit e-mailformaal is ongeldig',
    MAX_LENGTH: 'Dit veld mag maximaal ${max} tekens bevatten',
    PASSWORD_FORMAT: 'Je wachtwoord dient minstens één cijfer, één hoofdletter en een kleine letter.',
    PHONE_FORMAT: 'Het telefoonnummer dient het internationale kerngetal te bevatten',
    PROMO_CODE: 'Ongeldige kortingscode'
  },
  forgotPassword: {
    FORGOT_PASSWORD_BUTTON: 'Stuur mij een link om mijn wachtwoord opnieuw in te stellen',
    FORGOT_PASSWORD_CANCEL: 'Terug naar de aanmeldingspagina',
    FORGOT_PASSWORD_ERRORS: {
      UNKNOWN: 'Er is een fout opgetreden. Gelieve contact met ons op te nemen via onze helpdesk.'
    },
    FORGOT_PASSWORD_EXPIRED: 'De koppeling waarmee u uw wachtwoord opnieuw kunt instellen, is verlopen.',
    FORGOT_PASSWORD_RETRY: 'Vraag een nieuwe link aan voor een vergeten wachtwoord',
    FORGOT_PASSWORD_SUCCESS: 'Als er een account bestaat met {{mail}} ontvangt u een email met een link om uw wachtwoord opnieuw in te stellen.'
  },
  forms: {
    CITY: {
      LABEL: 'Plaatsnaam',
      PLACEHOLDER: 'Je plaatsnaam'
    },
    CONFIRM_PASSWORD: {
      LABEL: 'Wachtwoord herhalen',
      PLACEHOLDER: 'Bevestigen'
    },
    COUNTRY: {
      LABEL: 'Land'
    },
    EMAIL: {
      LABEL: 'E-mailadres',
      PLACEHOLDER: 'votre@email.com'
    },
    FIRST_NAME: {
      LABEL: 'Voornaam',
      PLACEHOLDER: 'Je voornaam'
    },
    LANGUAGE: {
      LABEL: 'Taal'
    },
    LAST_NAME: {
      LABEL: 'Naam',
      PLACEHOLDER: 'Je naam'
    },
    NEW_PASSWORD: {
      LABEL: 'Nieuw wachtwoord',
      PLACEHOLDER: 'Je nieuwe wachtwoord'
    },
    OLD_PASSWORD: {
      LABEL: 'Vorig wachtwoord',
      PLACEHOLDER: 'Je vorige wachtwoord'
    },
    PASSWORD: {
      LABEL: 'Wachtwoord',
      PLACEHOLDER: 'Wachtwoord',
      VALIDATION: 'Uw wachtwoord moet bestaan uit minstens 8 tekens.',
      WARNING: 'Waarschuwing, "qwertyuiop" en "123456789" zijn GEEN sterke wachtwoorden.\nTest hier de beveiliging van uw wachtwoord: <1>https://howsecureismypassword.net</1>'
    },
    PHONE: {
      LABEL: 'Telefoon',
      PLACEHOLDER: '+33600000000'
    },
    POSTAL_CODE: {
      LABEL: 'Postcode',
      PLACEHOLDER: 'Je postcode',
      STREET_NUMBER: 'Nummer en straatnaam'
    },
    STREET: {
      LABEL: 'Adres',
      PLACEHOLDER: 'Straatnaam en huisnummer'
    }
  },
  geofences: {
    CREATE: {
      HEADLINE: 'Een nieuwe zone aanmaken'
    },
    EDIT: {
      DELETE_GEOFENCE: 'De zone verwijderen',
      ENTRANCE_NOTIFICATION: 'Melding binnengaan zone',
      EXIT_NOTIFICATION: 'Melding verlaten zone',
      GEOCODE_LABEL: 'Gebruik een adres',
      GEOCODE_PLACEHOLDER: 'Typ het adres hier',
      LOCATION: 'Positie van het centrum van de zone',
      NAME_INPUT: {
        LABEL: 'Naam van de zone',
        PLACRHOLDER: 'Voer de naam van de zone in'
      },
      OUTDOORS: 'Buitenzone (park, bos)',
      OUTDOORS_TOOLTIP: 'Aangezien GPS buiten preciezer is, zijn de regels voor het afgaan van  een in- of uitgaande melding verschillend tussen zones die zich binnen bevinden en zones die zich buiten bevinden.',
      POINT_ON_THE_MAP_TEXT: 'of klik rechtstreeks op de kaart',
      RADIUS: 'Grootte van de zone (radius in meters)',
      TRACKER_LAST_POSITION_BUTTON: 'Gebruik de positie van de tracer',
      UNKNOWN_ADDRESS: 'Onbekend adres'
    }
  },
  header: {
    ALL_TRACKERS: 'Alle tracers',
    AVAILABLE_PLURAL: 'beschikbaar',
    NAV: {
      ACCOUNT: 'Account',
      INSURANCE: 'Verzekering',
      LOGOUT: 'Uitloggen',
      SUPPORT: 'Ondersteuning',
      TRACKERS: 'Kaart'
    },
    SEARCHBAR_RESULT: 'trackers gevonden',
    SEARCHBAR_TITLE: 'Een tracker zoeken:',
    TRACKERS: 'Tracer(s)',
    WHITE_LABEL: {
      IN_PARTERNSHIP_WITH: 'In samenwerking met'
    }
  },
  imeiInfo: {
    HINT: 'Je vindt het EMEI-nummer op de achterkant van je tracer.',
    IMAGE_ALT: 'Foto van de tracer',
    TITLE: 'IMEI-nummer'
  },
  login: {
    FORGOT_PASSWORD: 'Uw wachtwoord vergeten?',
    LOGIN_ERRORS: {
      UNKNOWN: 'Het inloggen is mislukt'
    },
    SIGN_IN_BUTTON: 'Inloggen',
    SIGN_IN_HEADLINE: 'Inloggen',
    SIGN_UP_BUTTON: 'Een account aanmaken',
    SIGN_UP_HEADLINE: 'Heb je nog geen account?',
    TERMS_LINK: 'Gebruiksvoorwaarden',
    TERMS_LINK_URL: 'https://www.weenect.com/common/pdf/en/cgv-weenect.pdf'
  },
  manageSubscription: {
    CANCEL: {
      DESCRIBE_YOUR_PROBLEMS: 'Kunt u de reden voor het opzeggen van uw abonnement specificeren?',
      GIVE_US_DETAILS: 'Reden voor het opzeggen van uw abonnement.',
      REASONS: {
        AFTER_SALES_SERVICE: 'Slechte ervaring met de klantenservice',
        BATTERY: 'Onvoldoende accuduur',
        LOST_BROKEN: 'Ik ben het kwijt / het is kapot',
        MOBILE_APPLICATION: 'De app functioneert niet',
        NOT_SATISFIED: 'Ik ben niet tevreden met Weenect',
        NO_USE: 'Ik heb het niet meer nodig',
        TOO_EXPENSIVE: 'Het abonnement is te duur',
        TRACKER_FORMAT: 'Formaat van de tracker te herzien (maat en gewicht)',
        WILL_REACTIVATE_LATER: 'Ik zal het abonnement later opnieuw activeren'
      },
      SAD_TO_SEE_YOU_LEAVE: 'We vinden het heel jammer dat u weggaat.',
      WHY_DO_YOU_RESIGN: 'Waarom wilt u uw abonnement opzeggen?'
    },
    CANCEL_EXPIRE: 'De automatische verlenging van is geannuleerd. Het abonnement verloopt op {{date}}.',
    CANCEL_QUESTION: 'Wil je het abonnement niet verlengen?',
    CANCEL_RENEWAL: 'De automatische verlenging annuleren',
    CANCEL_RENEWAL_BIS: 'Het abonnement opzeggen',
    CANT_RESIGN_UNDER_EXTENDED_NOTICE: 'Wilt u uw abonnement beëindigen of alleen maar de automatische verlenging van uw abonnement deactiveren?\nBel ons dan op +33 (0)1 87 66 98 71 van 9.30 u tot 16.00 u. van maandag tot en met vrijdag.',
    CANT_RESIGN_UNDER_NOTICE: 'Het is niet mogelijk om uw abonnement in de laatste 30 dagen op te zeggen. Neem contact op met het Customer Support-team.',
    CANT_RESIGN_UNTIL: 'Je kunt je abonnement pas op {{date}} neerleggen',
    CANT_RESIGN_WHILE_COMMITED: 'U bent voor 3 maanden vastgelegd, de beëindiging van uw abonnement wordt pas van kracht aan het einde van de lopende periode, d.w.z. op {{date}}',
    CURRENT_PLAN: 'Huidig abonnement',
    HEADLINE: 'Abonnement beheren',
    MESSAGES: {
      UPDATE_SUCCESS: 'Abonnement met succes bijgewerkt.'
    },
    RENEW_SUBSCRIPTION: 'Het abonnement vernieuwen',
    RESIGN_DATE: 'De beëindiging van uw abonnement gaat in op het einde van de huidige periode, die {{date}}',
    RETURN_TO_TRACKERS: 'Terug naar de kaart'
  },
  map: {
    NO_GPS_MARKER_TITLE: 'Geen GPS signaal',
    POSITION: 'Positie'
  },
  mapControls: {
    MAP_TYPE: 'Kaarttype',
    REFRESH_POSITION: 'Positie vernieuwen',
    SUPER_LIVE: 'Super live',
    ZOOM_IN: 'Zoom +',
    ZOOM_OUT: 'Zoom -'
  },
  mapLayers: {
    CLASSIC_MAPS: 'Klassieke kaarten',
    DONT_SUBSCRIBE: 'Verdergaan met de klassieke kaarten',
    FOREST_PATH: 'Boswegen en paden',
    GOOGLE_STREET: 'Google Maps',
    GRAY: 'Zwart en wit',
    PLAN: 'Kaart',
    PREMIUM_MAPS: 'Premium kaarten',
    PREMIUM_SUBSCRIBE: 'Abonneer voor de optie Premium Kaarten',
    PREMIUM_WARNING_MSG: 'Oeps, u bent momenteel niet geabonneerd voor de optie Premium kaarten! \u2028\n\nMet deze optie profiteert u van completere kaarten en regelmatige updates zodat u de verplaatsingen van uw tracker beter in de gaten kunt houden. \u2028Met de versies Satelliet HD, Reliëfkaarten of Bospaden vindt u uw tracker in één ogenblik terug.\n\nAbonneer u voor deze optie voor slechts {{mapOptionPrice}} per maand!',
    RELIEF: 'Bergkaart',
    SATELLITE: 'Sateliet',
    STREET: 'Klassiek',
    UNLOCK_MAPS: 'Speel extra kaarten vrij door erop te klikken!'
  },
  misc: {
    LANGUAGE: {
      DE: 'Duits',
      EN: 'Engels',
      ES: 'Spaans',
      FR: 'Frans',
      IT: 'Italiaans',
      NL: 'Nederlands'
    },
    NO_DATE: 'Geen positie bekend',
    ROTATE_DEVICE: 'Weenect is ontworpen om te worden gebruikt in portret modus. Draai je apparaat om zo goed mogelijk van onze diensten gebruik te kunnen maken.',
    locale: 'nl-NL'
  },
  onboarding: {
    ACTIVATING_HINT: 'Gemiddelde activatietijd van 5 minuten',
    ACTIVATING_INDICATOR: 'Wordt geactiveerd',
    GREET: {
      BUTTON: 'Mijn tracker is ingeschakeld',
      COMMON_TEXT: 'Om goed te beginnen raden we u aan om de volgende tutorial te volgen. het zal u helpen om uw tracker te beheren.\n\nLaten we beginnen met het inwerking stellen van uw tracker. Volg de indicaties hieronder om het aan te zetten:',
      MAIN_TITLE: 'WELKOM BIJ DE FAMILIE!',
      RED_TEXT: 'De tracker dient zich buiten te bevinden zodat deze een GPS-positie kan bepalen.'
    },
    MAP_INTERACTIONS: {
      COMMON_TEXT: {
        PET: 'Van boven naar onder kunnen deze knoppen uw tracker laten afgaan of laten vibreren. Met deze functionaliteiten kunt u uw huisdier trainen om terug te komen.',
        SILVER: 'Van boven naar onder kunnen deze knoppen uw tracker laten afgaan of laten vibreren. '
      },
      TITLE: 'De knoppen voor interactie met de tracker'
    },
    MAP_INTERVAL: {
      COMMON_TEXT: 'Met deze knop kunt u kiezen op welke frequentie u een nieuwe GPS-positie voor uw tracker wilt ontvangen. Let op, hoe korter de tussentijd, hoe minder autonoom de autonomie van de tracker.',
      TITLE: 'De tijdsinterval tussen iedere GPS-positie'
    },
    MAP_OPTIONS: {
      COMMON_TEXT: 'Met deze knoppen kunt u het type kaart dat u wilt weergeven wijzigen en kunt u in- of uitzoomen.',
      TITLE: 'De afstelknoppen van de kaart'
    },
    MAP_UPDATE: {
      COMMON_TEXT: 'Met deze knop kunt u de positie van uw tracker actualiseren (om een nieuwe positie te krijgen dient uw tracker met de server te zijn verbonden en een geldig gps signaal te hebben).',
      TITLE: 'De knop om de positie te actualiseren'
    },
    MODAL: {
      BUTTON_HINT: 'Druk gedurende 3 seconden op de knop totdat de tracer trilt.',
      HEADLINE: 'Zet de tracer buiten of bij de rand van een raam aan.',
      IMAGE_ALT: 'Foto van de tracer',
      MAP_HINT: 'Eenmaal aangezet zal de tracer gemiddeld binnen 5 minuten op de kaart verschijnen.'
    },
    REMOTE_ASSISTANCE: {
      ASSISTANCE: {
        COMMON_TEXT: 'Indien niemand binnen 30 seconden op het alert reageert, wordt deze doorgestuurd naar een medewerker.'
      },
      EXPLANATION: 'De medewerker neemt de oproep aan en beantwoord de behoefte van de tracker-drager. Afhangende van de situatie kan hij <1>contact opnemen met naasten of hulpdiensten oproepen</1>.',
      PRICE: 'De optie kost € 9,99 per maand en kan op ieder gewenst moment worden uitgeschakeld.',
      SOS: {
        COMMON_TEXT: 'Je naaste drukt op de SOS knop van zijn/haar tracker.'
      },
      TIME: {
        COMMON_TEXT: 'U krijgt een alert en heeft 30 seconden om erop te reageren.'
      },
      TITLE: 'Ontdek onze nieuwe optie: teleassistentie ',
      USE_CASE: 'Met deze optie kan de drager van de tracker 7/7d en 24/24u contact maken met een gespecialiseerd teleassistentie plateau.'
    },
    SIDE_PAGES: {
      COMMON_TEXT: {
        PET: 'In het linkerdeel vindt u alle informatie betreffende het beheer en het gebruik van uw tracker. Onder de tab Geschiedenis vindt u alle verplaatsingen van uw huisdier en de onder de tab Activiteit volgen zijn energieverbruik. Onder de tab Zones kunt u deze configureren, de tab Instellingen bevat het profiel van uw huisdier en de in de tab Abonnement kunt u uw abonnement en uw opties zeer eenvoudig beheren.',
        SILVER: 'In het linkerdeel vindt u alle informatie betreffende het beheer en het gebruik van uw tracker. Onder de tab Geschiedenis vindt u alle verplaatsingen van uw huisdier en onder de tab  Zones kunt u de veiligheidsinstellingen configureren..\n Onder de tab Instellingen kunt u het te bellen nummer invoeren waarnaar gebeld dient te worden in geval van SOS evenals de meldingen die worden toegewezen aan iedere zijknop van de tracker. Als laatste kunt u in de tab Abonnement heel eenvoudig uw abonnement en uw opties beheren.'
      },
      TITLE: 'Extra pagina\'s'
    },
    SKIP: 'De tutorial overslaan',
    SUPERLIVE: {
      COMMON_TEXT: 'Met deze knop kunt u de  "superlive" modus aanzetten: iedere 10 seconden een nieuwe positie gedurende 5 minuten.',
      TITLE: 'De "superlive" knop'
    },
    TUTORIAL_START: {
      BUTTON: 'Verder gaan met de tutorial',
      COMMON_TEXT: 'Geweldig, uw tracker is nu klaar voor gebruik. Laten we verder gaan met een rondleiding door uw persoonlijke account in My Weenect.'
    },
    WAITING_GPS_POSITION: 'Zoeken naar een gps positie',
    WAITING_GPS_POSITION_HINT: 'Zet uw tracker buiten aan.'
  },
  optionCardExpired: {
    PROPOSAL: 'Gelieve uw bankpas bij te werken om van onze diensten gebruik te blijven maken.',
    UPDATE_CARD: 'Nu bijwerken',
    WARNING: '<1>Opgelet!</1> U kunt u niet op deze optie inschrijven want <1>uw bankpas is verlopen</1>.'
  },
  payment: {
    ADD_NEW_PAYMENT_REGULARISE: 'Registreer een nieuwe betaalmethode of corrigeer uw situatie.',
    EXPIRACY_CREDIT_CARD_TEXT: 'Voeg een nieuwe betaalmethode toe om uw situatie te regulariseren.',
    NO_PAYMENT_MEAN_YET: 'Momenteel geen betaalmiddel',
    NO_PAYMENT_YET: 'Geen betaling op dit moment',
    UPDATE_PAYMENT_REGULARISE: 'Werk deze bij of wijzig deze om uw situatie te regulariseren.',
    WARNING_ERROR: 'Er lijkt een probleem te zijn met uw betaalmethode.',
    WARNING_EXPIRACY_CREDIT_CARD: 'Let op: Uw creditcard is verlopen.',
    WARNING_NOT_ENOUGH_FOUNDS: 'Let op, het lijkt erop dat er niet genoeg geld op je rekening staat.'
  },
  payments: {
    CARD: {
      ACCEPTED: '(CB, VISA, Mastercard, American Express)',
      DESCRIPTION: '30 dagen niet goed geld terug',
      NAME: 'Creditcard'
    },
    STATUS: {
      CANCELED: 'Gebruik een adres',
      ERROR: 'Mislukt',
      NEW: 'In behandeling',
      PAYMENT_ERROR: 'Betaling mislukt',
      PAYMENT_ERROR_EXPLAIN_TEXT: 'Er is een fout opgetreden tijdens uw betaling. Probeer het over enkele ogenblikken opnieuw of neem contact',
      PAYMENT_RETRY: 'Probeer opnieuw',
      PAYMENT_SAV: 'klantendienst.',
      PAYMENT_SUCCESS: 'Betaling geslaagd!',
      PAYMENT_SUCCESS_TEXT: 'Uw betaling is verwerkt. U kunt nu beginnen met uw ervaring bij Weenect !',
      PAYMENT_SUCCESS_TEXT_APP: 'Uw betaling is verwerkt.',
      PENDING: 'In behandeling',
      REFUNDED: 'Terugbetaald',
      SUCCESS: 'Bevestigd'
    },
    TRANSFER: {
      DESCRIPTION: '15 dagen extra om je te bedenken, oftewel 30 dagen niet goed geld terug garantie.',
      NAME: 'Bankafschrijving'
    }
  },
  periods: {
    ALT: {
      MONTH: '1 maand',
      YEAR: '1 jaar',
      YEAR_plural: '{{count}} jaar'
    },
    BASE: {
      MONTH: 'maand',
      YEAR: 'Jaar'
    }
  },
  registerSummaryTable: {
    BILLED_MONTH: 'De betaling is maandelijks',
    BILLED_NOW: 'Onmiddelijk gefactureerd',
    BILLED_THREE_YEARS: 'De betaling gebeurt eens in de 3 jaar',
    BILLED_TWO_YEARS: 'Dit wordt éénmaal per twee jaar gefactureerd',
    BILLED_YEAR: 'De betaling gebeurt eens per jaar',
    HINT_OPTIONS_PRICE: 'De opties, voor een bedrag van {{amount}}, zullen binnen twee uur afzonderlijk van het abonnement worden gefactureerd..',
    OPTION_UPGRADE: 'Voeg de optie {{name}} toe voor slechts {{price}} / {{period}}',
    PAY_HINT: 'Klik op de knop "Betalen en activeren" en betaal {{subscriptionPrice}} voor het abonnement.',
    SUBSCRIPTION_UPGRADE: 'Zet om naar een abonnement voor 2 jaar en bespaar ieder jaar {{amount}}.',
    TOTAL: 'Totale prijs'
  },
  registerTracker: {
    BUY_LINK: 'Heb je nog geen Weenect tracer? <1>Je kunt er hier één kopen</1>',
    ERRORS: {
      UNKNOWN: 'Er is een probleem ontstaan tijdens het activeren van de tracer, probeer het nogmaals'
    },
    HEADLINE: 'Een nieuwe tracer activeren',
    IMEI_INPUT: {
      LABEL: 'IMEI-nummer',
      PLACEHOLDER: 'Je vindt het EMEI-nummer op de achterkant van je tracer.'
    },
    NAME_INPUT: {
      LABEL: 'Naam van de tracer',
      PLACEHOLDER: 'Naam drager van de tracer'
    }
  },
  remoteAssistance: {
    BASE: 'Tele-ondersteuning',
    FEATURES: 'Als er na het drukken op de SOS-knop 30 seconden lang geen antwoord van u komt, worden de SOS-melding en de spraakoproep automatisch doorgestuurd, 7/7 dagen en 24/24 uur, naar onze hulpdienst op afstand. Onze medewerkers zijn bevoegd om contact op te nemen met reddingsdiensten zoals de politie of ambulance.',
    FORM: {
      ADDRESS_INPUT: {
        LABEL: 'Adres',
        PLACEHOLDER: 'Straatnaam en huisnummer'
      },
      BACK: 'Annuleren',
      CITY_INPUT: {
        LABEL: 'Plaatsnaam',
        PLACEHOLDER: ''
      },
      EXTRA_PHONE: {
        LABEL: 'Tweede telefoon (optioneel)',
        PLACEHOLDER: ''
      },
      HEADLINE: 'Aanmelden voor tele-ondersteuning',
      NAME_INPUT: {
        LABEL: 'Niet compleet',
        PLACEHOLDER: 'Voornaam NAAM'
      },
      PHONE: {
        LABEL: 'Telefoon',
        PLACEHOLDER: ''
      },
      POSTAL_CODE_INPUT: {
        LABEL: 'Postcode',
        PLACEHOLDER: ''
      },
      SAVE: 'Bevestigen',
      TERMS: 'Ik heb <1>de gebruiksvoorwaarden</1> van de dienst gelezen en aanvaard ze.',
      TEXT: 'Voor de gegevens van je naaste (de drager van de tracer) in. Zo kunnen onze medewerkers te hulp komen bij een SOS-alert.'
    },
    PENDING: {
      WAITING_FOR_CALL_TEST: 'Inschrijfformulier bevestigd. In afwachting van de testoproep.\n U moet nu een testoproep uitvoeren om het activeren van de keuze af te ronden. Raadpleeg de aan u gerichte e-mail om de te volgen procedure te achterhalen.',
      WAITING_FOR_RESIGN: 'De desactivering van de keuze is bezig.\n Uw aanvraag voor de desactivering van de keuze is goed ontvangen. Onze medewerkers zijn op dit moment bezig met de administratieve formaliteiten. De desactivering zal binnenkort ingaan.',
      WAITING_FOR_VALIDATION: 'Inschrijfformulier in afwachting van bevestiging. \nDe gegevens die u hebt opgegeven tijdens uw inschrijving op de keuze worden op dit moment door onze medewerkers gecontroleerd. Zodra deze gegevens worden bevestigd, kunnen we overgaan tot het laatste stadium van de activering van de keuze: een testoproep moet worden uitgevoerd om te controleren of alles goed functioneert.'
    },
    USER_PHONE: {
      TEXT: 'Geef uw gegevens op. Zo kunnen onze medewerkers contact met u opnemen bij een SOS-alarm.'
    }
  },
  signup: {
    ALREADY_MEMBER_TEXT: 'Heb je al een account?',
    CREATE_ACCOUNT_HEADLINE: 'Een account aanmaken',
    LOGIN_TEXT: 'In loggen',
    MARKETING_AGREEMENT_TEXT: 'Ik stem er mee in om aanbiedingen en promoties te ontvangen van Weenect partners',
    RETRY: 'Probeer opnieuw',
    SIGNUP_ERRORS: {
      EMAIL_ALREADY_EXISTS: 'Dit e-mailadres is al gekoppeld aan een account',
      POPIN_EMAIL_ALREADY_EXIST: 'Oeps, dit emailadres schijnt al gebruikt te zijn.',
      UNKNOWN: 'Het inloggen is mislukt, probeer het nogmaals'
    },
    SIGN_IN_TEXT: 'Inloggen',
    SUBMIT_BUTTON: 'Mijn account aanmaken',
    TERMS_AGREEMENT_TEXT: 'Ik aanvaard <1>de gebruiksvoorwaarden</1> van de dienst'
  },
  simInfo: {
    TITLE: 'SIM-nummer'
  },
  subOptions: {
    CANCEL: {
      EXPIRATION_DATE: 'Deze optie blijft actief tot {{date}}',
      HEADLINE: 'Wil je de optie {{option}} annuleren?'
    },
    MAP: {
      BASE: 'Multi-kaarten pakket',
      FEATURES: 'Profiteer van completere kaarten en regelmatige updates zodat u de verplaatsingen beter in de gaten kunt houden. (Satelliet HD, Reliëfkaarten, Bospaden)',
      TITLE: 'HD kaarten: '
    },
    PREMIUM: {
      BASE: 'Premium pakket',
      BUTTON: {
        FREEMONTH: 'Probeer het gratis voor 1 maand!'
      },
      DEACTIVATE: 'Door deze optie te deactiveren verliest u uw premie voordelen.',
      FEATURE: {
        CHAT: {
          BASE: 'Online chatten',
          TEXT: 'Chat rechtstreeks met een Weenect-adviseur in uw toepassing, 7 dagen per week, van 9u tot 18u.',
          TEXT2: 'Geniet van exclusieve voordelen (online chat, sms-waarschuwingen, HD-kaarten) binnen de applicatie.'
        },
        SMS: {
          TEXT: 'Laat u rechtstreeks per SMS waarschuwen voor alle meldingen van uw keuze (40 SMS per maand).'
        }
      },
      FREEMONTH: {
        BASE: 'Eerste maand gratis!',
        BASE2: 'GRATIS'
      },
      SUBTITLE: 'Het Premium Pack bevat de volgende functies:',
      SUB_LEGAL: 'Dit lidmaatschap wordt elke maand automatisch verlengd, via de betaalwijze die u bij het afsluiten van uw abonnement hebt gekozen. U kunt het lidmaatschap op elk moment opzeggen via uw persoonlijke ruimte.',
      TEXT_INFO_PRICE: 'Service aangerekend aan €{{price}}/maand na de eerste maand.',
      TITLE_DISCOVER: 'Ontdek uw exclusieve voordelen: ',
      TITLE_POPUP: 'Word Premium lid!'
    },
    SMS_PACK: {
      ALT_FEATURES: 'Ontvang alle meldingen per SMS',
      BASE: 'SMS pakket',
      FEATURES: 'Ontvang alle meldingen per sms (20 sms-berichten beschikbaar per maand). Zonder deze optie ontvangt u nog altijd meldingen via pushberichten (app) en e-mails.'
    },
    UNDEFINED: {
      PAYMENTS: 'Betaling voor de optie'
    },
    WARRANTY: {
      ALT_FEATURES: {
        one: 'Ontvang gratis een nieuwe tracker in geval van schade, verlies of diefstal van je huidige tracker.',
        other: 'Betaald met debetkaart met mogelijkheid tot annuleren op iedere gewenst moment',
        two: 'Uw tracer is in 48H vervangen in geval van verlies, breuk of diefstal, zonder voorwaarden.'
      },
      BASE: 'Multi-risico garantie',
      EXPLAINER: 'De standaard garantie dekt alleen de fabricatiefouten van de tracer.',
      FEATURES: 'Ontvang een gratis vervangende tracker bij schade, verlies of diefstal van uw huidige tracker; u hoeft geen bewijs te leveren. De standaard garantie dekt alleen de fabricatiefouten van de tracker.'
    }
  },
  subscription: {
    COMMON_TEXT: {
      MONTH: '/ maand',
      OR: 'Dus'
    },
    FEATURES: {
      APP_ACCESS: 'Onbeperkte toegang tot de applicatie en locatiedienst',
      REFOUND: '15 dagen proberen: tevreden of uw geld terug',
      SAV: 'Klantenservice beschikbaar 6/7 dagen. \nGegarandeerd antwoord binnen 24 uur.',
      WARRANTY: '24 maanden fabrieksgarantie'
    },
    INFO_CGU: 'Om van de abonnementen te kunnen genieten, moet u geldige bankgegevens invoeren en de algemene',
    INFO_CGU_BUTTON: 'de algemene voorwaarden voor het gebruik van de dienst.',
    INFO_OPTION: 'Betaling is maandelijks, opzegging is op elk moment mogelijk.',
    MONEY_BACK: 'Alle abonnementen worden onmiddellijk gefactureerd, maar kunnen binnen 15 dagen worden opgezegd, met volledige terugbetaling van het betaalde bedrag.',
    PAYMENT_INFO: 'Geld-terug-garantie: 15 dagen om uw tracker uit te proberen',
    PAYMENT_SUBHEADLINE: 'Selecteer een betaalmethode om uw abonnement te betalen en uw tracker te activeren.',
    PAYMENT_TITLE: 'Kies uw betalingsmethode',
    PAYMENT_TITLE2: 'Register your payment method.',
    PRICE_MONTH_SAVED: 'dus {{price}} / maand',
    SUMMARY: 'Samenvatting'
  },
  subscriptionAlert: {
    ACTIVATE_AUTO_RENEWAL: 'De automatische verlenging weer inschakelen',
    AUTO_RENEWAL_ADVICE: 'Wij adviseren u om de automatische verlenging weer in te schakelen indien u uw abonnement wilt verlengen',
    AUTO_RENEWAL_NOTICE: '<1>Let op!</1> Uw abonnement voor de tracer {{trackerName}} loopt af en de automatische verlening ervan is uitgeschakeld.',
    CONTINUE_BUTTON: 'Doorgaan',
    MESSAGES: {
      UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden',
      UPDATE_SUCCESS: 'Met succes bijgewerkt'
    },
    REMIND_LATER: 'Herinner me er later aan',
    SUPPORT_TEXT: 'Indien u vragen hebt of u denkt dat het een fout betreft, neem dan contact met onze <1>klantenservice op</1>.'
  },
  subscriptionOptionsSelection: {
    CANCEL_WARNING_MODAL_HEADLINE: 'Weet je zeker dat je deze optie wilt deactiveren?',
    CANCEL_WARNING_SMS: 'Door deze optie te deactiveren ontvang je geen meldingen voor alerts via SMS.',
    CANCEL_WARNING_WARRANTY: 'Door deze optie te deactiveren verlies je de extra bescherming van de multi-risico verzekering.',
    EXPLANATION: 'De opties staan standaard geselecteerd, maar u kunt ze uitvinken door erop te klikken.',
    EXPLANATION2: 'And cancellation is possible at any time.',
    EXPLANATION3: 'Er wordt niet betaald.',
    HINT: 'je kunt op ieder gewenst moment een optie activeren of deactiveren',
    MAIN_HEADLINE: 'Kies je opties',
    SUBMIT_BUTTON: 'Bevestigen'
  },
  subscriptionPayment: {
    ERRORS: {
      UNKNOWN: 'Er is een probleem opgetreden tijdens de betaling, probeer het nogmaals'
    },
    GIFT_CARD: {
      EXPLANATION: 'Voer de code in die op de achterkant van de kaart staat die in uw tracker pack zit.',
      I_HAVE_ONE: 'Ik heb een cadeaubon',
      NEED_HELP: 'Hulp nodig met cadeaubonnen?',
      TITLE: 'Gebruik uw geschenk',
      USE: 'Gebruiken'
    },
    HEADLINE: 'Overzicht vóór betaling',
    PROMOCODE_TEXT: 'Ik heb een coupon code:',
    PROMO_CODE: 'Coupon code',
    PROMO_CODE_GIFT_CARD: 'Cadeaubon',
    SATISFIED_OR_REFUNDED: 'Niet goed geld terug garantie',
    SATISFIED_OR_REFUNDED_DESCRIPTION: '30 dagen om deze zonder risico uit te proberen, als u niet tevreden bent geven we uw geld terug,',
    SELECT_PAYMENT_MEAN: 'Selecteer een betaalmethode voordat u uw abonnement betaalt en activeert.',
    SUBMIT_BUTTON: 'Abonnement instellen',
    SUBMIT_BUTTON2: 'Activeer de tracker',
    SUMMARY: 'Herhaling van je keuzes'
  },
  subscriptionProcess: {
    GIFT_CARD_EXPLANATION: 'Voer de betalingsmethode van uw voorkeur in om uw tracker gratis te activeren.',
    GIFT_CARD_EXPLANATION2: 'Deze informatie is nodig om juridische redenen in verband met het gebruik van SIM-kaarten.',
    RETURN_TO_APP: 'Terugkeren naar de app.',
    RETURN_TO_MAP: 'Terugkeren naar de kaart',
    RETURN_TO_TRACKERS: 'Terug naar de kaart',
    STEPS: [
      'Tracer',
      'Abonnement',
      'Opties',
      'Samenvatting'
    ]
  },
  subscriptionSelection: {
    BEST_OFFER: 'BESTE AANBIEDING',
    CANCELABLE_AFTER_THREE_MONTHS: 'Mogelijkheid tot annuleren op iedere gewenst moment na de eerste drie maanden',
    CANCEL_ANYTIME: 'Mogelijkheid tot annuleren op iedere gewenst moment',
    COMMITMENT_TEXT: 'Kan op elk moment worden opgezegd na de minimale verbintenisperiode van 3 maanden.',
    FEATURES: '',
    FEATURES_HEADLINE: 'Alle abonnementen zijn inclusief:',
    HEADLINE: 'Kies je abonnement',
    MIN_THREE_MONTHS: 'Minimum inschrijving voor drie maanden',
    MONEY_BACK: 'Alle abonnementen worden onmiddellijk gefactureerd maar kunnen binnen een termijn van 30 dagen worden geannuleerd, met terugbetaling van het volledige betaalde bedrag.',
    PAYMENT_MEANS: 'Betaling via automatische incasso of kredietkaart',
    REFUNDED_INFO: 'We betalen uw abonnement terug zonder voorwaarden.',
    REFUNDED_TEXT: 'Tevreden of terugbetaald 30 dagen.',
    SIM_CARD_WARNING: 'Een SIM kaart is direct in uw tracker ingebouwd. Dit verklaart waarom het nodig is om in te schrijven op een abonnement (om de servicekosten te dekken).',
    SUBMIT_BUTTON: 'Bevestigen',
    XMAS_OFFER: {
      BODY: 'Toch zijn we verplicht om u nu al te laten kiezen voor uw toekomstig abonnement (om veiligheidsreden is het product namelijk uitgerust met een SIM-kaart). Er worden geen kosten in rekening gebracht en u kan het abonnement op elk moment opzeggen tot één maand voor de ingangsdatum.',
      TITLE: 'Uw tracker is voorgeladen met een abonnement van 6 maanden geschonken  door uw geliefde.'
    },
    features: [
      '30 dagen niet goed geld terug',
      'Onbeperkte GPS lokalisatie',
      'Meldingen via push-berichten (App) en e-mails',
      '10 minuten spraakoproep per maand'
    ]
  },
  subscriptions: {
    BASE: 'Abonnement',
    CANCEL_MODAL: {
      HEADLINE: 'Wil je de automatische verlenging van je abonnement annuleren?',
      HINT: 'Je abonnement blijft actief tot {{date}} maar zal niet automatisch worden verlengd. Na deze datum kun je je tracer niet meer localiseren.'
    },
    CHANGE_PLAN: {
      ADD_OPTIONS: 'Een optie toevoegen',
      EXPLANATION: 'De verandering van abonnement zal ingaan aan het eind van de lopende periode.',
      HEADLINE: 'Van abonnement veranderen?'
    },
    DETAILS: {
      ACTIVATE_BUTTON: {
        LABEL: 'Abonnement beheren'
      },
      ACTIVE_CASE: {
        MANAGE_BUTTON: 'Abonnement beheren',
        RENEWAL_INFO: 'Je abonnement wordt automatisch verlengd op {{date}} voor {{price}}'
      },
      CANCELED: {
        EXPIRED_MAIN: 'Uw abonnement is verlopen. Activeer het abonnement opnieuw om uw tracker te kunnen lokaliseren.',
        EXPIRED_SEC: 'Verlopen',
        UNCANCEL_BUTTON_LABEL: 'Automatische verlenging van abonnement opnieuw activeren',
        WILL_EXPIRE_DAYS: 'Verloopt over {{count}} dag',
        WILL_EXPIRE_DAYS_plural: 'Verloopt over {{count}} dagen',
        WILL_EXPIRE_MAIN: 'De automatische verlenging van je abonnement is geannuleerd. Het verloopt op {{date}}.'
      },
      CANCEL_RESILLIATION: 'Annuleer het annuleringsverzoek',
      NEW_CASE: {
        INFO_TEXT: 'Er is een probleem met je betaling. Probeer het opnieuw of wijzig je betaalmethode.',
        PAY_BUTTON: 'Betalen en activeren'
      },
      NO_SUB: {
        INFO_TEXT: 'Activeer het abonnement om te beginnen de tracer te gebruiken.'
      },
      RESILLIATION_ASKED: 'Gevraagde annulering',
      SUPPORT_TEXT: 'Heb je problemen met het activeren van je tracer? Neem contact op met <1>ondersteuning</1>.'
    },
    OFFERS: {
      SAVING_PER_YEAR: 'bespaar {{amount}} / jaar'
    },
    OFFERS_TEXT: {
      MOST_POPULAR: 'De populairste',
      ONE_MONTH: {
        INFO: 'Verbintenis van 3 maanden',
        TITLE: '1 maand pakket'
      },
      ONE_YEAR: {
        INFO: '15 dagen proefperiode: 30 dagen in totaal',
        TITLE: '1 jaar pakket'
      },
      THREE_YEAR: {
        INFO: '1 jaar extra fabrieksgarantie: 3 jaar in totaal',
        INFO2: '3-year manufacturer\'s warranty (instead of two).',
        TITLE: '3-jarig pakket'
      }
    },
    OVERVIEW: {
      OPTIONS: 'Details van de opties',
      REG_DETAILS: 'Details van de tracer',
      REPLACE_TRACKER_BUTTON: {
        LABEL: 'Tracer vervangen'
      },
      SUB_DETAILS: 'Details van het abonnement'
    },
    PLAN_NAMES: {
      FALLBACK: 'Abonnement',
      ONE_MONTH: 'Maandelijks abonnement',
      ONE_YEAR: 'Prepaid abonnement van 1 jaar',
      THREE_YEARS: 'Prepaid abonnement van 3 jaar',
      TWO_YEARS: 'Abonnement 2 jaar'
    },
    STATUS: {
      ACTIVE: 'Geabonneerd',
      CANCELED: 'Geannuleerd',
      INACTIVE: 'Niet Geabonneerd',
      NEW: 'Actief',
      PENDING: 'In behandeling'
    },
    TRACKER_DETAILS: {
      REGISTRATION_DATE: 'Activeringsdatum',
      WARRANTY: 'Standaard garantie'
    }
  },
  superLive: {
    ACTIVE_TEXT: 'De super live modus is actief',
    HEADLINE: 'Super live modus',
    MESSAGES: {
      SUCCESS_ACTIVATED: 'Super live modus met succes geactiveerd - Eén positie iedere {{frequency}} seconden gedurende 5 minuten'
    },
    TIME: 'Eén positie elke {{frequency}} seconden'
  },
  support: {
    FORM: {
      MESSAGE: {
        LABEL: 'Je bericht*',
        PLACEHOLDER: ''
      },
      PROBLEM: {
        AVAILABLE_OPTIONS: 'Beschikbare opties',
        LABEL: 'Soort probleem*'
      },
      SUBJECT: {
        LABEL: 'Onderwerp van je vraag*',
        PLACEHOLDER: ''
      },
      TRACKER: {
        LABEL: 'Betreffende tracer aangaande je vraag',
        PLACEHOLDER: 'Selecteer de tracer'
      }
    },
    HEADLINE: 'Ondersteuning',
    HELP_CENTER: 'Helpcentrum',
    HELP_TEXT: 'Hulp nodig? Ga naar ons helpcenter om het antwoord op je vraag te vinden. Je kunt ook contact opnemen middels het formulier hieronder; ons team zal je op werkdagen binnen 24 uur antwoorden.',
    MAIN_TEXT: 'In ons helpcentrum vindt u alle artikelen die uw vragen kunnen beantwoorden.',
    MESSAGES: {
      SUBMIT_ERROR: 'Er is een fout opgetreden, probeer het nogmaals',
      SUBMIT_SUCCESS: 'Bericht met succes verzonden'
    },
    PROBLEM_TYPES: {
      CHARGE: 'Mijn tracer laadt niet normaal op',
      DOWNLOAD_APP: 'Het lukt me niet om de app te downloaden',
      GPS_INVALID: 'Mijn GPS-signaal wordt als ongeldig weergegeven',
      GPS_PRECISION: 'De GPS-locatie van mijn tracer is onjuist',
      LEDS_FIXED: 'De twee leds van mijn tracer veranderen niet',
      NO_GEOFENCE_ALERTS: 'Ik ontvang onjuiste meldingen voor het in- of uitgaan van een zone',
      NO_NOTIFICATIONS: 'Ik ontvang geen meldingen',
      OTHER: 'Andere',
      SIGN_IN: 'Het lukt me niet om in te loggen op mijn account',
      SUBSCRIPTION: 'Ik heb een probleem met mijn abonnement',
      TRACKERS_PARAMS: 'Ik kan mijn tracer niet instellen',
      TRACKER_NOT_CONNECTED: 'Mijn tracer wordt als niet-verbonden weergegeven'
    },
    SUBMITTED_TICKET: 'We hebben uw aanvraag goed ontvangen, dank u.\nEr is een e-mail ter bevestiging aan u verzonden (vergeet niet om uw spambox te controleren).',
    SUB_TEXT: 'Als deze uitleg niet voldoende is, kunt u ook contact opnemen met een lid van ons serviceteam, dat u binnen 24 werkuren zal antwoorden.',
    TITLE: 'Heb je hulp nodig?',
    URGENT_NUMBER: 'Als uw aanvraag dringend is, kunt u contact opnemen met onze klantenservice op \n+33 187 660 083 van 10 u. tot 12 u. en van 14 u. tot 17 u. van maandag tot en met vrijdag.'
  },
  trackerLeftPanel: {
    CALL: 'Oproepen',
    CTR_ONLY_SECONDS: 'U hebt nog {{SECONDS}} seconde(n) telefoongesprekken voor de lopende maand.',
    CTR_WITH_MINUTES: 'U hebt nog {{MINUTES}} minute(n) en {{SECONDS}} seconde(n) telefoongesprekken voor de lopende maand.',
    FLASH: 'Flits',
    GOOD_GPS_ACCURACY: 'Goede GPS precisie ',
    LAST_POSITION: 'Laatste bekende positie:',
    MEDIUM_GPS_ACCURACY: 'Gemiddelde GPS precisie',
    OFFLINE: 'Tracer niet verbonden',
    POOR_GPS_ACCURACY: 'Slechte GPS precisie',
    RING: 'Bellen',
    SMS_AVAILABLE: 'SMS beschikbaar : {{SMS_count}}',
    VIBRATE: 'Vibreren'
  },
  trackerSettings: {
    BUTTONS: {
      LABELS: {
        BUTTON: 'Knop'
      },
      PLACEHOLDER_BUTTON: 'Voer het bericht in dat verzonden wordt met Waarschuwing {{button_number}}',
      TEXT: 'Schrijf een bericht voor elke waarschuwing van uw lokalisator. U ontvangt het bericht in uw meldingen wanneer de knop van de lokalisator ingedrukt wordt.'
    },
    CONTACTS: {
      ADD_EMAIL_BUTTON: 'Een e-mailadres toevoegen',
      ADD_PHONE_BUTTON: 'Een telefoonnummer toevoegen',
      EMAILS_LABEL: 'E-mailadres voor meldingen per e-mail',
      MAIL: {
        PLACEHOLDER: 'votre@email.com'
      },
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden, probeer het nogmaals.',
        UPDATE_SUCCESS: 'Contacten met succes bijgewerkt'
      },
      NUMBERS_LABEL: 'Telefoonnummer voor meldingen per SMS',
      PHONE: {
        PLACEHOLDER: '+33600000000'
      }
    },
    DISPLAY: {
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden',
        UPDATE_SUCCESS: 'Instellingen met succes gewizigd'
      },
      MODAL: {
        HEADLINE: 'Icoon selecteren',
        TEXT: 'Je kunt de gewenste icoon in de onderstaande lijst selecteren. Deze zal je tracer op de kaart weergeven.'
      },
      TRACKER_ICON: {
        EDIT_TEXT: 'Icoon wijzigen',
        LABEL: 'Tracer icoon'
      },
      TRACKER_NAME: {
        LABEL: 'Naam van de tracer',
        PLACEHOLDER: 'Naam die wordt weergegeven op de kaart'
      },
      TRACKER_TYPE: {
        CHANGE: 'Het type tracker wijzigen',
        MODIFIED_SUCCESS: 'Type tracker met succes gewijzigd'
      }
    },
    GPS_FREQUENCY: {
      BATTERY_USAGE: 'Gemiddelde autonomie van een batterij:',
      BATTERY_USAGE_LEVELS: {
        HIGH: 'Hoog',
        LOW: 'Laag',
        MEDIUM: 'Gemiddeld'
      },
      GEOFENCE_NOTIFICATIONS: 'Gemiddelde reactiviteit van meldingen voor de zone:',
      HINT: 'Een hogere frequentie vermindert de autonomie van de batterij maar verbetert de reactiviteit van meldingen voor het in- en uitgaan van een zone. Een lagere frequentie verbetert daarentegen de autonomie van de batterij maar vermindert de reactiviteit van meldingen voor het in- en uitgaan van een zone.',
      TEXT: 'Kies de tijdsinterval tussen elke GPS-positie'
    },
    NOTIFICATION_SETTINGS: {
      APP: 'App',
      BATTERY: {
        HINT: 'Stuur een melding naar alle contacten indien de tracer een zwakke batterij heeft.',
        TITLE: 'Batterij'
      },
      BUTTONS: {
        HINT: 'Stuur een melding naar alle contacten wanneer één van de laterale knoppen wordt ingedrukt. Het voor deze knop ingestelde bericht zal bij de melding worden ingesloten.',
        TITLE: 'Laterale knoppen'
      },
      GEOFENCES: {
        HINT: 'Stuur een melding naar alle contacten wanneer de tracer een ingestelde zone in- of uitgaat.',
        TITLE: 'Veilige zones'
      },
      MAIL: 'E-mail',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden, probeer het nogmaals',
        UPDATE_SUCCESS: 'De instellingen voor meldingen zijn met succes gewijzigd'
      },
      SMS: 'SMS',
      SOS: {
        HINT: 'Stuur een melding naar alle contacten wanneer de SOS-knop wordt ingedrukt.',
        TITLE: 'SOS oproep'
      },
      WARNING: {
        SMS: {
          LINK: 'Klik hier om die te activeren.',
          TEXT: 'Let op, als u meldingen per sms wilt ontvangen, moet u de bijbehorende keuze activeren.'
        }
      }
    },
    SECTION_TITLES: {
      BUTTONS: 'Knoppen',
      CONTACTS: 'Contacten voor meldingen',
      DISPLAY: 'Algemeen',
      GPS_FREQUENCY: 'Tijdsinterval tussen iedere GPS-positie',
      NOTIFICATION_SETTINGS: 'Type meldingen voor iedere alert',
      SOS: 'SOS-nummer',
      TRAINING: 'Telefoonfunctie'
    },
    TRAINING_SOS: {
      DIRECT_CALL: 'Bel dit nummer rechtstreeks als de SOS-knop wordt ingedrukt',
      INPUT_PLACEHOLDER: '+33600000000',
      MESSAGES: {
        UPDATE_ERROR_UNKNOWN: 'Er is een probleem opgetreden, probeer het nogmaals',
        UPDATE_SUCCESS: 'Telefoonnummer met succes gewijzigd'
      },
      SOS_TEXT: 'Telefoonnummer dat de oproepen zal ontvangen bij een SOS alert',
      TRAINING_TEXT: 'Voer het telefoonnummer in dat zal standaard worden gebeld door de tracer wanneer de telefoonfunctie vanuit de app wordt gebruikt. Dit nummer kan worden aangepast bij iedere oproep aanvraag vanuit de app.'
    }
  },
  trackers: {
    ADD_TRACKER_BUTTON: {
      LABEL: 'Tracer toevoegen'
    },
    EXPIRATION: {
      EXPIRED: 'Het abonnement van {{tracker}} is verlopen',
      WILL_EXPIRE: 'Het abonnement van {{tracker}} verloopt over {{days}} dagen'
    },
    LINKS: {
      ACTIVITY: 'Opvolging van activiteit',
      GEOFENCES: 'Zones',
      HISTORY: 'Geschiedenis',
      SETTINGS: 'Instellingen',
      SUBSCRIPTION: 'Abonnement'
    }
  },
  trackersPositionRefresh: {
    MESSAGES: {
      NOT_REFRESHED: 'Uw tracker is er niet in geslaagd om nieuwe gps-posities te krijgen',
      REFRESHING: 'Even geduld, er is een verversingsverzoek bezig',
      SUCCESS: 'GPS positie van de tracer met succes bijgewerkt'
    }
  },
  transferPage: {
    ADD_TRACKER: 'Ik wil een nieuwe tracker toevoegen aan mijn account',
    BACK_TO_TRACKERS: 'Terug naar de kaart',
    FIRST_TITLE: 'Wat wilt u precies doen?',
    FROM_HEADLINE: 'Te vervangen tracer:',
    HEADLINE: 'Een tracer vervangen',
    IMEI_LABEL: 'IMEI-nummer',
    INFO: 'Het huidige abonnement, evenals de geschiedenis van de GPS-posities en de instellingen, worden overgezet naar de nieuwe tracer na vervanging.',
    MESSAGES: {
      ERROR_UNKNOWN: 'Er is een probleem opgetreden, probeer het nogmaals',
      SUCCESS: 'Nieuwe tracer met succes toegewezen',
      WRONG_TYPE: 'De lokalisator met dit IMEI-nummer stemt niet overeen met het type van uw oude lokalisator. Neem contact op met onze helpdesk.'
    },
    NAME_LABEL: 'Naam van de tracer',
    PURCHASE: 'Heb je nog geen Weenect tracer? <1>Je kunt er hier één kopen</1>',
    REPLACE_BUTTON: {
      LABEL: 'Vervangen'
    },
    REPLACE_TRACKER: 'Ik wil een bestaande tracker vervangen',
    TO_HEADLINE: 'Nieuwe tracer:'
  },
  webappDisconnection: {
    BUG: 'Het lijkt erop dat uw tracker geen verbinding kan krijgen met het netwerk.',
    MANUAL: 'U hebt uw tracker uitgeschakeld. Klik op de knop ON om hem weer aan te zetten.',
    NO_BATTERY: 'Uw tracker is uitgegaan omdat de accu leeg was.',
    NO_SUB: 'U hebt geen abonnement voor deze tracker. U kunt een abonnement afsluiten op de pagina'
  },
  wifiZones: {
    AIRPLANE_TEXT: 'Let op: zolang uw tracker zich in de Energiebesparende zones bevindt, kunt u hem in vliegtuigmodus niet meer laten rinkelen, trillen of handmatig de positie ervan verversen.',
    DEEP_SLEEP_TITLE: 'Vliegtuigmodus',
    DISPLAY_BUTTON: 'Toon het gebied op de kaart',
    DISPLAY_TITLE: 'Meldingen en weergave',
    IN_AIRPLANE: '- in vliegtuigmodus',
    IN_OUT_TEXT: 'Ingangen en uitgangen van zones :',
    LAST_POS_IN_WIFI: 'In de Energiebesparende zones sinds {{date}} ',
    MAIL_NOTIF: 'Mail meldingen',
    NOTIF_TEXT: 'Ontvang een melding voor elke ingang\nof verlaten van de voorgedefinieerde zone',
    POPIN: {
      AIRPLANE: {
        BUTTON_VALIDATE: 'De vliegtuigmodus uitzetten',
        CONTENT: 'U kunt niet langer communiceren met uw tracker terwijl deze zich in de&nbsp;<span style="color: #333333; font-family: Verdana, sans-serif;"><span>Energiebesparende zones</span></span><span>. Als je weer volledig functioneel wilt worden, &lt;1&gt; schakel je de vliegtuigmodus uit en haal je de tracker uit de zone&lt;1&gt;.</span>',
        TITLE: 'Uw tracker staat in vliegtuigmodus in de Energiebesparende zones {{wifiName}}.'
      },
      BUTTON_CLOSE: 'Doe het later',
      BUTTON_VALIDATE: 'De zone deactiveren',
      CONTENT: 'In deze zone is het automatisch opvragen van GPS posities van uw tracker uitgeschakeld, maar u kunt het nog steeds laten bellen om het te vinden. U kunt ook de Energiebesparende zones deactiveren.',
      TITLE: 'Uw tracker bevindt zich in de Energiebesparende zones {{wifiName}}.'
    },
    PUSH_NOTIF: 'Push meldingen',
    SMS_NOTIF: 'SMS meldingen',
    TITLE: 'Energiebesparende zones',
    ZONE_ACTIVATION: 'Activering van het gebied'
  }
}
