import React, { useEffect } from 'react';
import { isEqual } from 'lodash';
import {
    compose, withStateHandlers, mapProps,
    StateHandler, StateHandlerMap
} from "recompose";
import { useTranslation } from 'react-i18next';

import { IUserEmailPrefData } from "./interfaces"

import { Typography } from '../common/typography/typography.component';
import { Button } from '../common/button/button.component';
import { SaveButton } from '../common/button/save-button.component';
import { Headline } from '../common/headline/headline.component';
import { Toggle } from '../common/toggle/toggle.component';
import { Link } from '../common/link/link.component';
import { UserEmailLanguagePreferences } from './user-email-language-preferences/user-email-language-preferences.component';
import { Spinner } from '../common/loading-spinner/loadingSpinner.component';

import styles from './email-preferences.module.scss';


export interface IInputProps {
    distantValues?: IUserEmailPrefData;
    maskedEmail?: string;
    disabled?: boolean;
    loading?: boolean;
    onSubmit?: (values: IUserEmailPrefData) => void;
}

export interface IOutputProps extends IInputProps {}

export interface IFullState {
    dirty: boolean;
    values: IUserEmailPrefData;
}

export interface IStateHandlers {
    setValues: StateHandler<IFullState>;
    handleChange: StateHandler<IFullState>;
    handleSubmit: StateHandler<IFullState>;
    handleReset: StateHandler<IFullState>;
}
export interface IStateHandlersMap extends 
    StateHandlerMap<IFullState>,
    IStateHandlers {}

export interface IInnerProps extends IOutputProps, IFullState, IStateHandlers {}

const defaultListValues: IUserEmailPrefData = {
    current_lang: "fr",
    list_company_news: false,
    list_new_features: false,
    list_offers: false,
    list_surveys_and_tests: false,
    list_tutorials: false,
    list_service_status: true,
};

export const EmailPreferences = compose<IInnerProps, IInputProps>(
    mapProps<IInputProps, IOutputProps>((props: IInputProps) => {
        //console.log("mapProps");
        return ({
            ...props,
            disabled: props.distantValues? props.disabled || false : true,
            loading: props.loading || false,
            onSubmit: props.onSubmit != null ? props.onSubmit : (v) => (console.log(v))
        })}),
        withStateHandlers<IFullState, IStateHandlersMap, IInnerProps>(
            ({ distantValues }) => {
                //console.log("withStateHandlers");
            return({
            dirty: false,
            values: distantValues ? distantValues : defaultListValues,
        })}, {
                setValues: ({ values }, { distantValues, disabled, loading }) => (
                    new_values: Partial<IUserEmailPrefData>
                ) => {
                    //console.log("setValues");
                    if (disabled || loading) return {};
                    const new_vals: IUserEmailPrefData = {
                        ...values,
                        ...new_values
                    };
                    return {
                        values: new_vals,
                        dirty: !isEqual(new_vals, distantValues),
                    }
                },
                handleChange: (
                    { values }, { distantValues, disabled, loading }
                ) => (e: React.FormEvent<HTMLFormElement>) => {
                    //console.log("handleChange");
                    e.stopPropagation();
                    if (disabled || loading) return {};
                    let new_vals: IUserEmailPrefData = { ...values };
                    // @ts-ignore
                    if (e.target.type === 'checkbox')
                        // @ts-ignore
                        new_vals[e.target.name] = !new_vals[e.target.name];
                    else
                        // @ts-ignore
                        new_vals[e.target.name] = e.target.value.toLowerCase();
                    return {
                        values: new_vals,
                        dirty: !isEqual(new_vals, distantValues),
                    };
                },
                
                handleSubmit: ({ values, dirty }, { onSubmit, disabled, loading }) => (e) => {
                    //console.log("handleChange");
                    e.stopPropagation();
                    e.preventDefault();
                    if (disabled || loading) return {};
                    if (onSubmit && dirty) onSubmit(values);
                    return {};
                },
                handleReset: (
                    { values, dirty }, { distantValues, disabled, loading }
                    ) => (e: React.FormEvent<HTMLFormElement>) => {
                    //console.log("handleChange", disabled);
                    e.stopPropagation();
                    e.preventDefault();
                    if (disabled || loading) return {};
                    return {
                        values: {...(distantValues || values)} ,
                        dirty: false,
                    }
                },
        }
    ))(
    ({
        distantValues,
        maskedEmail,
        disabled,
        loading,
        onSubmit,
        values,
        dirty,
        setValues,
        handleChange,
        handleSubmit,
        handleReset,
    }: IInnerProps) => {
        useEffect(() => (void setValues(distantValues)), [distantValues, setValues]);

        const { t } = useTranslation([
            'account', 'forms', 'commonActions'
        ]);

        const all_selected = (values.list_company_news
            && values.list_new_features
            && values.list_offers
            && values.list_surveys_and_tests
            && values.list_tutorials);

        const toggle_all = async () => {
            if (all_selected) setValues({
                list_company_news: false,
                list_surveys_and_tests: false,
                list_new_features: false,
                list_tutorials: false,
                list_offers: false,
            });
            else setValues({
                list_company_news: true,
                list_surveys_and_tests: true,
                list_new_features: true,
                list_tutorials: true,
                list_offers: true,
            });
        }

        const maybeRenderCancel = (dirty: boolean) => {
            if (dirty) {
                return (
                    <Button className={styles.reset} type="reset" secondary>
                        {t('commonActions:CANCEL')}
                    </Button>
                );
            }
        };

        const renderEmailListBlock = () => {
            //console.log(values)
            return <fieldset className={styles.emailList}>
                <div className={styles.headrow}>
                    <Typography>{t("emailPreferences:EMAIL_LISTS_HEADER")}</Typography>
                    <Link onClick={disabled ? undefined : toggle_all}
                        muted={all_selected || disabled}>
                        {disabled ? 
                            t("commonActions:DEACTIVATE") : (
                                all_selected ?
                                t("commonActions:UNSELECT_ALL") :
                                t("commonActions:SELECT_ALL")
                            )}
                    </Link>
                </div>
                <Toggle name="list_company_news"
                    checked={values.list_company_news}
                    onChange={handleChange} disabled={disabled || loading}
                    toggleAfter={true} wifiToggle>
                    {t("emailPreferences:EMAIL_LISTS.COMPANY_NEWS")}
                </Toggle>
                <Toggle name="list_surveys_and_tests"
                    checked={values.list_surveys_and_tests}
                    onChange={handleChange} disabled={disabled || loading}
                    toggleAfter={true} wifiToggle>
                    {t("emailPreferences:EMAIL_LISTS.SURVEYS_AND_TESTS")}
                </Toggle>
                <Toggle name="list_new_features"
                    checked={values.list_new_features}
                    onChange={handleChange} disabled={disabled || loading}
                    toggleAfter={true} wifiToggle>
                    {t("emailPreferences:EMAIL_LISTS.NEW_FEATURES")}
                </Toggle>
                <Toggle name="list_tutorials"
                    checked={values.list_tutorials}
                    onChange={handleChange} disabled={disabled || loading}
                    toggleAfter={true} wifiToggle>
                    {t("emailPreferences:EMAIL_LISTS.TUTORIALS")}
                </Toggle>
                <Toggle name="list_offers"
                    checked={values.list_offers}
                    onChange={handleChange} disabled={disabled || loading}
                    toggleAfter={true} wifiToggle>
                    {t("emailPreferences:EMAIL_LISTS.OFFERS")}
                </Toggle>
                <Toggle name="list_service_status"
                    checked={values.list_service_status}
                    onChange={handleChange}
                    toggleAfter={true} disabled>
                    {t("emailPreferences:EMAIL_LISTS.SERVICE_STATUS")}<br />
                    <span className={styles.labelExtraInfo}>
                        {t("emailPreferences:EMAIL_LISTS_INFO.SERVICE_STATUS")}
                    </span>
                </Toggle>
            </fieldset>
        };

        const maybeRenderText = () => (
            maskedEmail ? <Typography className={styles.label}>
                {t('USER_PREFERENCES.EMAIL_PREFERENCES_USER_INFO', {
                    user_email: maskedEmail,
                })}
            </Typography> : undefined
        )

        return <form onSubmit={handleSubmit} onReset={handleReset}
                className={styles.emailPreferences}>
            <Spinner loading={loading || false} />
            <Headline className={styles.subHeadline} level={2}>
                {t('USER_PREFERENCES.EMAIL_PREFERENCES_HEADLINE')}
            </Headline>
            <Typography className={styles.label}>
                {t('emailPreferences:PREFERENCES_DECRIPTION')}
            </Typography>
            {maybeRenderText()}
            {renderEmailListBlock()}
            <UserEmailLanguagePreferences
                name="current_lang"
                legend={t("emailPreferences:LANGUAGE_PREFERENCES_DESCRIPTION") || ""}
                value={values.current_lang.toUpperCase()}
                onChange={handleChange}
                className={styles.values}
                disabled={disabled || loading}
            />
            <SaveButton
                disabled={!dirty}
                type='submit'>
                {t('commonActions:SAVE')}
            </SaveButton>
            {maybeRenderCancel(dirty)}
        </form>;
    }
)
