import cx from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { Typography } from '../typography/typography.component';

import styles from './toggle.module.scss';

export interface IToggleProps extends InputHTMLAttributes<HTMLInputElement> {
    isError?: boolean;
    wifiToggle?: boolean;
    toggleAfter?: boolean;
}

export const Toggle = ({
    wifiToggle,
    toggleAfter,
    children,
    checked,
    onChange,
    className,
    disabled,
    isError,
    ...props
}: IToggleProps) => {
    const label_txt = <Typography noMargin
        className={disabled ? styles.labelDisabled : styles.label}
        size16>
            {children}
        </Typography>;
    return <label className={cx(styles.wrapper, className)}>
        {toggleAfter ? label_txt : null}
        <input
            className={wifiToggle ? styles.nativeFieldWifi : styles.nativeField}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            {...props}
            />
        <div className={cx(styles.customField, { [styles.error]: isError })} />
        {toggleAfter ? null : label_txt}
    </label>;
};
