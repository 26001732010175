import cx from 'classnames';
import React, { AnchorHTMLAttributes } from 'react';

import styles from './link.module.scss';

export interface ILinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    center?: boolean;
    block?: boolean;
    bold?: boolean;
    white?: boolean; // When you need link to be white and underlined.
    muted?: boolean;
}

export const Link = ({
    center,
    block,
    children,
    className,
    bold,
    white,
    muted,
    ...props
}: ILinkProps) => (
    <a
        className={cx(
            styles.link,
            {
                [styles.center]: center,
                [styles.block]: block,
                [styles.bold]: bold,
                [styles.white]: white,
                [styles.muted]: muted,
            },
            className,
        )}
        {...props}
    >
        {children}
    </a>
);
