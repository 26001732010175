import cx from 'classnames';
import React, { InputHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { getAvailableLanguages } from '../../i18n/available-languages';
import { Radio } from '../../common/radio/radio.component';

import styles from './user-email-language-preferences.module.scss';

const languages = getAvailableLanguages();

export interface ILangagueProps extends InputHTMLAttributes<HTMLInputElement> {
    legend?: string;
}


export const UserEmailLanguagePreferences = ({
    legend,
    value,
    name,
    children,
    checked,
    onChange,
    className,
    ...props
}: ILangagueProps) => {

    const { t } = useTranslation(['account', 'commonActions', 'forms']);

    return (
        <fieldset className={cx(className, styles.languageFieldset)}>
            {legend ? <legend>{legend}</legend> : null}
                {children}
            {languages.map((lang) => (
                <Radio
                    className={styles.radio}
                    name={name || "language"}
                    key={lang.code}
                    checked={value === lang.code}
                    value={lang.code}
                    onChange={onChange}
                    {...props}
                >
                    {t("misc:LANGUAGE." + lang.shortDisplay)}
                </Radio>
            ))}
        </fieldset>
    );
};
