import cx from 'classnames';
import React from 'react';
import { compose, mapProps, withProps } from 'recompose';

import { ReactComponent as TickIcon } from '../../icons/tick.svg';
import { Button, IButtonProps } from './button.component';

import styles from './save-button.module.scss';

export const SaveButton = compose<IButtonProps, IButtonProps>(
    withProps({
        iconLeft: <TickIcon />,
    }),
    mapProps((p: IButtonProps) => ({
        ...p,
        className: cx(p.className, styles.button),
    })),
)(Button);
