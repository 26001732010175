import cx from 'classnames';
import React, { HTMLAttributes } from 'react';
import { pure } from 'recompose';

import styles from './headline.module.scss';

export interface IHeadlineProps extends HTMLAttributes<HTMLHeadingElement> {
    level?: number;
    center?: boolean;
}

export const Headline = pure(
    ({ level = 1, center, children, className, ...props }: IHeadlineProps) => {
        return React.createElement(
            `h${level}`,
            {
                ...props,
                className: cx(
                    styles.headline,
                    styles[`level-${level}`],
                    {
                        [styles.center]: center,
                    },
                    className,
                ),
            },
            children,
        );
    },
);
