import cx from 'classnames';
import React, { InputHTMLAttributes } from 'react';

import styles from './radio.module.scss';

export interface IRadioProps extends InputHTMLAttributes<HTMLInputElement> {
    hideRadio?: boolean | undefined;
    error?: boolean;
    isTracker?: boolean;
}

export const Radio = ({
    checked,
    children,
    className,
    isTracker,
    error,
    hideRadio,
    onChange,
    ...props
}: IRadioProps) => {
    const shouldBeTracker = Boolean(isTracker);

    if (shouldBeTracker === true) {
        return (
            <label className={cx(styles.container, className)}>
                <div className={styles.trackerWrapper}>
                    <input
                        checked={checked}
                        onChange={onChange}
                        className={styles.radio}
                        type="radio"
                        {...props}
                    />
                    <div
                        className={cx(styles.mask, {
                            [styles.error]: error,
                            [styles.hidden]: hideRadio,
                        })}
                    />
                    <span
                        className={cx(styles.labelText, {
                            [styles.trackerChecked]: checked,
                        })}
                    >
                        {children}
                    </span>
                </div>
            </label>
        );
    }
    return (
        <label className={cx(styles.container, className)}>
            <div className={styles.wrapper}>
                <input
                    checked={checked}
                    onChange={onChange}
                    className={styles.radio}
                    type="radio"
                    {...props}
                />
                <div
                    className={cx(styles.mask, {
                        [styles.error]: error,
                        [styles.hidden]: hideRadio,
                    })}
                />
                <span
                    className={cx(styles.labelText, {
                        [styles.checked]: checked,
                    })}
                >
                    {children}
                </span>
            </div>
        </label>
    );
};
